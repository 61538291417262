<template>
    <el-date-picker
	    :disabled="disabled"
        :editable="false"
        type="datetime"
        placeholder="选择日期"
        v-model="values"
        style="width: 100%;"
    @change="change"></el-date-picker>
</template>

<script>
export default {
    name: "PushTime",
    data(){
        return{
            values:'',
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:String,
            default: ''
        },
	    disabled:{
		    type:Boolean,
		    default:false
	    }
    },
    created() {
        this.values = this.value ? this.$moment(this.value).toDate() : '';
				console.log(this.values);
    },
    watch:{
        value(n,o){
            this.values = n ? this.$moment(n).toDate() : '';
        },
    },
    methods:{
        change(e){
            let _year = e.getFullYear();
            let _month = e.getMonth() + 1;
            let _day = e.getDate();

            // this.$emit('change',_year+'-'+_month+'-'+_day);
            this.$emit('change',this.$moment(e).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
}
</script>

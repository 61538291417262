<template>
	<user-layout>
		<user-menu path="/user">
			<el-tabs v-model="searchForm.state" @tab-click="changeTo">
				<el-tab-pane label="待处理" name="1" class="none"></el-tab-pane>
				<el-tab-pane label="已通过" name="2" class="none"></el-tab-pane>
				<el-tab-pane label="未通过" name="3" class="none"></el-tab-pane>
			</el-tabs>
			<div class="bg-light margin-bottom-20 box-shadow-5 border-radius-10 padding-20 hand" v-for="(item,index) in list" @click="toToubiaoDetail(item)">
				<div class="inline-block vm">
					<el-avatar class="vm" :size="40" :src="$utils.formatImagePath(item.company.logo)"></el-avatar>
					<div class="inline-block margin-left f16">{{item.company.company}}</div>
					<div class="margin-top f14 dark-3">投标时间：{{item.create_time}}</div>
				</div>
				<div class="y inline-block green" v-if="searchForm.state === '2'">
					<i class="el-icon-success"></i>
					<div class="inline-block f14 margin-left-5">已通过</div>
				</div>
				<div class="y inline-block red" v-if="searchForm.state === '3'">
					<i class="el-icon-error"></i>
					<div class="inline-block f14 margin-left-5">已拒绝</div>
				</div>
			</div>
			<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
				<el-pagination
					background
					@current-change="pageChange"
					:current-page="searchForm.page"
					:page-size="searchForm.pageSize"
					layout="prev, pager, next, jumper"
					:total="totalCount">
				</el-pagination>
			</div>
		</user-menu>
	</user-layout>
</template>

<script>
export default {
	name: "toubiaoLog",
	data() {
		return {
			searchForm:{
				state: '1',
				tid: '',
				page: 1,
				pageSize: 10
			},
			totalCount:0,
			list:[]
		}
	},
	created() {
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.searchForm.state = this.$route.query.state ? this.$route.query.state : '1';
	},
	mounted() {
		this.getList();
	},
	methods: {
		toToubiaoDetail(item){
			if(item){
				this.$router.push({path:'/company/toubiao',query:{id:item.uid,bidsid:item.bids.id}})
			}
		},
		changeTo(tab, event){
			this.searchForm.page = 1;
			this.getList();
		},
		getList(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUserToubiaoLog/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		pageChange(e){
			this.searchForm.page = e;
			// let _datas = JSON.parse(JSON.stringify(this.searchForm));
			this.$router.replace({query:this.searchForm})
		},
	}
}
</script>

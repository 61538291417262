<template>
    <m-main-layout>
        <bread :list="bread"></bread>
        <div class="cl margin-top-30">
            <div class="z hide" style="width: 860px;">
                <div class="padding-20">
                    <template v-for="(item,index) in list">
                        <div class="cl padding-top-bottom">
                            <a class="z f16" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}" :href="'/news/detail/'+item.id" target="_blank" rel="opener">{{item.title}}</a>
                            <div class="y dark-4-1 f12">{{item.create_time}}</div>
                        </div>
                        <div class="line-2"></div>
                    </template>
                </div>
            </div>
            <div class="y bg-gray-4 hide padding-15" style="width: 317px;">
                <zbtj></zbtj>
                <div class="line-1"></div>
            </div>
        </div>
        <div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            searchForm:{
                page:1
            },
            pageSize:15,
            totalCount:0,
            list:[],
            bread:[]
        }
    },
    created() {
        // this.type = this.$route.params.type ? this.$route.params.type : '';
        this.type = this.$route.name ? this.$route.name : '';
        this.searchForm.page = this.$route.query.page ? this.$route.query.page : 1;

        if(this.type === 'policy'){
            this.bread = [{name:'政策法规',path:'/news/policy'}];
        }else if(this.type === 'logistics'){
            this.bread = [{name:'物流资讯',path:'/news/logistics'}];
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        pageChange(e){
            this.searchForm.page = e;

            this.$router.replace({query:{...this.searchForm}});
            // this.getList();
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/News/listPage',
                datas:{...this.searchForm,type:this.type,pageSize:this.pageSize,state:1},
                success(res){
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            })
        },
        toDetail(id){
            window.open('/news/detail/'+id,'_blank')
        }
    }
}
</script>

<template>
    <m-main-layout>
        <bread></bread>
        <div class="margin-top-30 padding-20">
            <div style="width: 1000px;" v-html="setting.about"></div>
<!--            <div class="blue f16 f-b">关于我们</div>-->
<!--            <div class="f16 dark-2" style="width: 1000px;line-height: 30px;">-->
<!--                <div class="margin-top-50">-->
<!--                    <div class="blue">中国物流招标网 www.clb.org.cn</div>-->
<!--                    <div class="">专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。</div><br/>-->
<!--                    <div>中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。</div>-->
<!--                </div>-->
<!--                <div class="margin-top-50">-->
<!--                    <div class="blue">深耕物流 全时覆盖</div>-->
<!--                    <div class="">专注于物流运输招标全流程服务，与众多制造业、商贸企业建立了坚实合作。</div><br/>-->
<!--                    <div>专注12大领域：农副食品加工、食品制造、饮料制造、纺织业、木材加工、纺织业、工业品制造、家具制造、机械制造、金属矿业、橡胶制品、医药制造 <br/>渠道覆盖：全国300强制造业企业、全国500家商贸物流园区、全国600家工业园区</div>-->
<!--                </div>-->
<!--                <div class="margin-top-50">-->
<!--                    <div class="blue">专业资讯 实效快捷</div>-->
<!--                    <div class="">物流采购信息第一时间获取发布，即时更新运输、设备采购、库房寻租、行业资讯等信息，累计发布信息超过100万条。</div><br/>-->
<!--                    <div>10人组信息采编团队，对招标信息实时应用有深刻的理解；<br/>累计3000家制造业、商贸业客户实时更新对接，招标信息联动发布</div>-->
<!--                </div>-->
<!--                <div class="margin-top-50">-->
<!--                    <div class="blue">信用度高 体系完善</div>-->
<!--                    <div class="">累计注册会员120000+，分布在全国1000个城市及地区。会员营销服务多维度，研讨会、标准制定、培训等线下互动，为深化合作伙伴关系，探索未来合作发展，搭建高效的对话平台。</div><br/>-->
<!--                    <div>7*24小时在线一对一客户服务跟踪；<br/>30场线下互动活动/年度；<br/>50场专业领域培训/年度。</div>-->
<!--                </div>-->
<!--                <div class="margin-top-50 padding-bottom-30">-->
<!--                    <div class="blue">优势研发 创新服务</div>-->
<!--                    <div class="">汇集信息化领域资深团队，并与多家客户建立共同研发与共享平台，从信息收集、整理、分析、发布到实时信息推送，一系列的解决方案，信息服务能力持续改进。</div><br/>-->
<!--                    <div>服务号：招标信息分类整理，即时推送；<br/>订阅号：资讯每日更新，市场动态即刻掌握；<br/>招标网：品牌、资讯转向视觉化，即时了解招标发展趋向。</div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "About",
    data(){
        return{
            setting:{
                about:''
            },
        }
    },
    mounted(){
        this.getSystem();
    },
    methods:{
        getSystem(){
            let _this = this;

            let _skeys = [];

            for (let k in this.setting) {
                _skeys.push(k);
            }

            this.$http.httpPost({
                url: '/SystemSetting/getSystem',
                datas: {skey: _skeys},
                success: (res) => {
                    _this.setting = res;
                }
            });
        },
    }
}
</script>

<template>
	<div class="margin-top" v-if="info">
		<div class="h50 hide">
			<img :src="$utils.formatImagePath(info.logo)" style="height: 50px;"/>
			<span class="vm f30 blue margin-left">{{info.company}}</span>
			<i class="iconfont icon-vip3 f34 margin-left-5 vm" style="color: orange" v-if="info.user_detail && info.user_detail.vip_state === 1"></i><i class="iconfont icon-vip3 f34 margin-left-5 vm" style="color: #bbb" v-if="info.user_detail && info.user_detail.vip_state === 0"></i>
		</div>
		<div class="el-descriptions margin-top">
			<div class="el-descriptions__body">
				<table class="el-descriptions__table is-bordered">
					<tbody>
						<tr class="el-descriptions-row">
							<th colspan="4" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label" style="text-align: center;font-size: 38px !important;color:#15489D !important;">企业信息</th>
						</tr>
					</tbody>
					<tbody>
						<tr class="el-descriptions-row">
							<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">所属行业</th>
							<td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content f16">{{info.industry_str}}</td>
							<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">所属区域</th>
							<td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content f16">{{info.province_str}}</td>
						</tr>
						<tr class="el-descriptions-row">
							<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">已发标数量</th>
							<td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content f16">{{info.bids_year_count ? info.bids_year_count : 0}}/年</td>
							<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">发标月份</th>
							<td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content f16">
								<template v-if="info.bids_last_month === '-'">-</template>
								<template v-else>
									<el-row :gutter="10">
										<el-col class="blue" :span="4" v-for="(item,index) in info.bids_last_month">{{item}}</el-col>
									</el-row>
								</template>
							</td>
						</tr>
						<tr class="el-descriptions-row">
							<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">天眼查信息</th>
							<td colspan="3" class="el-descriptions-item__cell el-descriptions-item__content">
								<a :href="'https://www.tianyancha.com/search?key='+info.company" target="_blank" rel="opener" class="border-radius bg-blue inline-block light f16 padding-left-right hand padding-top-bottom-5">
									<i class="iconfont icon-tianyancha"></i>
									跳转到天眼查
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<el-row :gutter="20" class="margin-top-20">
			<el-col :span="12">
				<div class="bg-blue light padding hide border-radius-5 rel">
					<div class="f16">发布动态</div>
					<div class="f30 margin-top">{{companyTotal && companyTotal.blog_count ? companyTotal.blog_count : 0}}<span class="f14 margin-left-5">条</span></div>
					<i class="el-icon-arrow-right abs f30" style="right:10px;top:50%;margin-top:-15px;"></i>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="bg-blue light padding hide border-radius-5 rel">
					<div class="f16">发标数量</div>
					<div class="f30 margin-top">{{info.bids_total_count}}<span class="f14 margin-left-5">条</span></div>
					<i class="el-icon-arrow-right abs f30" style="right:10px;top:50%;margin-top:-15px;"></i>
				</div>
			</el-col>
		</el-row>
		<div class="margin-top-20">
			<div v-if="bidsList.length > 0" id="bids_box">
				<div class="padding-20 border-w-1 border-dark-6 border-radius-5 box-shadow-5 margin-bottom-20" v-for="(item,index) in bidsList">
					<div>
						<span class="inline-block text-center border-radius" style="width: 30px;height: 30px;background: #E3ECFF;line-height: 30px;">
							<i class="iconfont icon-qizhicha blue f18"></i>
						</span>
						<a :href="'/bids/detail/'+item.id" target="_blank" rel="opener" style="color: #246AF9;" class="margin-left-5 f18">{{item.title}}</a>
					</div>
					<div class="dark-3 f14 margin-top">发布日期：{{item.push_time}}</div>
					<div class="f14 margin-top" style="color: #246AF9;">截止日期：{{item.end_time}}</div>
					<div class="cl margin-top padding-top" style="border-top:1px solid #eee;" v-if="$user.getUid() === info.uid">
						<div class="z dark-3 f14">
							<span>浏览:{{item.click_count}}</span>
							<span class="margin-left-5 margin-right-5">关注:{{item.total.favorite_count}}</span>
							<span>报名:{{item.total.baoming_count}}</span>
						</div>
						<div class="y f14 padding-left-right-20 hand padding-top-bottom-5" style="background-color: #ffd500;color:#333;border-radius: 999px;" @click="toExport(item)">导出线上报名信息</div>
						<div class="y f14 padding-left-right-20 hand padding-top-bottom-5 margin-right" style="background-color: #008cff;color:#fff;border-radius: 999px;" @click="toExportFile(item)">导出报名资质文件</div>
					</div>
				</div>
				<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
					<el-pagination
						background
						@current-change="pageChange"
						:current-page="bidsSearch.page"
						:page-size="bidsSearch.pageSize"
						layout="prev, pager, next, jumper"
						:total="totalCount">
					</el-pagination>
				</div>
			</div>
			<el-empty description="暂无数据" v-else></el-empty>
		</div>
		<div class="f20 blue margin-bottom">相似企业</div>
		<list-huozhu-item class="box-shadow-5 bg-light border-radius-5 padding-20" v-for="(item,index) in companyList" :info="item"></list-huozhu-item>
	</div>
</template>

<script>
import ListHuozhuItem from '../listTemplate/huozhu'
export default {
	name: "huozhu",
	components: {ListHuozhuItem},
	data() {
		return {
			bidsSearch:{
				uid:'',
				orderBy: 'create_time',
				sortBy: 'desc',
				page: 1,
				state:1,
				pageSize: 10
			},
			companySearch:{
				user_type:'hz',
				orderBy: 'RAND()',
				type_industry_ids: ''
			},
			totalCount:0,
			bidsList:[],
			companyList:[],
			companyTotal:null
		}
	},
	props:{
		info:{
			type:[Object,Array],
			default:null
		}
	},
	created() {
		if(this.info){
			this.bidsSearch.uid = this.info.uid;
			this.companySearch.type_industry_ids = this.info.type_industry_ids ? this.info.type_industry_ids.join(',') : '';
		}
	},
	mounted() {
		if(this.info.uid === this.$user.getUid()){
			this.getAuthBidsList();
		}else {
			this.getBidsList();
		}
		this.getCompanyList();
		this.getCompanyTotal();
	},
	methods: {
		toExport(item){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthBids/export',
				datas:{id:item.id},
				success(res){
					let _url = res.url.indexOf('http') >= 0 ? res.url : _this.$config.assetsUrl + res.url;
					window.open(_url,'_blank')
				}
			})
		},
		toExportFile(item){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthBids/exportFile',
				datas:{id:item.id},
				success(res){
					let _url = res.url.indexOf('http') >= 0 ? res.url : _this.$config.assetsUrl + res.url;
					window.open(_url,'_blank')
				}
			})
		},
		getBidsList(){
			let _this = this;
			this.$http.httpPost({
				url:'/Bids/listPage',
				datas:this.bidsSearch,
				success(res){
					_this.bidsList = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		getAuthBidsList(){
			let _this = this;
			let _datas = JSON.parse(JSON.stringify(this.bidsSearch));
			delete _datas.uid;
			this.$http.httpPost({
				url:'/AuthBids/listPage',
				datas:_datas,
				success(res){
					_this.bidsList = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		pageChange(e){
			this.bidsSearch.page = e;
			// this.getBidsList();
			if(this.info.uid === this.$user.getUid()){
				this.getAuthBidsList();
			}else {
				this.getBidsList();
			}
			
			document.getElementById('bids_box').scrollIntoView({ behavior: 'smooth' })
			// $('#bids_box').offset().top
		},
		getCompanyList(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserCompany/listAll',
				datas:{...this.companySearch,page:1,limit:5},
				success(res){
					_this.companyList = res;
				}
			})
		},
		getCompanyTotal(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserTotal/detail',
				datas:{uid:this.info.uid},
				success(res){
					_this.companyTotal = res;
				}
			})
		},
	}
}
</script>
<style scoped>
.el-descriptions-item__label.is-bordered-label{background: #EFF2F8 !important;width: 200px !important;color:#333 !important;font-size: 16px !important;text-align: center !important;}
.el-descriptions .is-bordered .el-descriptions-item__cell{border:1px solid #E5E9F1 !important;}
</style>

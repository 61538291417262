<template>
	<div>
		<div id="page-header" class="box-shadow">
			<div class="wrap cl">
				<div class="z dark-2 f16 hand" @click="$router.push({path:'/important'})">
					<img src="/images/laba.png" style="margin-right:12px;"/>重要通知
				</div>
				<div class="y dark-2 f16" v-if="token">
					<a @click="toPush" class="margin-right-20" v-if="(companyInfo && companyInfo.user_type === 'hz') || (userInfo && userInfo.user_type === 'hz')">发布标讯</a>
					<a @click="$router.push({path:'/bbs'})" class="margin-right-20">发布需求</a>
					<a @click="$router.push({path:'/user'})" class="margin-right-20">个人中心</a>{{ $user.getMobile() }}
					<!--<a @click="$router.push({path:'/changepass'})" class="margin-left">修改密码</a><a @click="$router.push({path:'/user'})" class="margin-left">用户中心</a>--><a @click="logout" class="margin-left">退出</a>
				</div>
				<div class="y dark-2 f16" v-else>
					<a @click="showLogin = true" class="margin-right-20" v-if="companyInfo && companyInfo.user_type === 'hz'">信息发布</a>
					<a @click="showLogin = true" class="margin-right-20">个人中心</a><a @click="showLogin = true">登录</a>/<a @click="$router.push({path:'/register'})">注册</a>
				</div>
			</div>
		</div>
		<div class="wrap">
			<div class="page-logo cl">
				<div class="z" style="margin-top:33px;">
					<img src="/images/logo.png" width="282" height="78" class="vm"/>
				</div>
				<div class="z" style="margin-left:112px;margin-top:48px !important;">
					<div class="border-blue border-w-1 search cl hide">
						<img src="/images/search.png" class="z" style="margin-top:14px;"/>
						<input class="z" v-model="title" placeholder="请输入招标项目名称或关键字"/>
						<span class="y inline-block f-sy bg-blue btn text-center hand" @click="toSearch">搜索</span>
					</div>
				</div>
				<div class="y" style="margin-top: 48px;width: 224px;height:45px;line-height: 43px;">
					<!--                    <img src="./images/phone.png" style="margin-right: 14px;"/>400-5151-56-->
					<img src="/images/call.png" width="225"/>
				</div>
			</div>
			<div id="nav">
				<ul class="cl">
					<li><a @click="$router.push({path:'/'})" :class="$route.path === '/' ? 'on' : ''">首页</a></li>
					<template v-for="(item,index) in nav">
						<li v-if="item.path !== '/bids/odd'&&item.path!='/coldChain'">
							<a @click="$router.push({path:item.path})" :class="$route.path.indexOf(item.path) >= 0 ? 'on' : ''" style="height: 33px;">{{ item.label }}<span v-if="item.ext" :style="{color:item.extColor,fontSize:item.extSize}">{{ item.ext }}</span></a>
						</li>
						<el-dropdown class="z" placement="bottom-start" v-else>
                      <span class="f18 hand" style="height: 33px;line-height: 33px;color:#727171;">
                        {{ item.label }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
							<el-dropdown-menu slot="dropdown">
								<!-- <el-dropdown-item @click.native="$router.push({path:'/notice',query:{secondType:'coldBid'}})">冷链招标</el-dropdown-item>
								<el-dropdown-item @click.native="$router.push({path:'/notice',query:{secondType:'coldNeed'}})">冷链需求</el-dropdown-item> -->
								<el-dropdown-item @click.native="$router.push({path:'/coldChain/coldBid'})">冷链招标</el-dropdown-item>
								<el-dropdown-item @click.native="$router.push({path:'/coldChain/coldNeed'})">冷链需求</el-dropdown-item>
								<!-- <el-dropdown-item @click.native="$router.push({path:'/bids/zhaobiao'})">物流招标</el-dropdown-item>
								<el-dropdown-item @click.native="$router.push({path:'/bids/xqfb'})">需求发布</el-dropdown-item> -->
								<!--                        <el-dropdown-item @click.native="$router.push({path:'/bids/sbcg'})">设备采购</el-dropdown-item>-->
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</ul>
			</div>
			<slot @toLogin="toLogin"></slot>
			<div class="cl margin-top bg-gray-6" style="padding:43px 0 40px 0;height: 280px;">
				<div class="z" style="width: 32.7%">
					<div class="text-center f18 dark-2 f-sy">客户服务</div>
					<div class="cl"></div>
					<div class="text-center" style="margin-top: 26px;">
                        <span class="hand inline-block rel hover-show" style="margin-right: 32px;">
                            <el-popover
	                            placement="bottom"
	                            width="300"
	                            trigger="hover">
                                <div class="hide" style="height:84px;width:84px;border:1px solid #595757" slot="reference">
                                    <img src="/images/s2.png"/>
                                </div>
                                <div class="f16">
                                    <div class="cl text-left f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>客服热线：{{ setting.service_tel }}
                                    </div>
                                    <div class="cl text-left margin-top f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>会员咨询：{{ setting.vip_tel }}
                                    </div>
                                    <div class="cl text-left margin-top f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>招标发布：{{ setting.bids_push_tel }}
                                    </div>
                                </div>
                              </el-popover>
                            <div class="margin-top dark-2 f16 text-center f-sy">客服热线</div>
                        </span>
						<span class="hand inline-block rel hover-show" style="margin-right: 32px;">
                            <el-popover
	                            placement="bottom"
	                            width="106"
	                            trigger="hover">
                                <div class="hide" style="height:84px;width:84px;border:1px solid #595757" slot="reference">
                                    <img src="/images/s1.png"/>
                                </div>
                                <div class="">
                                    <img :src="setting.qq_service_qrcode.indexOf('http')>= 0 ? setting.qq_service_qrcode : $config.assetsUrl + setting.qq_service_qrcode" width="80" height="80" v-if="setting.qq_service_qrcode"/>
                                </div>
                              </el-popover>
                            <div class="margin-top dark-2 f16 text-center f-sy">在线客服</div>
                        </span>
						<!--                        <span class="hand inline-block rel hover-show">-->
						<!--                            <div class="rel">-->
						<!--                                <img src="/images/s1.png"/>-->
						<!--                                <div class="margin-top dark-2 f16 text-center f-sy">在线客服</div>-->
						<!--                                <div class="box-shadow hover-show-item padding f16 dark-2 arrow-up abs bg-light" style="top:80px;z-index: 10;left:-7px;" v-if="setting && setting.qq_service_qrcode">-->
						<!--                                    <img :src="setting.qq_service_qrcode.indexOf('http')>= 0 ? setting.qq_service_qrcode : $config.assetsUrl + setting.qq_service_qrcode" width="80"/>-->
						<!--                                </div>-->
						<!--                            </div>-->
						<!--                        </span>-->
					</div>
				</div>
				<div class="border-right z margin-top-30" style="width: 0;height: 150px;"></div>
				<div class="z" style="width: 34.6%;padding:0 60px;">
					<div class="text-center f18 dark-2 f-sy">中国物流招标网</div>
					<div class="dark-2 margin-top-20 f16 f-sy" style="line-height: 26px;letter-spacing: 0.2px;">
						专注物流招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时准确的招标信息和推送服务。
					</div>
				</div>
				<div class="border-right z margin-top-30" style="width: 0;height: 150px;"></div>
				<div class="z" style="width: 31.9%">
					<div class="text-center f18 dark-2 f-sy">关注我们</div>
					<div class="cl"></div>
					<div class="text-center" style="margin-top:20px;">
            <span class="inline-block" style="margin-right: 38px;" v-if="setting.weixin_mini_app_qrcode">
              <div>
	              <img :src="setting.weixin_mini_app_qrcode.indexOf('http') >= 0 ? setting.weixin_mini_app_qrcode : $config.assetsUrl + setting.weixin_mini_app_qrcode" height="97" width="97"/>
              </div>
              <div class="cl"></div>
              <!--                            <div class="dark-2 margin-top-5 f16 f-sy">订阅号</div>-->
              <div class="dark-2 margin-top-5 f16 f-sy">小程序</div>
            </span>
						<span class="inline-block" v-if="setting.weixin_service_qrcode">
              <div>
	              <img :src="setting.weixin_service_qrcode.indexOf('http') >= 0 ? setting.weixin_service_qrcode : $config.assetsUrl + setting.weixin_service_qrcode" height="97" width="97"/>
              </div>
              <div class="dark-2 margin-top-5 f16 f-sy">服务号</div>
            </span>
					</div>
				</div>
			</div>
		</div>
		<div class="text-center bg-blue f14" style="height: 134px;padding-top:35px;">
			<div class="light f-sy">友情链接：
				<template v-for="(item,index) in friendList">
					<a class="light hover-under no-visited" target="_blank" :href="item.url.indexOf('http') >= 0 ? item.url : ''">{{ item.title }}</a>
					<template v-if="index + 1 < friendList.length"> |</template>
				</template>
			</div>
			<div class="light hover-under no-visited light-sun margin-top-15 f-sy" v-html="setting.page_footer" v-if="setting.page_footer"></div>
		</div>
		<el-dialog title="" custom-class="border-radius-20" :visible.sync="showLogin" width="530px" center @close="onCloseLoginDialog">
			<div class="text-center">
				<img src="/images/logo.png" width="200"/>
			</div>
			<div class="wrap margin-top-50" style="width: 380px !important;">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-button class="block" :type="loginType === 'pass' ? 'primary' : ''" @click="changeType('pass')">密码登录</el-button>
					</el-col>
					<el-col :span="12">
						<el-button class="block" :type="loginType === 'qr' ? 'primary' : ''" @click="changeType('qr')">扫码登录</el-button>
					</el-col>
				</el-row>
			</div>
			<div class="margin-l-r-50 margin-top-50">
				<template v-if="loginType === 'pass'">
					<div class="box-shadow bg-light cl border-radius-5 padding">
						<img src="/images/icon_username.png" class="z"/>
						<input type="text" class="margin-left" v-model="formData.mobile" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入手机号"/>
					</div>
					<div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">
						<img src="/images/icon_password.png" class="z"/>
						<input type="password" class="margin-left" v-model="formData.password" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入密码"/>
					</div>
					<div class="margin-top-40 dialog-footer">
						<el-button type="primary" class="block" @click="login">登 录</el-button>
					</div>
					<div class="cl margin-top padding-bottom-30">
						<a href="/findpass" class="y">忘记密码？立即找回</a>
					</div>
				</template>
				<template v-else>
					<div class="text-center margin-top-30 margin-auto rel" :class="logined ? '' : 'show-refresh-qrcode'" style="width: 200px;" v-if="qrcode">
						<img :src="qrcode" class="border-green border-w-5 border-radius-5" height="200" width="200"/>
						<div class="hand show-qrcode none text-center light abs bg-dark-op" style="top:0;left:0;right:0;bottom: 0;" @click="getQrCode">
							<i class="margin-top-50 el-icon-refresh light f38"></i>
							<div class="margin-top-30">刷新二维码</div>
						</div>
						<div class="hand text-center light abs bg-dark-op" style="top:0;left:0;right:0;bottom: 0;" v-if="logined">
							<i class="margin-top-50 el-icon-success green f38"></i>
							<div class="margin-top-30">登录成功</div>
						</div>
					</div>
					<div class="dark-3 text-center margin-top-30 padding-bottom">手机微信扫一扫登录</div>
				</template>
			</div>
		</el-dialog>
		<div class="fixed" :style="'right:0;bottom:'+setting.side_bottom+'px;'" v-if="setting">
			<img :src="setting.weixin_mini_app_qrcode.indexOf('http') >= 0 ? setting.weixin_mini_app_qrcode : $config.assetsUrl + setting.weixin_mini_app_qrcode" :width="setting.side_qrcode_width" style="margin-bottom:20px;display: block;" v-if="setting.weixin_mini_app_qrcode"/>
			<img :src="setting.side_qrcode.indexOf('http') >= 0 ? setting.side_qrcode : $config.assetsUrl + setting.side_qrcode" :width="setting.side_qrcode_width" style="margin-bottom:20px;display: block;" v-if="setting.side_qrcode"/>
			<div class="cl"></div>
			<div style="width: 44px;" class="y">
				<el-popover
					placement="left"
					width="250"
					popper-class="padding-20"
					trigger="hover">
					<a title="点击这里给我发消息" class="f16" :href="'http://wpa.qq.com/msgrd?v=3&amp;uin='+setting.service_qq+'&amp;site=www.cactussoft.cn&amp;menu=yes'" target="_blank">
						<img src="/images/qq2.png" class="margin-right" height="24"/>咨询在线客服
					</a>
					<div class="bg-dark-3 padding hand bg-red-hover" slot="reference">
						<img src="/images/qq.png" width="24"/>
					</div>
				</el-popover>
				<el-popover
					placement="left"
					width="250"
					popper-class="padding-20"
					trigger="hover">
					<div class="cl">
						<div class="z bg-gray-2-1 border-radius padding"><img src="/images/dianhua2.png" height="26"/></div>
						<div class="z margin-left" style="height: 46px;line-height: 23px;">
							<div class="f16">会员咨询</div>
							<div class="f16">{{ setting.vip_tel }}</div>
						</div>
					</div>
					<div class="cl margin-top">
						<div class="z bg-gray-2-1 border-radius padding"><img src="/images/dianhua2.png" height="26"/></div>
						<div class="z margin-left" style="height: 46px;line-height: 23px;">
							<div class="f16">招标发布</div>
							<div class="f16">{{ setting.bids_push_tel }}</div>
						</div>
					</div>
					<div class="cl margin-top">
						<div class="z bg-gray-2-1 border-radius padding"><img src="/images/dianhua2.png" height="26"/></div>
						<div class="z margin-left" style="height: 46px;line-height: 23px;">
							<div class="f16">客服热线</div>
							<div class="f16">{{ setting.service_tel }}</div>
						</div>
					</div>
					<div class="bg-dark-3 padding margin-top hand bg-red-hover" slot="reference">
						<img src="/images/dianhua.png" width="24"/>
					</div>
				</el-popover>
				<div class="bg-dark-3 text-center padding margin-top hand bg-red-hover" @click="toTop">
					<!--                <img src="/images/ai-code.png" width="24"/>-->
					<i class="el-icon-caret-top light f24"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MainLayout",
	data() {
		return {
			loginType:'pass',
			formData: {
				mobile: '',
				password: ''
			},
			setting: {
				weixin_service_qrcode: '',
				weixin_app_qrcode: '',
				qq_service_qrcode: '',
				service_tel: '',
				vip_tel: '',
				bids_push_tel: '',
				service_qq: '',
				side_qrcode: '',
				side_qrcode_width: '',
				side_bottom: '',
				page_footer: '',
				weixin_mini_app_qrcode: '',
				default_avatar: ''
			},
			showLogin: false,
			nav: [
				{label: '招标公告', path: '/bids/notice'},
				// {label:'散单平台',path:'/bids/odd',ext:'付费',extColor:'red',extSize:'14px'},
				// {label:'冷链专区',path:'/coldChain'},
				// {label:'冷链专区',path:'/coldChain/coldBid'},
				{label: '冷链专区', path: '/bids/cold'},
				{label: '医药专区', path: '/bids/medicine'},
				{label: '设备采购', path: '/bids/device'},
				// {label:'仓储供给',path:'/bids/storage'},
				// {label:'政策法规',path:'/news/policy'},
				{label: '散单需求', path: '/bbs'},
				{label: '物流资讯', path: '/news/logistics'},
				{label: '会员频道', path: '/vip'},
				{label: '关于我们', path: '/about'},
			],
			token: '',
			title: '',
			timeId: null,
			companyInfo: null,
			userInfo:null,
			
			qrcode:'',
			key:'',
			qrtime:null,
			logined:false,
			
			friendList: []
		}
	},
	created() {
		this.title = this.$route.query.title ? this.$route.query.title : '';
	},
	mounted() {
		this.token = this.$user.getToken() ? this.$user.getToken() : '';
		this.getSystem();
		
		this.checkLogin();
		
		this.getFriendLink();
		
		if (this.token) {
			this.getUserType();
			this.getUser();
		}
		console.log('===ds=f=ds==');
	},
	methods: {
		onCloseLoginDialog(){
			this.formData.password = '';
			this.formData.mobile = '';
			this.loginType = 'pass';
			
			if(this.qrtime){
				clearInterval(this.qrtime);
				this.qrtime = null;
			}
		},
		getQrCode(){
			let _this = this;
			this.$http.httpPost({
				url:'/Common/getLoginQrCode',
				datas:null,
				success(res){
					_this.qrcode = res.qrcode;
					_this.key = res.key;
					
					if(_this.qrtime){
						clearInterval(_this.qrtime);
						_this.qrtime = null;
					}
					
					_this.qrtime = setInterval(_this.checkQrLogin, 2000);
				}
			})
		},
		checkQrLogin(){
			//wx/user
			let _this = this;
			this.$http.httpPost({
				url: '/Common/checkLogin',
				datas: {key: this.key},
				success(res) {
					console.log(res);
					if(res.token) {
						_this.$user.setToken(res.token);
						_this.$user.setMobile(res.mobile);
						_this.$user.setUid(res.uid);
						_this.$user.setUserName(res.username);
						_this.$user.setUserType(res.user_type ? res.user_type : '');
						
						_this.$utils.success('登录成功');
						
						_this.token = res.token;
						
						_this.formData = {
							username: '',
							password: ''
						};
						
						_this.showLogin = false;
						
						window.location.reload();
					}
				}
			})
		},
		changeType(e){
			this.loginType = e;
			
			if(e === 'qr'){
				this.getQrCode();
			}else{
				if(this.qrtime){
					clearInterval(this.qrtime);
					this.qrtime = null;
				}
			}
		},
		toPush() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUserCompany/clearDetail',
				datas: null,
				success(res) {
					let _qrcode = _this.$utils.localGet('weixin_mini_app_qrcode');
					if (!res) {
						// let _content = '';
						// if (_qrcode) {
						// 	_content = '<div class="text-center">' +
						// 		'<div class="f16">请先使用微信扫描二维码完善信息</div>' +
						// 		'<img class="margin-top" src="' + _qrcode + '" height="200"/>' +
						// 		'</div>';
						// } else {
						// 	_content = '请先完善信息'
						// }
						
						_this.$confirm('请先完善信息', '提示', {
							confirmButtonText: '立即完善',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push({path:'/user/company-profile'});
						}).catch(() => {});
					} else {
						if (res.user_type === 'gys') {
							_this.$alert('你是供应商，不能发布信息', '提示', {
								confirmButtonText: '知道了',
							});
							
							return;
						}
						
						if (res.state !== 1) {
							// let _content = '';
							// if (_qrcode) {
							// 	_content = '<div class="text-center">' +
							// 		'<div class="f16">您的信息还在审核中，扫描二维码了解详情</div>' +
							// 		'<img class="margin-top" src="' + _qrcode + '" height="200"/>' +
							// 		'</div>';
							// } else {
							// 	_content = '您的信息还在审核中'
							// }
							
							_this.$alert('您的信息还在审核中', '提示', {
								confirmButtonText: '知道了',
								dangerouslyUseHTMLString: true
							});
							
							return;
						}
						
						_this.$router.push({path: '/push2'});
					}
				}
			})
		},
		getUserType() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUserCompany/clearDetail',
				datas: null,
				success(res) {
					_this.companyInfo = res;
				}
			})
			// this.usertype = this.$user.getUserType();
		},
		getUser() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUser/detail',
				datas: null,
				success(res) {
					_this.userInfo = res;
				}
			})
		},
		checkLogin() {
			this.timeId = setInterval(this.timeLogin, 5000);
		},
		timeLogin() {
			if (!this.token) {
				this.token = this.$user.getToken();
			} else {
				clearInterval(this.timeId);
				this.timeId = null;
			}
		},
		reLogin() {
			this.token = this.$user.getToken();
		},
		logout() {
			this.$utils.localClear();
			this.$utils.success('已退出登录');
			
			this.token = '';
			
			if (this.$route.path === '/changepass') {
				this.$router.replace({path: '/'});
			} else {
				window.location.reload();
			}
		},
		login() {
			let _this = this;
			this.$http.httpPost({
				url: '/User/login',
				datas: this.formData,
				success: (res) => {
					console.log(res);
					_this.$user.setToken(res.token);
					_this.$user.setMobile(res.mobile);
					_this.$user.setUid(res.uid);
					_this.$user.setUserName(res.username);
					_this.$user.setUserType(res.user_type ? res.user_type : '');
					
					_this.$utils.success('登录成功');
					
					_this.token = res.token;
					
					_this.formData = {
						username: '',
						password: ''
					};
					
					_this.showLogin = false;
					
					window.location.reload();
				}
			});
		},
		toLogin() {
			this.showLogin = true;
		},
		toSearch() {
			this.$router.push({path: '/bidssearch', query: {title: this.title}});
		},
		getSystem() {
			let _this = this;
			
			let _skeys = [];
			
			for (let k in this.setting) {
				_skeys.push(k);
			}
			
			this.$http.httpPost({
				url: '/SystemSetting/getSystem',
				datas: {skey: _skeys},
				success: (res) => {
					_this.setting = res;
				}
			});
		},
		getFriendLink() {
			let _this = this;
			
			this.$http.httpPost({
				url: '/FriendLink/listAll',
				datas: {state: 1, orderBy: 'sort', sortBy: 'asc'},
				success: (res) => {
					_this.friendList = res;
				}
			});
		},
		toTop() {
			document.documentElement.scrollTop = 0;
			// document.getElementById("app").scrollIntoView();
		}
	},
	destroyed() {
		if (this.timeId) {
			clearInterval(this.timeId);
			this.timeId = null
		}
	}
}
</script>
<style scoped>
.el-dropdown-menu li {
	float: left;
}
</style>

<template>
    <div class="cl hide margin-top-15 padding-left-right bg-gray-4 dark-2 f18" style="height: 45px;line-height: 45px;">
        <a class="z" @click="$router.push({path:'/'})">首页</a>
        <span class="z padding-left-right" style="line-height: 42px;">></span>
        <template v-for="(item,index) in values">
            <a class="z" @click="$router.push({path:item.path})" v-if="index + 1 < values.length">{{item.name}}</a>
            <span class="z" v-else>{{item.name}}</span>
            <span class="z padding-left-right" style="line-height: 42px;" v-if="index + 1 < values.length">></span>
        </template>
    </div>
</template>

<script>
export default {
    name: "Bread",
    props:{
        list:{
            type:[Array],
            default(){
                return []
            }
        }
    },
    data(){
        return{
            values:[]
        }
    },
    watch:{
        list(n,o){
            this.values = n.length > 0 ? n : this.$route.meta.bread;
        }
    },
    created() {
        this.values = this.list.length > 0 ? this.list : this.$route.meta.bread;
    },
}
</script>

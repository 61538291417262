<template>
    <m-main-layout>
        <bread></bread>
        <div class="cl margin-top">
            <div class="z hide" style="width: 860px;">
                <div class="padding-20">
                    <template v-for="(item,index) in list">
                        <div class="cl padding-top-bottom-20">
                            <div class="hand" style="width: 820px;" @click="toDetail(item.id)">
                                <div class="f16">
                                    <a :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">{{item.title}}</a>
                                </div>
                                <div class="cl hide margin-top-50" style="height: 21px;">
                                    <bid-tag type="tuijian"></bid-tag>
                                    <span class="z f12 dark-4-1 margin-left-20 margin-top">
                                        {{$moment(item.create_time).format('YYYY-MM-DD')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="line-1"></div>
                    </template>
                </div>
            </div>
            <div class="y bg-gray-4 hide padding-15" style="width: 317px;">
                <zbtj></zbtj>
                <div class="line-1"></div>
                <ccgq></ccgq>
            </div>
        </div>
        <div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "Tuijian",
    data(){
        return{
            searchForm:{
                page:1
            },
            pageSize:8,
            totalCount:0,
            type:'',
            list:[],
            bread:[]
        }
    },
    created() {
        this.type = this.$route.params.type ? this.$route.params.type : '';
        this.searchForm.page = this.$route.query.page ? this.$route.query.page : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        pageChange(e){
            this.searchForm.page = e;

            this.$router.replace({query:{...this.searchForm}});

            // this.getList();
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listPage',
                datas:{is_tuijian:1,orderBy:'sort desc,create_time',sortBy:'desc',pageSize:this.pageSize,state:1},
                success(res){
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            })
        },
        toDetail(id){
            window.open('/bids/detail/'+id,'_blank')
        }
    }
}
</script>

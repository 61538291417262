<template>
	<div class="margin-bottom-30" v-if="info">
		<a class="dark-2 hand f22" style="width: 393px;" target="_blank" rel="opener" :href="'/company/detail/'+info.uid">
			<i class="iconfont icon-vip3 vm f34" style="color: orange" v-if="info.user && info.user.vip_state === 1"></i><i class="iconfont icon-vip3 vm f34" style="color: #bbb" v-if="info.user && info.user.vip_state === 0"></i>{{info.company}}
		</a>
		<el-row class="margin-top-20">
			<el-col :span="6">
				<span class="z iconfont icon-gold-supplie-fill blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">所属行业</span>
				<span class="z dark-1 f14 margin-left text-line-1" style="width: 120px;">{{info.industry_str}}</span>
			</el-col>
			<el-col :span="6" class="cl">
				<span class="z iconfont icon-product blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">所属区域</span>
				<span class="z dark-1 f14 margin-left text-line-1" style="width: 120px;">{{info.province_str}}</span>
			</el-col>
			<el-col :span="6">
				<span class="iconfont icon-template-fill blue-2"></span>
				<span class="dark-3 f14 margin-left-5">发标数量</span>
				<span class="dark-1 f14 margin-left">{{info.bids_total_count}}</span>
			</el-col>
<!--			<el-col :span="6">-->
<!--				<span class="iconfont icon-integral-fill blue-2"></span>-->
<!--				<span class="dark-3 f14 margin-left-5">发标月份</span>-->
<!--				<span class="dark-1 f14 margin-left">21</span>-->
<!--			</el-col>-->
		</el-row>
	</div>
</template>

<script>
export default {
	name: "huozhu",
	data() {
		return {}
	},
	props:{
		info:{
			type:[Object,Array],
			default:null
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>

import Vue from 'vue';
let vm = new Vue();

import Config from './config'

export default {
	formatImagePath(path){
		if(!path){
			return ''
		}
		return path.indexOf('http') >= 0 ? path : Config.assetsUrl + path;
	},
	evalGet(obj,pre,target){
		let info = obj;
		return eval(pre+'.'+target);
	},
	randomWords(n = 6){
		//创建26个字母数组
		let arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
		let idvalue ='';
		for(let i=0;i<n;i++){
			idvalue+=arr[Math.floor(Math.random()*26)];
		}
		return idvalue;
	},
	success(title){
		vm.$message({
			message: title,
			type: 'success'
		});
	},
	warning(title){
		vm.$message({
			message: title,
			type: 'warning'
		});
	},
	error(title){
		vm.$message({
			message: title,
			type: 'error'
		});
	},
	isWxImg(img) {
		if (img) {
			if (!/\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(img)) {
				return true;
			}
			
			return false;
		} else {
			return false;
		}
	},
	mustLetter(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		let _v = /[a-z0-9]$/;
		if (!_v.test(value)) {
			return callback(new Error('必须是小写字母或数字'));
		} else {
			return callback();
		}
	},
	mustNumber(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		let _v = /(^[1-9]\d*$)/;
		if (!_v.test(value)) {
			return callback(new Error('必须是整数'));
		} else {
			return callback();
		}
	},
	must09(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		// if (!Number.isInteger(value)) {
		//     return callback(new Error('请输入数字值'));
		// }
		
		if(value.length>1){
			let flagRex =/^0/;
			if(flagRex.test(value)){
				return callback(new Error('请输入正确的数字'));
			}
		}
		
		let _v = /(^[0-9]\d*$)/;
		if (!_v.test(value)) {
			return callback(new Error('必须是整数'));
		} else {
			return callback();
		}
	},
	biggerZero(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		if (!Number.isInteger(value) && typeof value !== 'number') {
			return callback(new Error('请输入数字值'));
		} else {
			if (value < 0) {
				return callback(new Error('必须>=0'));
			} else {
				return callback();
			}
		}
	},
	biggerZero2(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		// console.log(typeof value);
		if (!Number.isInteger(value) && typeof value !== 'number') {
			return callback(new Error('请输入数字值'));
		} else {
			if (value <= 0) {
				return callback(new Error('必须大于0'));
			} else {
				return callback();
			}
		}
	},
	rulePercent(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
		
		if (!_v.test(value)) {
			return callback(new Error('请输入数字值'));
		} else {
			if (parseFloat(value) < 0 || parseFloat(value) > 100) {
				return callback(new Error('必须>=0 并且 <= 100'));
			} else {
				return callback();
			}
		}
	},
	rulePriceNotZero(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
		
		if (!_v.test(value)) {
			return callback(new Error('请输入数字值'));
		} else {
			if (parseFloat(value) <= 0) {
				return callback(new Error('必须大于0'));
			} else {
				return callback();
			}
		}
	},
	rulePriceCanZero(rule, value, callback){
		if (value === '') {
			return callback(new Error('不能为空'));
		}
		
		let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
		
		if (!_v.test(value)) {
			return callback(new Error('请输入数字值'));
		} else {
			if (parseFloat(value) < 0) {
				return callback(new Error('必须大于0'));
			} else {
				return callback();
			}
		}
	},
	calcDescartes (array) {
		
		let res = [];
		let _arr = [];
		let skus = [];
		
		for(let i=0;i < array.length;i++){
			console.log(array[i].spec_value.length);
			if(array[i].spec_value.length > 0) {
				_arr.push(array[i].spec_value);
			}
		}
		
		
		if(_arr.length > 0) {
			if (_arr.length < 2) {
				
				for (let i = 0; i < _arr[0].length; i++) {
					res.push([_arr[0][i]]);
				}
			} else {
				res = [].reduce.call(_arr, function (col, set) {
					let _res = [];
					
					col.forEach(function (c) {
						set.forEach(function (s) {
							let t = [].concat(Array.isArray(c) ? c : [c]);
							t.push(s);
							_res.push(t);
							
						})
					});
					
					return _res;
				});
			}
			
			
			
			for (let k = 0; k < res.length; k++) {
				let _sku = [];
				for (let j = 0; j < res[k].length; j++) {
					_sku.push({
						id: array[j].id,
						name: array[j].name,
						value_id: res[k][j].id,
						value_name: res[k][j].name
					});
				}
				skus.push({spec: _sku, price: 0.00, stock: 0, code: '', img: ''});
			}
			
			return skus;
		}
		
		return skus;
	},
	checkParams(rule,val){
		for(let item in rule){
			if(rule[item].check === 'require'){
				if(this.isUndefined(val[item].trim())){
					console.error('param '+item+' must be require!');
					return false;
				}
			}
			
			if(typeof val[item] !== rule[item].type && !this.isUndefined(val[item])){
				console.error('param '+item+' must be '+rule[item].type+'!');
				return false;
			}
		}
		return true;
	},
	formatPrice(price){
		return (Math.floor(Number(price) * 100) / 100).toFixed(2);
	},
	isUndefined(variable) {
		return (typeof variable == 'undefined' || variable === null || variable === '');
	},
	sessionSet(key,val){
		// if((typeof val === 'string' || typeof val === 'number') && typeof key === 'string') {
		return sessionStorage.setItem(key, val);
		// }else{
		//     console.error('value & key must be a string!');
		// }
		// return false;
	},
	sessionGet(key){
		if(typeof key === 'string'){
			return sessionStorage.getItem(key);
		}else{
			console.error('key must be a string!');
		}
		return false;
	},
	sessionDel(key){
		if(typeof key === 'string'){
			return sessionStorage.removeItem(key);
		}else{
			console.error('key must be a string!');
		}
		return false;
	},
	sessionClear(){
		return sessionStorage.clear();
	},
	
	localSet(key,val){
		if((typeof val === 'string' || typeof val === 'number') && typeof key === 'string') {
			return localStorage.setItem(key, val);
		}else{
			console.error('value & key must be a string!');
		}
		return false;
	},
	localGet(key){
		if(typeof key === 'string'){
			return localStorage.getItem(key);
		}else{
			console.error('key must be a string!');
		}
		return false;
	},
	localDel(key){
		if(typeof key === 'string'){
			return localStorage.removeItem(key);
		}else{
			console.error('key must be a string!');
		}
		return false;
	},
	localClear(){
		return localStorage.clear();
	},
	
	copy(data){
		let oInput = document.createElement('input');
		oInput.value = data;
		oInput.classList.add('copy-input');
		document.body.appendChild(oInput);
		vm.$nextTick(() => {
			oInput.select(); // 选择对象;
			console.log(oInput.value);
			document.execCommand("Copy"); // 执行浏览器复制命令
			vm.$message({
				message: '复制成功',
				type: 'success'
			});
			oInput.remove();
		});
	},
}

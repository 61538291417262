<template>
    <user-layout>
        <user-menu path="/user">
            <div class="border-w-1 border-dark-7 bg-light padding-top-bottom-40 padding-left-right border-radius-10">
                <div class="padding-left-40 dark-2 f20">我的收藏</div>
                <div class="cl border-bottom-1 border-dark-7 dark-2 padding-top-40 padding-bottom-20">
                    <div class="z padding-left-40" style="width: 68%;">名称</div>
                    <div class="z" style="width: 12%;">发布时间</div>
                    <div class="z text-center" style="width: 20%;">状态</div>
                </div>
                <template v-if="list.length > 0">
                    <div class="cl padding-top-30 padding-bottom-30 dark-2" style="line-height: 22px;" v-for="(item,index) in list">
                        <div class="z padding-left-right-40" style="width: 68%;">
                            <a class="dark-2 text-line-1 hand f16"  :href="'/bids/detail/'+item.tid" target="_blank" rel="opener">{{item.slug.title}}</a></div>
                        <div class="z" style="width: 12%;">{{item.slug.push_time}}</div>
                        <div class="z text-center" style="width: 20%;">
<!--                            <i class="f22 el-icon-star-on yellow"></i>-->
                            <log-button only-icon type="favorite" akey="bids" :tid="item.tid" :is-one="1" class="margin-left-20" on-class="f22 iconfont icon-star-on yellow" off-class="f22 iconfont icon-star-off dark-3"></log-button>
                        </div>
                    </div>
                </template>
                <div class="text-center padding-top-bottom-100 f18 dark-3" v-else>
                    暂无收藏记录
                </div>
                <div class="bg-gray-4 margin-40 padding margin-auto text-center" style="height: 52px;border-radius: 26px;" v-if="list.length > 0">
                    <el-pagination
                        background
                        @current-change="pageChange"
                        :current-page="searchForm.page"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalCount">
                    </el-pagination>
                </div>
            </div>
        </user-menu>
    </user-layout>
</template>

<script>
export default {
    name: "favourite",
    data(){
        return{
            searchForm:{
                page:1
            },
            pageSize:6,
            totalCount:0,
            list:[],
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        pageChange(e){
            this.searchForm.page = e;

            this.getList();
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/UserLog/listPage',
                datas:{page:this.searchForm.page,pageSize:this.pageSize,type:'favorite'},
                success(res){
                    // console.log(res);
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            })
        }
    }
}
</script>

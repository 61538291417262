<template>
	<el-cascader
		size="large"
		:options="options"
		v-model="values"
		@change="change">
	</el-cascader>
</template>

<script>
import {provinceAndCityData,CodeToText} from 'element-china-area-data'

export default {
	name: "ProvinceSelect",
	data() {
		return {
			options: provinceAndCityData,
			values: [],
			name: '',
			province_code:'',
			province_name:'',
			city_code:'',
			city_name:''
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: Array,
			default() {
				return []
			}
		}
	},
	created() {
		this.values = this.value;
	},
	watch: {
		value(n, o) {
			this.values = n;
		}
	},
	mounted() {
	
	},
	methods: {
		change(e) {
			this.$emit("change", e);
			this.province_code = e[0];
			this.province_name = CodeToText[e[0]];
			this.city_code = e[1];
			this.city_name = CodeToText[e[1]];
			
			this.$emit('change-area',{provinceCode:this.province_code,provinceName:this.province_name,cityCode:this.city_code,cityName:this.city_name})
		}
	}
}
</script>

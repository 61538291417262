<template>
	<m-main-layout>
		<bread :list="[{name:'散单需求'}]"></bread>
		<div class="margin-top-20">
			<div class="cl margin-bottom-20">
				<el-button type="primary" class="y" @click="toShowAdd">发布需求</el-button>
			</div>
			<div class="bg-light border-radius-10 margin-bottom-15 padding-15 box-shadow-5" v-for="(item,index) in list">
				<div class="flex flex-row flex-ai">
					<el-avatar class="vm" :size="40" :src="$utils.formatImagePath(item.company && item.company.logo ? item.company.logo : (item.user.avatar ? item.user.avatar : $utils.localGet('default_avatar')))"></el-avatar>
					<div class="inline-block margin-left">
						<div class="f16">{{item.company && item.company.company ? item.company.company : (item.user.company ? item.user.company : '')}}</div>
						<div class="f12 dark-3">{{item.create_time}}</div>
					</div>
				</div>
				<template v-if="item.type === 'blog'">
					<div class="f16 dark-0 margin-top" v-html="item.slug.content" style="white-space: pre-wrap">{{item.slug.content}}</div>
					<image-pre-view :list="item.files" class="margin-top-bottom" v-if="item.files.length > 0"></image-pre-view>
					<!--					<goodView outClass="flex flex-row flex-jc-between mg-t-20" hasapi="{{ false }}" total="{{item.total}}" tid="{{item.tid}}" akey="blog" target_uid="{{item.uid}}" canShare="{{false}}" delUrl="/AuthBlogLog/del" bind:onDel="onDel" bind:onShare="onShare" wx:if="{{item.type === 'blog'}}"></goodView>-->
				</template>
			</div>
			<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
				<el-pagination
					background
					@current-change="pageChange"
					:current-page="searchForm.page"
					:page-size="searchForm.pageSize"
					layout="prev, pager, next, jumper"
					:total="totalCount">
				</el-pagination>
			</div>
		</div>
		<el-dialog append-to-body title="发布需求" :visible.sync="showAddDialog">
			<el-form :model="addForm" label-width="100px" :rules="rules" ref="form">
				<el-form-item label="内容" prop="content">
					<el-input v-model="addForm.content" type="textarea" rows="6" maxlength="500" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="图片" prop="file_ids">
					<image-upload v-model="addForm.files_ids"></image-upload>
				</el-form-item>
				<el-form-item label="">
					<el-checkbox v-model="checkxieyi">点击提交表示您已阅读并同意</el-checkbox>
					<span class="blue b f14 hand" @click="showXieyi = true">《平台服务协议》</span>
				</el-form-item>
			</el-form>
<!--			<el-dialog-->
<!--				width="30%"-->
<!--				title="内层 Dialog"-->
<!--				:visible.sync="innerVisible"-->
<!--				append-to-body>-->
<!--			</el-dialog>-->
			<div slot="footer" class="dialog-footer">
				<el-button @click="showAddDialog = false">取消</el-button>
				<el-button type="primary" @click="toAddBlog">提交</el-button>
			</div>
		</el-dialog>
		<el-dialog title="平台服务协议" :visible.sync="showXieyi">
			<div class="f16" style="max-height: 600px;overflow-y: auto;" v-html="service"></div>
		</el-dialog>
	</m-main-layout>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			searchForm:{
				orderBy:'cc_blog_log.tuijian_time desc,cc_blog_log.create_time',
				sortBy: 'desc',
				content:'',
				page: 1,
				pageSize: 10
			},
			addForm:{
				content:'',
				files_ids:[]
			},
			showAddDialog:false,
			showXieyi:false,
			checkxieyi:false,
			totalCount:0,
			service:'',
			list:[],
			rules:{
				content:[{required:true,message:'请填写内容'}]
			}
		}
	},
	created() {
	},
	mounted() {
		this.getList();
		this.getService()
	},
	methods: {
		getService() {
			let _this = this;
			this.$http.httpPost({
				url: '/SystemSetting/getSystem',
				datas: {skey: 'service'},
				success: (res) => {
					_this.service = res.service;
				}
			});
		},
		toShowAdd(){
			this.addForm.content = '';
			this.addForm.files_ids = [];
			this.showAddDialog = true;
		},
		toAddBlog(){
			let _this = this;
			
			if(!this.checkxieyi){
				this.$message.error('请同意《平台服务协议》')
				return;
			}
			
			this.$refs['form'].validate((valid) => {
				if (valid) {
					this.$http.httpPost({
						url:'/AuthBlog/add',
						datas:_this.addForm,
						success(res){
							_this.$message.success('发布成功，等待审核');
							_this.showAddDialog = false;
							_this.searchForm.page = 1;
							_this.getList();
						}
					})
				} else {
					return false;
				}
			});
			
		},
		pageChange(e) {
			this.searchForm.page = e;
			this.getList();
		},
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: '/BlogLog/listPage',
				datas: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		}
	}
}
</script>

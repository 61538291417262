<template>
    <user-layout>
        <user-menu path="/user/changepass">
            <div class="bg-light border-w-1 border-dark-7 border-radius-10">
                <div class="margin-auto margin-top-50" style="width: 550px;">
                    <div class="border-w-1 border-dark-4 bg-light cl border-radius-10 padding">
                        <input type="password" class="f16 margin-left" v-model="formData.old_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入旧密码"/>
                    </div>
                    <div class="border-w-1 border-dark-4 margin-top-20 bg-light cl border-radius-10 padding">
                        <input type="password" class="f16 margin-left" v-model="formData.new_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入新密码"/>
                    </div>
                    <div class="border-w-1 border-dark-4 margin-top-20 bg-light cl border-radius-10 padding">
                        <input type="password" class="f16 margin-left" v-model="formData.re_new_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请确认新密码"/>
                    </div>
                    <div class="margin-top-50 padding-bottom-30 text-center">
                        <el-button type="primary" @click="changePass" class="padding-left-right-40">确 定</el-button>
                    </div>
                </div>
            </div>
        </user-menu>
    </user-layout>
</template>

<script>
export default {
    name: "changePass",
    data(){
        return{
            formData:{
                old_pass:'',
                new_pass:'',
                re_new_pass:''
            }
        }
    },
    created() {

    },
    mounted() {
    },
    methods:{
        changePass(){
            let _this = this;
            this.$http.httpPost({
                url: '/AuthUser/changePass',
                datas: this.formData,
                success: (res) => {
                    if(res === 'ok'){
                        _this.$utils.localClear();
                        _this.$utils.success('密码修改成功，请重新登录');
                        _this.$router.replace('/');
                    }
                }
            });
        }
    }
}
</script>

<template>
    <div class="bg-light padding-20 border-radius-10 border-w-1 border-dark-7">
        <div class="padding-bottom-20 border-bottom-1 border-dark-7 cl" style="height: 50px;line-height: 30px;">
            <div class="z">
                <div class="cl">
                    <div class="hide z border-radius margin-right-20 text-center" style="line-height:30px;height: 30px;width:30px;background: #f4f8fb;">
                        <span class="dark-3" :class="icon"></span>
                    </div>
                    <div class="z dark-2 f18" :class="titleClass">{{title}}</div>
                </div>
            </div>
            <span class="iconfont icon-arrow-right y f14 dark-3" v-if="isLink"></span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "MCard",
    props:{
        title:{
            type:String,
            default:''
        },
        icon:{
            type:String,
            default:''
        },
	    titleClass:{
            type:String,
            default:''
        },
        isLink:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<template>
    <span class="z block-tag" v-if="type === 'odd'">散单</span>
    <span class="z block-tag" v-else-if="type === 'storage'">仓储</span>
    <span class="z block-tag" v-else-if="type === 'device'">设备</span>
    <span class="z block-tag" v-else-if="type === 'notice'">公告</span>
    <span class="z block-tag" v-else-if="type === 'tuijian'">推荐</span>
</template>

<script>
export default {
    name: "BidTag",
    props:{
        type:{
            type:String,
            default:''
        }
    }
}
</script>

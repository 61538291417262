import Vue from 'vue';
let vm = new Vue();
import axios from 'axios'
import qs from 'qs';

import Config from './config'
import Utils from './utils'
import User from './user'
import Router from '../router'

import md5 from 'js-md5';
import Moment from 'moment';

// export default {
const http = {
    httpPost:function(obj){
        let _temp_data = JSON.parse(JSON.stringify(obj.datas));
        let _download = Utils.isUndefined(obj.download) ? false : obj.download;

        if(obj.url === '/Bids/detail'){
            delete obj.datas.id;
        }
    
        //设置来源
        if(obj.datas) {
            obj.datas.platform = 'pc';
        }else{
            obj.datas = {};
            obj.datas.platform = 'pc';
        }
    
        let _sid = Utils.localGet('sid');
        obj.datas.sid = _sid ? _sid : '';
       
        let _datas,_heads;
        if(obj.type=='json'){
           _datas = Utils.isUndefined(obj.datas) ? null : obj.datas;
           _heads = {'Content-Type':'application/json','Accept': 'application/json'};
        }else{
          _datas = Utils.isUndefined(obj.datas) ? null : qs.stringify(obj.datas);
          _heads = {'Content-Type':'application/x-www-form-urlencoded','Accept': 'application/json'};
        }
        
        let _showLoad = Utils.isUndefined(obj.showLoad) ? false : obj.showLoad;

        let loadingInstance = null;
        if(_showLoad) {
            loadingInstance = vm.$loading({lock:true});
        }

        // if(obj.url !== 'Member/login'){
        let _token = User.getToken();

        if(_token) {
            _heads.Authorization = _token;
        }

        // console.log(Moment().unix());
        // }

        // console.log(_heads);

        // console.log(_datas);

        if(obj.url === '/Bids/detail'){
            obj.url = obj.url + '?id='+ _temp_data.id;
        }

        let _time = Moment().unix();

        let _secret = md5(Config.secret + _time.toString());

        _heads.secret = _secret;
        _heads.timestamp = _time;
        // _heads.withCredentials = true;

        let baseUrl;
        baseUrl = Config.apiUrl;
        // obj.type=='json'?baseUrl=Config.serviceUrl:baseUrl=Config.apiUrl;
    
        if(_download){
            _heads['response-Type'] = 'blob';
        }
    
        let _config = {};
    
        _config.headers = {..._heads};
        _config.method = 'post';
        _config.url = obj.url.indexOf('http') >= 0 ? obj.url : baseUrl+obj.url,
        _config.responseType = _download ? 'arraybuffer' : 'application/json';
        _config.data = _datas ? _datas : null
        
        // console.log(_config);
        
        axios(_config).then(function(res){
            if(res.data.sid){
                let _sid = Utils.localGet('sid')
                if(!_sid) {
                    Utils.localSet('sid', res.data.sid);
                }
            }
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
            if(_download){
                if (obj.success) {
                    obj.success(res.data);
                }
            }else{
                if(res.data.code === 0){
                    if(obj.success){
                        obj.success(res.data.result);
                    }
                }else{
                    if(res.data.code === -1004 || res.data.code === -1005  || res.data.code === 1005){
                        vm.$message.error({message: '登录信息已失效，请重新登录', duration: 2000});
                        Utils.sessionClear();
                        Router.replace({path:'/'});
                    }else {
                        if (obj.fail) {
                            obj.fail(res.data);
                        } else {
                            vm.$message.error({message: res.data.msg, duration: 2000});
                        }
                    }
                }
            }
        },function(error){
            vm.$message.error(error);
        }).catch(function (error) {
            console.log(error);
            vm.$message.error(error.toString());
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
        })
      // 埋点
      // if(_token) {
      //   let base_url = Config.baseUrl
      //   axios({
      //     headers:_heads,
      //     method: 'post',
      //     // url: `${base_url}/Service/common/dailyAccessRecord/recordAccess`,
      //       url: `${base_url}/Api/common/recordAccess`,
      //   })
      // }
    },
    upLoad:function(file){
        let config = {
            //添加请求头
            headers: { "Content-Type": "multipart/form-data" },
            //添加上传进度监听事件
            onUploadProgress: e => {
                var completeProgress = ((e.loaded / e.total * 100) | 0) + "%";
                this.progress = completeProgress;
            }
        };

        let param = new FormData();
        param.append("imageFile", file);
        console.log(param.get("imageFile"));

        axios.post('http://miiheapi/user/up', param, config).then(
            function (response)
            { console.log(response); })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export default http;

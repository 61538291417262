<template>
    <m-main-layout>
        <bread></bread>
        <div class="hide margin-auto" style="width: 1000px;">
            <div class="padding-50" v-if="info">
                <div class="text-center f26">{{info.title}}</div>
                <div class="padding-top-bottom-40 text-center f16 dark-2">
                    <span class="dark-3 f12">时间：{{info.create_time}}</span>
                </div>
                <div class="line-2"></div>
                <div class="cl"></div>
                <div class="f16 dark-2 margin-top-30 padding-bottom-100" v-html="info.content"></div>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "Detail",
    data(){
        return{
            info:null
        }
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.httpPost({
                url: '/Notice/listAll',
                datas: {orderBy:'create_time',sortBy:'desc',limit:1,state:1},
                success: (res) => {
                    _this.info = res.length > 0 ? res[0] : null;
                }
            });
        }
    }
}
</script>

import Vue from 'vue'
let self = new Vue();

export default {
    setAvatar(v){
        self.$utils.localSet('avatar',v);
    },
    
    getAvatar(){
        return self.$utils.localGet('avatar');
    },
    
    setNickname(v){
        self.$utils.localSet('nickname',v);
    },
    
    getNickname(){
        return self.$utils.localGet('nickname');
    },
    
    setToken(v){
        self.$utils.localSet('token',v);
    },

    getToken(){
        return self.$utils.localGet('token');
    },

    setUserName(v){
        self.$utils.localSet('username',v);
    },

    getUserName(){
        return self.$utils.localGet('username');
    },
    
    setUserType(v){
        self.$utils.localSet('usertype',v);
    },
    
    getUserType(){
        return self.$utils.localGet('usertype');
    },
    
    setGysType(v){
        self.$utils.localSet('gystype',v);
    },
    
    getGysType(){
        return self.$utils.localGet('gystype');
    },

    setMobile(v){
        self.$utils.localSet('mobile',v);
    },

    getMobile(){
        return self.$utils.localGet('mobile');
    },

    setUid(v){
        self.$utils.localSet('uid',v);
    },

    getUid(){
        return self.$utils.localGet('uid');
    },
    
    setQuery(path,v){
        let _query = self.$utils.sessionGet('query');
    
        console.log(_query);
        _query = _query ? JSON.parse(_query) : {};
    
        _query[path] = v;
        
        self.$utils.sessionSet('query',JSON.stringify(_query));
    },
    
    getQuery(path){
        let _query = self.$utils.sessionGet('query');
        console.log(_query);
        _query = _query ? JSON.parse(_query) : {};
        
        return _query[path] ? _query[path] : null;
    },
}

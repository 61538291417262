<template>
    <el-upload
        style="width: 500px;"
        class="upload-demo text-left"
        :action="$config.uploadUrl"
        :data="{key:imgKey}"
        :headers="headers"
        :on-preview="onPreview"
        :on-remove="onRemove"
        :on-success="onSuccess"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx"
        multiple
        :limit="limit"
        :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div class="dark-2 f14" v-if="tips" v-html="tips">文件格式：doc,docx,ppt,pdf,pptx,xls,xlsx；<br/>文件大小：不能超过20Mb；</div>
    </el-upload>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter';
export default {
    name: "FileUpload",
	mixins: [emitter],
    data() {
        return {
            fileList:[],
            values:'',
            imgKey:'file',
            ids:[],
            headers:{}
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        files:{
            type:Array,
            default(){
                return []
            }
        },
        value:{
            type:Array,
            default(){
                return []
            }
        },
        limit:{
            type:Number,
            default:10
        },
	    tips:{
					type:String,
		    default:''
	    }
    },
    created() {
        // this.values = this.files;
        this.fileList = this.files;
        this.ids = this.value;

        console.log(this.files);
    },
    watch:{
        files(n,o){
            console.log(n);
            // this.values = n;
            this.fileList = n;

            // if(!n){
            //     this.fileList = [];
            // }
        },
        value(n,o){
            this.ids = n;
        }
    },
    methods: {
        onRemove(file, fileList) {
            // this.values = fileList;
            this.fileList = fileList;

            let _index = this.ids.indexOf(file.id);

            this.ids.splice(_index,1);

            console.log(this.fileList);
            this.$emit('change',this.ids);
	        this.dispatch('ElFormItem', 'el.form.change', [])
	        this.dispatch('ElFormItem', 'el.form.blur', [])
        },
        beforeUpload(file,fileList){
            this.headers.Authorization = this.$user.getToken();

            let _time = this.$moment().unix();

            let _secret = this.$md5(this.$config.secret + _time.toString());

            this.headers.secret = _secret;
            this.headers.timestamp = _time;
        },
        onPreview(file) {
            let _url = file.url.indexOf('http') >= 0 ? file.url : this.$config.assetsUrl + file.url;

            window.open(_url, '_blank');
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        onSuccess(response, file, fileList) {
            if(response.code !== 0){
                this.$utils.error('上传失败');
                this.fileList = [];
            }else{
                // this.fileList = fileList;

                // this.fileList.push(response.result);

                console.log(this.fileList);

                // this.values.push(response.result);

                this.ids.push(response.result.id);

                this.$emit('change',this.ids);
	            this.dispatch('ElFormItem', 'el.form.change', [this.ids])
	            this.dispatch('ElFormItem', 'el.form.blur', [this.ids])
            }
        },
    }
}
</script>
<style>
.upload-demo .el-upload--text{text-align: left !important;}
</style>

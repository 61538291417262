<template>
    <div>
        <div class="el-upload el-upload--picture-card margin-right rel hide margin-bottom" v-if="values" style="width: 80px !important;height: 80px !important;line-height:80px !important;display: inline-block !important;">
            <img class="el-upload-list__item-thumbnail" :src="values.indexOf('http') >= 0 ? values : $config.assetsUrl + values" alt="" style="width: 100%;">
            <span class="miihe-upload-item">
              <span @click="showBigImg">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span @click="delImg">
                <i class="el-icon-delete"></i>
              </span>
            </span>
        </div>
        <el-upload
	        :disabled="disabled"
	        class="inline-block"
            :show-file-list="false"
            :action="$config.uploadUrl"
            :data="{key:imgKey}"
            :headers="headers"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP"
            ref="upload"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :limit="limit"
            :auto-upload="true">
            <div class="text-left">
	            <el-button size="small" type="primary" :disabled="disabled">点击上传</el-button>
            </div>
        </el-upload>
        <el-dialog :visible.sync="show" append-to-body>
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </div>
</template>

<script>
import Moment from 'moment'
import md5 from 'js-md5'
import Config from '../../script/config'

export default {
    name: "HeadImgUpload",
    data() {
        return {
            bigImg: '',
            show: false,
            imgKey:'headImg',
            fileList:[],
            values:'',
	        headers:{
							
	        }
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:String,
            default:''
        },
        limit:{
            type:Number,
            default:1
        },
	    disabled:{
            type:Boolean,
            default:false
        }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;

            if(!n){
                this.fileList = [];
            }
        }
    },
    methods: {
        delImg(){
            this.values = '';
            this.$refs.upload.clearFiles();
            this.$emit('change','');
        },
        showBigImg() {
            this.bigImg = this.values.indexOf('http') >= 0 ? this.values : this.$config.assetsUrl + this.values;
            this.show = true;
        },
        onSuccess(response, file, fileList) {
            if(response.code !== 0){
                this.$utils.error(response.msg);
                this.fileList = [];
            }else{
                this.fileList = fileList;

                this.values = response.result.url;

                this.$emit('change',response.result.url);
            }
        },
        onExceed(f,fl){
            console.log('323');
        },
	    beforeUpload(){
		    let _time = Moment().unix();
		
		    let _secret = this.$md5(this.$config.secret + _time.toString());
		
		    this.headers.secret = _secret;
		    this.headers.timestamp = _time;
		    this.headers.Authorization = this.$user.getToken()
				return true;
	    }
    },
    destroyed() {
        console.log('dis');
    }
}
</script>

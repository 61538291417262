<template>
	<el-form :model="formData" label-width="80px" :rules="rules" ref="form" label-position="left">
		<el-row :gutter="20">
			<el-col :span="12" v-if="!hideType">
				<el-form-item label="身份" prop="type">招标方</el-form-item>
			</el-col>
			<el-col :span="12" v-if="!hideType">
				<el-form-item label="状态" prop="type">
					<el-tag type="info" v-if="formData.state === 0">待审核</el-tag>
					<el-tag type="success" v-if="formData.state === 1">已通过</el-tag>
					<el-tag type="danger" v-if="formData.state === 2">已拒绝</el-tag>
					<el-tag v-if="formData.state === 3">待完善</el-tag>
					<span class="margin-left-5 dark-3" v-if="formData.state === 2">拒绝理由：{{formData.reason}}</span>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="公司名称" prop="company">
					<el-input v-model="formData.company" maxlength="20" show-word-limit :disabled="formData.state === 1"></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="企业logo" prop="logo">
					<head-img-upload v-model="formData.logo" :disabled="formData.state === 1"></head-img-upload>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="注册资金" prop="zczj">
					<el-input v-model="formData.zczj" maxlength="15" show-word-limit :disabled="formData.state === 1">
						<template slot="append">万元</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="行业类型" prop="type_industry_ids">
					<type-select :can-add="false" multiple v-model="formData.type_industry_ids" url="/Type/listAll" :data="{akey:'industry',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="服务类型" prop="type_fuwu_ids">
					<type-select :can-add="false" multiple v-model="formData.type_fuwu_ids" url="/Type/listAll" :data="{akey:'fuwu',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="所属区域" prop="province_codes">
					<type-select :can-add="false" multiple v-model="formData.province_codes" url="/Area/listAll" :data="{state:1,orderBy:'code',sortBy:'asc',pid:[1,'<=']}" val-key="code" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="办公区域" prop="bangong_city_code">
					<province-select style="width: 100%;" v-model="bangong_area" @change-area="onChangeArea"></province-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="联系人" prop="contact">
					<el-input v-model="formData.contact" maxlength="10" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="formData.phone" maxlength="15" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="职务" prop="job">
					<el-input v-model="formData.job" maxlength="15" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-button type="primary" style="width: 100%;" @click="toSave">保存</el-button>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: "huozhu",
	data() {
		return {
			formData:null,
			bangong_area:[],
			rules:{
				company:[{required:true,message:'企业名称不能为空'}],
				logo:[{required:true,message:'企业logo不能为空'}],
				zczj:[{required:true,message:'注册资金不能为空'}],
				type_industry_ids:[{required:true,message:'行业类型不能为空'}],
				type_fuwu_ids:[{required:true,message:'服务类型不能为空'}],
				province_codes:[{required:true,message:'所属区域不能为空'}],
				bangong_city_code:[{required:true,message:'办公区域不能为空'}],
				contact:[{required:true,message:'联系人不能为空'}],
				phone:[{required:true,message:'联系电话不能为空'},{pattern:this.$regexp.mobile,message:'请填写正确的手机号码'}],
				job:[{required:true,message:'职位不能为空'}],
			}
		}
	},
	props:{
		data:{
			type:[Object,Array],
			default:null
		},
		hideType:{
			type:Boolean,
			default:false
		}
	},
	created() {
		if(this.data) {
			this.formData = JSON.parse(JSON.stringify(this.data));
			this.bangong_area = [this.data.bangong_province_code, this.data.bangong_city_code]
			this.formData.user_type = 'hz';
		}
	},
	mounted() {
	},
	methods: {
		onChangeArea(e){
			this.formData.bangong_province_code = e.provinceCode;
			this.formData.bangong_province_name = e.provinceName;
			this.formData.bangong_city_code = e.cityCode;
			this.formData.bangong_city_name = e.cityName;
		},
		toSave(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _datas = {
						user_type:this.formData.user_type,
						logo:this.formData.logo,
						contact:this.formData.contact,
						phone:this.formData.phone,
						job:this.formData.job,
						company:this.formData.company,
						type_industry_ids:this.formData.type_industry_ids,
						type_fuwu_ids:this.formData.type_fuwu_ids,
						zczj:this.formData.zczj,
						province_codes:this.formData.province_codes,
						bangong_province_code:this.formData.bangong_province_code,
						bangong_province_name:this.formData.bangong_province_name,
						bangong_city_code:this.formData.bangong_city_code,
						bangong_city_name:this.formData.bangong_city_name,
					}
					_this.$http.httpPost({
						url:'/AuthUserCompany/edit',
						datas:_datas,
						success(res){
							_this.$message.success('保存成功');
							_this.$router.replace({path:'/user'})
						}
					})
				} else {
					return false;
				}
			});
		}
	},
	watch:{
		data:{
			handler(n,o){
				this.formData = n ? JSON.parse(JSON.stringify(n)) : null;
				this.bangong_area = [n.bangong_province_code, n.bangong_city_code]
			},
			deep:true,
			immediate:false
		}
	}
}
</script>

<template>
	<user-layout>
		<user-menu path="/user">
			<el-tabs v-model="searchForm.state" @tab-click="changeTo">
				<el-tab-pane label="待处理" name="1" class="none"></el-tab-pane>
				<el-tab-pane label="已通过" name="2" class="none"></el-tab-pane>
				<el-tab-pane label="未通过" name="3" class="none"></el-tab-pane>
			</el-tabs>
			<div class="bg-light margin-bottom-20 box-shadow-5 border-radius-10 padding-20 hand" v-for="(item,index) in list" @click="toBidsDetail(item)">
<!--				<el-avatar class="vm" :size="40" :src="$utils.formatImagePath(item.company.logo)"></el-avatar>-->
<!--				<div class="inline-block margin-left f16" v-if="searchForm.to === 'me'">{{item.target_company.company ? item.target_company.company : '无昵称'}}</div>-->
				<div class="f20">{{item.bids.title ? item.bids.title : '-'}}</div>
				<div class="f14 dark-3 margin-top">{{item.bids.profile && item.bids.profile.company ? item.bids.profile.company : '-'}}</div>
				<div class="margin-top-5 f14 dark-3">发布时间：{{item.bids.push_time}}</div>
				<div class="margin-top-5 f14 blue-2">报名截止时间：{{item.bids.end_time}}</div>
			</div>
			<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
				<el-pagination
					background
					@current-change="pageChange"
					:current-page="searchForm.page"
					:page-size="searchForm.pageSize"
					layout="prev, pager, next, jumper"
					:total="totalCount">
				</el-pagination>
			</div>
		</user-menu>
	</user-layout>
</template>

<script>
export default {
	name: "baomingLog",
	data() {
		return {
			searchForm:{
				state: '1',
				page: 1,
				pageSize: 10
			},
			totalCount:0,
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList()
	},
	methods: {
		toBidsDetail(item){
			if(item){
				this.$router.push({path:'/bids/detail/'+item.bids.id})
			}
		},
		changeTo(tab, event){
			this.searchForm.page = 1;
			this.getList();
		},
		getList(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUserToubiaoLog/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>

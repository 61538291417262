<template>
    <m-main-layout>
        <bread></bread>
        <div class="margin-auto" style="width: 600px;">
            <div class="margin-l-r-50 margin-top-50">
                <div class="cl padding-top-bottom">
                    正在通过手机号 <b>{{formData.mobile}}</b> 设置新密码
                </div>
                <div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">
                    <img src="/images/icon_password.png" class="z"/>
                    <input type="text" class="margin-left" v-model="formData.new_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入新密码"/>
                </div>
                <div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">
                    <img src="/images/icon_password.png" class="z"/>
                    <input type="text" class="margin-left" v-model="formData.re_new_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请确认新密码"/>
                </div>
                <div class="margin-top-50 padding-bottom-30 dialog-footer">
                    <el-button type="primary" class="block" @click="changePass">确 认</el-button>
                </div>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "findPass2",
    data(){
        return{
            formData:{
                mobile:'',
                spm:'',
                new_pass:'',
                re_new_pass:''
            }
        }
    },
    created() {
        this.formData.spm = this.$route.query.spm ? this.$route.query.spm : '';
        this.formData.mobile = this.$route.query.mobile ? this.$route.query.mobile : '';
    },
    mounted() {
    },
    methods:{
        changePass(){
            let _this = this;
            this.$http.httpPost({
                url: '/Common/changePass',
                datas: this.formData,
                success: (res) => {
                    if(res === 'ok'){
                        _this.$utils.sessionClear();
                        _this.$utils.success('密码修改成功，请登录');
                        _this.$router.replace('/');
                    }
                }
            });
        }
    }
}
</script>

<template>
    <span class="f-sy" :class="'tag-'+color">{{label}}</span>
</template>

<script>
export default {
    name: "SmallTag",
    data(){
        return{

        }
    },
    props:{
        label:{
            type:String,
            default:'',
        },
        color:{
            type:String,
            default:'red'
        }
    }
}
</script>

<template>
    <m-main-layout>
        <div class="padding-top-bottom-100 text-center">
            <img :src="setting.page_404_img.indexOf('http') >= 0 ? setting.page_404_img : $config.assetsUrl + setting.page_404_img" height="300" v-if="setting.page_404_img"/>
            <div class="margin-top-20">您访问的网页未找到，{{time}}秒后自动<a class="under brand" href="/">跳转</a></div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "notFound",
    data(){
        return{
            setting:{
                page_404_img:''
            },
            time:5,
            timeId:null
        }
    },
    mounted() {
        this.getSystem();
    },
    methods:{
        getSystem(){
            let _this = this;

            let _skeys = [];

            for (let k in this.setting) {
                _skeys.push(k);
            }

            this.$http.httpPost({
                url: '/SystemSetting/getSystem',
                datas: {skey: _skeys},
                success: (res) => {
                    _this.setting = res;
                    _this.timeDown();
                }
            });
        },
        timeDown(){
            this.timeId = setInterval(this.setTime,1000);
        },
        setTime(){
            if(this.time > 0){
                this.time--;
            }else{
                clearInterval(this.timeId);
                this.timeId = null;

                this.$router.replace({path:'/'});
            }
        }
    },
    destroyed() {
        if(this.timeId) {
            clearInterval(this.timeId);
            this.timeId = null;
        }
    }
}
</script>

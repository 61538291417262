<template>
    <user-layout>
        <user-menu path="/user">
            <div class="padding-40">
                <div class="margin-l-r-100">
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">发布板块</div>
                        <div class="" style="margin-left: 110px;">
                            <el-select v-model="formData.type" placeholder="请选择" style="width:225px;" class="m-select" @change="changeType">
                                <el-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                             <el-select v-model="formData.second_type" style="width:225px;margin-left:10px" placeholder="请选择" class="m-select" v-if="formData.type=='coldChain'" @change="changeSubType">
                                <el-option
                                    v-for="item in coldSubList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">标题</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.title" class="block border-w-1 border-dark-4 border-radius-10 f16" style="width:460px;height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入"/>
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">发布人</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.name" class="block border-w-1 border-dark-4 border-radius-10 f16" style="width:460px;height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入"/>
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">行业</div>
                        <div class="" style="margin-left: 110px;">
                            <el-select v-model="formData.type_industry_id" placeholder="请选择" class="m-select" style="width:calc(100% - 90px)">
                                <el-option
                                    v-for="item in industryList"
                                    :key="item.industryId"
                                    :label="item.industryCategoryName"
                                    :value="item.industryId">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;" v-if="formData.second_type=='coldNeed'">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">需求类型</div>
                        <div class="" style="margin-left: 110px;">
                            <el-select v-model="formData.type_demand_id" placeholder="请选择" class="m-select" style="width:calc(100% - 90px)">
                                <el-option
                                    v-for="item in demandList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">发布时间</div>
                        <div class="" style="margin-left: 110px;">
                            <push-time v-model="formData.push_time" class="m-select" style="width: 460px;"></push-time>
<!--                            <input v-model="formData.push_time" class="block border-w-1 border-dark-4 border-radius-10" style="width:460px;height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入"/>-->
                        </div>
                    </div>
                    <div class="margin-top-30 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">截止时间</div>
                        <div class="" style="margin-left: 110px;">
                            <push-time v-model="formData.end_time" class="m-select" style="width: 460px;"></push-time>
                        </div>
                    </div>
                    <div class="margin-top-30 cl" style="width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">公告内容</div>
                        <div class="" style="margin-left: 110px;">
                            <vue-ueditor-wrap v-model="formData.content" :config="config" editor-id="editor-demo-01"></vue-ueditor-wrap>
<!--                            <editor v-model="formData.content"></editor>-->
<!--                            <textarea v-model="formData.content" class="block border-w-1 border-dark-4 border-radius-10" style="width:100%;height: 200px;line-height:30px;padding:0 15px;" placeholder="请输入"/>-->
                        </div>
                    </div>
                    <div class="margin-top-30 cl" style="width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">附件(可选)</div>
                        <div class="" style="margin-left: 110px;">
                            <file-upload v-model="formData.files_ids" :files="formData['files']"></file-upload>
                        </div>
                    </div>
                    <div class="margin-top-50 padding-bottom-30 text-center">
                        <el-button type="primary" @click="submit" class="padding-left-right-40">确 定</el-button>
                    </div>
                </div>
            </div>
        </user-menu>
    </user-layout>
</template>

<script>
export default {
    name: "push",
    data(){
        return{
            id:'',
            formData:{
                title:'',
                type:'',
                second_type:"",
                type_industry_id:'',
                type_demand_id:"",
                push_time:'',
                end_time:'',
                content:'',
                name:'',
                files_ids:[]
            },
            typeList: [{
                value: 'notice',
                label: '招标公告'
            }, {
                value: 'coldChain',
                label: '冷链专区'
            }, {
                value: 'odd',
                label: '散单平台'
            }, {
                value: 'storage',
                label: '仓储供给'
            }, {
                value: 'device',
                label: '设备采购'
            }],
             coldSubList: [{
                value: 'coldBid',
                label: '冷链招标'
            }, {
                value: 'coldNeed',
                label: '冷链需求'
            }],
            industryList:[],
            demandList:[],
            config: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                autoFloatEnabled:false,
                // 初始容器高度
                initialFrameHeight: 240,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: 'http://www.clb.org.cn/UEditor/php/controller.php',
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/ueditor/',
                toolbars:[[
                    'fullscreen', '|', 'undo', 'redo', '|',
                    'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
                    'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
                    'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
                    'directionalityltr', 'directionalityrtl', 'indent', '|',
                    'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
                    'simpleupload','attachment', 'template', '|',
                    'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
                    'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
                    'print', 'preview', 'searchreplace', 'drafts', 'help'
                ]]
            }
        }
    },
    created() {
        this.id = this.$route.query.id ? this.$route.query.id : '';
        this.formData.type = this.$route.query.type ? this.$route.query.type : '';
    },
    mounted() {
        this.getDemandList();

        if(this.id){
            this.getDetail();
            this.getIndustryList();
        }else{
            this.formData.push_time = this.$moment().format('YYYY-MM-DD');
        }
    },
    methods:{
        // 切换发布板块
        changeType(){
          this.formData.second_type="";
          this.formData.type_demand_id="";
          this.formData.type_industry_id="";
          if(this.formData.type === "coldChain"){
            this.formData.second_type="coldBid";
          }

          // 请求行业数据
          this.getIndustryList()
        },
        // 切换冷链类型
        changeSubType(){
          this.formData.type_demand_id=""
        },
        getIndustryList(){
            let _this = this;
            // this.$http.httpPost({
            //     url: '/admin/industryCategory/getIndustryCategoryList',
            //     type:"json",
            //     datas:{type:this.formData.type},
            //     success: (res) => {
            //         _this.industryList = res;
            //     }
            // });
	
	        this.$http.httpPost({
		        url: '/industryCategory/getIndustryCategoryList',
		        datas:{type:this.formData.type},
		        success: (res) => {
			        _this.industryList = res;
		        }
	        });
        },
        // 查询需求类型
         getDemandList(){
            let _this = this;
            this.$http.httpPost({
                url: '/Type/listAll',
                datas:{akey:'demand',state:1,orderBy:'sort',sortBy:'asc'},
                success: (res) => {
                    _this.demandList = res;
                }
            });
        },
        submit(){
            let _this = this;
            let _url = '';
            if(this.id){
                _url = '/AuthBids/edit';
            }else{
                _url = '/AuthBids/add';
            }

            this.$http.httpPost({
                url: _url,
                datas:this.formData,
                success: (res) => {
                    if(res === 'ok'){

                        if(_this.id){
                            _this.$message.success('修改成功，等待审核');
                        }else {
                            _this.$message.success('发布成功，等待审核');
                        }

                        _this.$router.replace({path:'/user/bidlist',query:{type:_this.formData.type}});
                    }
                }
            });
        },
        getDetail(){
            let _this = this;

            this.$http.httpPost({
                url: '/AuthBids/detail',
                datas:{id:this.id},
                success: (res) => {
                    _this.formData = res;

                }
            });
        }
    }
}
</script>

<template>
	<user-layout>
		<user-menu path="/user/rss">
			<div class="bg-light border-radius-10 padding-20">
				<div class="">行业类别</div>
				<div class="margin-top-20">
					<el-checkbox-group v-model="formData.hangye_ids">
						<el-checkbox :label="item.id" v-for="(item,index) in hangyeList" class="margin-bottom" style="width: 100px;">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div class="bg-light border-radius-10 padding-20 margin-top-20">
				<div class="">服务类别</div>
				<div class="margin-top-20">
					<el-checkbox-group v-model="formData.fuwu_ids">
						<el-checkbox :label="item.id" v-for="(item,index) in fuwuList" class="margin-bottom" style="width: 100px;">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div class="bg-light border-radius-10 padding-20 margin-top-20">
				<div class="">服务区域</div>
				<div class="margin-top-20">
					<el-checkbox-group v-model="formData.province_codes">
						<el-checkbox :label="item.code" v-for="(item,index) in areaList" class="margin-bottom" style="width: 100px;">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div class="text-center margin-top-20">
				<el-button type="primary" style="width: 150px;" @click="toSave">保 存</el-button>
			</div>
		</user-menu>
	</user-layout>
</template>

<script>
export default {
	name: "rss",
	data() {
		return {
			hangyeList:[],
			fuwuList:[],
			areaList:[],
			
			formData: {
				hangye_ids: [],
				fuwu_ids: [],
				province_codes: [],
			}
		}
	},
	created() {
	},
	mounted() {
		this.getHangyeType();
		this.getFuwuType();
		this.getAreaType();
		this.getInterest();
	},
	methods: {
		getHangyeType(){
			let _this = this;
			this.$http.httpPost({
				url:'/Type/listAll',
				datas:{akey:'industry',orderBy:'sort',sortBy:'asc',state:1,delete_time:0},
				success(res){
					_this.hangyeList = res;
				}
			})
		},
		getFuwuType(){
			let _this = this;
			this.$http.httpPost({
				url:'/Type/listAll',
				datas:{akey:'fuwu',orderBy:'sort',sortBy:'asc',state:1,delete_time:0},
				success(res){
					_this.fuwuList = res;
				}
			})
		},
		getAreaType(){
			let _this = this;
			this.$http.httpPost({
				url:'/Area/listAll',
				datas:{pid:1},
				success(res){
					_this.areaList = res;
				}
			})
		},
		getInterest(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUser/getInterest',
				datas:null,
				success(res){
					_this.formData.fuwu_ids = res.fuwu_ids;
					_this.formData.hangye_ids = res.hangye_ids;
					_this.formData.province_codes = res.province_codes;
				}
			})
		},
		toSave(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUser/editInterest',
				datas:this.formData,
				success(res){
					_this.$message.success('保存成功');
					_this.getInterest();
				}
			})
		}
	}
}
</script>

<template>
	<m-main-layout>
		<bread :list="bread"></bread>
		<div class="rel hide margin-top" style="width: 100%;height: 134px;">
			<img class="abs" src="/images/profile_bg.jpg" style="width: 100%;height: 134px;z-index: 1;"/>
			<div class="rel f-bth light f30 padding-left-40" style="line-height: 134px;z-index:2;">
				完善资料 -
				<template v-if="user_type === 'hz'">招标方</template>
				<template v-if="user_type === 'gys' && gys_type === 'wuliu'">物流供应商</template>
				<template v-if="user_type === 'gys' && gys_type === 'shebei'">设备供应商</template>
			</div>
		</div>
		<div class="wrap margin-top-20" style="width: 800px !important;">
			<div class="box-shadow-5 bg-light padding-30 border-w-1 border-dark-5 border-radius-5">
				<el-form :model="formData" label-width="80px" ref="form33" label-position="left" v-if="hideType">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="身份" prop="type">
								<el-radio-group v-model="user_type" @change="changeUserType">
									<el-radio label="hz">招标方</el-radio>
									<el-radio label="gys">投标方</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="24" v-if="user_type === 'gys'">
							<el-form-item label="状态" prop="type">
								<el-radio-group v-model="gys_type" v-if="user_type === 'gys'" @change="changeGysType">
									<el-radio label="wuliu">物流供应商</el-radio>
									<el-radio label="shebei">设备供应商</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<profile-huozhu v-if="user_type === 'hz'" :data="formData" :hide-type="hideType"></profile-huozhu>
				<profile-wuliu v-if="user_type === 'gys' && gys_type === 'wuliu'" :data="formData" :hide-type="hideType"></profile-wuliu>
				<profile-shebei v-if="user_type === 'gys' && gys_type === 'shebei'" :data="formData" :hide-type="hideType"></profile-shebei>
			</div>
		</div>
	</m-main-layout>
</template>

<script>
import ProfileHuozhu from './template/huozhu'
import ProfileWuliu from './template/wuliu'
import ProfileShebei from './template/shebei'
export default {
	name: "companyProfile",
	components: {ProfileHuozhu,ProfileWuliu,ProfileShebei},
	data() {
		return {
			bread:[{name:'完善信息'}],
			formData:null,
			user_type:'',
			gys_type:'',
			hideType:false
		}
	},
	created() {
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		changeUserType(e){
			console.log(e);
			this.formData.user_type = e;
		},
		changeGysType(e){
			this.formData.gys_type = e;
		},
		getDetail(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUserCompany/detail',
				datas:null,
				success(res){
					console.log(res)
					_this.user_type = res.user_type;
					_this.gys_type = res.gys_type;
					_this.formData = JSON.parse(JSON.stringify(res));
					_this.bangong_area = [res.bangong_province_code,res.bangong_city_code];
					
					_this.hideType = !!!res.user_type;
				}
			})
		}
	}
}
</script>

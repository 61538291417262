<template>
	<div class="margin-top-150 box-shadow cl" style="min-height: 500px;background: linear-gradient(90deg, #f4f4f4 180px, #f8f9fb 180px);">
		<div class="z" style="width: 180px;">
			<template v-if="companyInfo && companyInfo.user_type === 'hz'">
				<div class="bg-blue text-center light border-radius hand" style="margin:50px auto 10px auto;width: 80px;height:80px;line-height: 80px;" @click="$router.push({path:'/push2'})">
					<span class="iconfont icon-fly f30"></span>
				</div>
				<div class="blue f22 text-center hand" @click="$router.push({path:'/user/push'})">发布</div>
			</template>
			<div class="cl padding bg-gold-line margin-top-40">
				<div class="inline-block vm" style="width: 110px;">
					<div style="color: #614444;" class="f-bth"><i class="iconfont icon-gold-supplie-fill"></i>会员有效期</div>
					<div class="f16 margin-top-5" style="color: #614444;">
						{{ vip_state === 0 ? '还不是会员' : $moment.unix(vip_end_time).format('YYYY-MM-DD') }}
					</div>
				</div>
				<div class="inline-block vm border-radius light f16 f-bth hand" style="background: #614444;padding:3px 10px;" @click="showVipDialog = true">
					{{ vip_state === 0 ? '开通' : '续费' }}
				</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand user-nav-off" style="padding-left:30px;padding-right:17px;">
				<div class="z dark-3">剩余解锁：<b class="blue">{{ bids_count }}</b></div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand user-nav-off" style="padding-left:30px;padding-right:17px;">
				<div class="z dark-3">剩余积分：<b class="blue">{{ gold_count }}</b></div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user/rss' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user/rss'})">
				<div class="z text-center margin-right" style="width: 30px;">
					<span class="iconfont icon-all-fill f20 v-m"></span>
				</div>
				<div class="z">订阅设置</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user'})">
				<div class="z text-center margin-right" style="width: 30px;">
					<span class="iconfont icon-home f20 v-m"></span>
				</div>
				<div class="z">首页</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand user-nav-off" style="padding-left:30px;padding-right:17px;">
				<div class="z text-center margin-right" style="width: 30px;">
					<span class="iconfont icon-user f20 v-m"></span>
				</div>
				<div class="z">用户中心</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user/profile' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user/profile'})">
				<div class="lh50 h50 z text-center margin-right" style="width: 30px;"></div>
				<div class="z menu-gray">修改资料</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user/company-profile' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user/company-profile'})">
				<div class="lh50 h50 z text-center margin-right" style="width: 30px;"></div>
				<div class="z menu-gray">完善信息</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user/changepass' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user/changepass'})">
				<div class="lh50 h50 z text-center margin-right" style="width: 30px;"></div>
				<div class="z menu-gray">修改密码</div>
			</div>
			<div class="cl lh50 h50 bg-light margin-top-3 hand" style="padding-left:30px;padding-right:17px;" :class="path === '/user/bidlist' ? 'user-nav-on' : 'user-nav-off'" @click="$router.push({path:'/user/bidlist'})">
				<div class="z text-center margin-right" style="width: 30px;">
					<span class="iconfont icon-txt f18 v-m"></span>
				</div>
				<div class="z">内容管理</div>
			</div>
			<div class="bg-light margin-top-3 user-nav-off" style="padding-left:30px;padding-top:90px;padding-bottom:90px;padding-right:17px;">
				<div class="cl hand" @click="logout">
					<div class="z text-center margin-right" style="width: 30px;">
						<span class="iconfont icon-exit f20 v-m"></span>
					</div>
					<div class="z menu-gray">退出登录</div>
				</div>
			</div>
		</div>
		<div class="y" style="width: 1020px;">
			<div class="padding-40">
				<div class="cl margin-bottom-40" style="height:30px;line-height: 30px;">
					<div class="z dark-3">
						<span class="iconfont icon-laba blue f20 v-m"></span>
						{{ notice ? notice.title : '' }}
					</div>
					<div class="y border-w-1 hand border-dark-7 bg-light f16 dark-3 padding-left-right border-radius-5" style="height: 30px;line-height: 28px;" @click="$router.push({path:'/'})">
						<i class="iconfont icon-bili margin-right f18"></i>主站
					</div>
				</div>
				<slot></slot>
			</div>
		</div>
		<vip-dialog :show.sync="showVipDialog"></vip-dialog>
	</div>
</template>

<script>
export default {
	name: "UserMenu",
	data() {
		return {
			showVipDialog:false,
			notice: null,
			companyInfo: null,
			bids_count: 0,
			gold_count: 0,
			vip_state: 0,
			vip_end_time: 0,
		}
	},
	props: {
		path: {
			type: String,
			default: ''
		}
	},
	mounted() {
		this.getDetail();
		this.getUserType();
		this.getUserCredit();
		this.getProfile();
	},
	methods: {
		getUserCredit() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUserCredit/detail',
				datas: null,
				success(res) {
					_this.bids_count = res.bids;
					_this.gold_count = res.gold;
				}
			})
		},
		getUserType() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUserCompany/clearDetail',
				datas: null,
				success(res) {
					_this.companyInfo = res;
				}
			})
		},
		getProfile() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUser/getProfile',
				datas: null,
				success: (res) => {
					_this.vip_state = res.vip_state;
					_this.vip_end_time = res.vip_end_time;
				}
			});
		},
		getDetail() {
			let _this = this;
			this.$http.httpPost({
				url: '/Notice/listAll',
				datas: {orderBy: 'create_time', sortBy: 'desc', limit: 1, state: 1},
				success: (res) => {
					_this.notice = res.length > 0 ? res[0] : null;
				}
			});
		},
		logout() {
			this.$confirm('是否退出登录？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$utils.localClear();
				this.$utils.success('已退出登录');
				
				this.token = '';
				
				if (this.$route.path === '/changepass') {
					this.$router.replace({path: '/'});
				} else {
					window.location.reload();
				}
			}).catch(() => {
			});
		},
	}
}
</script>

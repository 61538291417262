<template>
	<div class="margin-bottom-30" v-if="info">
		<div class="cl">
			<a class="vm dark-2 hand f22" style="width: 393px;" target="_blank" rel="opener" :href="'/company/detail/'+info.uid">
				<i class="iconfont icon-vip3 vm f34" style="color: orange" v-if="info.user && info.user.vip_state === 1"></i><i class="iconfont icon-vip3 vm f34" style="color: #bbb" v-if="info.user && info.user.vip_state === 0"></i>{{info.company}}
			</a>
			<div class="inline-block y vm text-center border-radius" style="background-color:#ffd500;height: 30px;line-height: 30px;width:30px;" v-if="info.is_unlock !== 1">
				<i class="el-icon-lock"></i>
			</div>
		</div>
		<el-row class="margin-top-20">
			<el-col :span="8" class="margin-bottom">
				<span class="z iconfont icon-gold-supplie-fill blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">注册资金</span>
				<span class="z dark-1 f14 margin-left text-line-1" style="width: 180px;">{{info.zczj}}万元</span>
			</el-col>
			<el-col :span="8" class="cl margin-bottom">
				<span class="z iconfont icon-product blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">自有运力</span>
				<span class="z dark-1 f14 margin-left">{{info.zyyl}}辆</span>
			</el-col>
			<el-col :span="8" class="cl margin-bottom">
				<span class="z iconfont icon-all-fill blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">行业类型</span>
				<span class="z dark-1 f14 margin-left text-line-1" style="width: 180px;">{{info.industry_str ? info.industry_str : '-'}}</span>
			</el-col>
			<el-col :span="8" class="margin-bottom">
				<span class="iconfont icon-template-fill blue-2"></span>
				<span class="dark-3 f14 margin-left-5">冷库面积</span>
				<span class="dark-1 f14 margin-left">{{info.lkmj}}㎡</span>
			</el-col>
			<el-col :span="8" class="margin-bottom">
				<span class="iconfont icon-integral-fill blue-2"></span>
				<span class="dark-3 f14 margin-left-5">普通仓库面积</span>
				<span class="dark-1 f14 margin-left">{{info.cwkmj}}㎡</span>
			</el-col>
			<el-col :span="8" class="margin-bottom cl">
				<span class="z iconfont icon-image-text-fill blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">服务类型</span>
				<span class="z dark-1 f14 margin-left text-line-1" style="width: 180px;">{{info.fuwu_str ? info.fuwu_str : '-'}}</span>
			</el-col>
			<el-col :span="24" class="margin-bottom cl">
				<span class="z iconfont icon-map blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">服务区域</span>
				<span class="z dark-1 f14 margin-left text-line-1">{{info.province_str}}</span>
			</el-col>
			<el-col :span="24" class="cl">
				<span class="z iconfont icon-product blue-2"></span>
				<span class="z dark-3 f14 margin-left-5">合作客户</span>
				<span class="z dark-1 f14 margin-left text-line-1">{{info.hzkh}}</span>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "wuliu",
	data() {
		return {}
	},
	props:{
		info:{
			type:[Object,Array],
			default:null
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>

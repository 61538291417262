<template>
	<div>
<!--		{{values}}-{{fileList}}-->
		<el-upload
			:disabled="disabled"
			style="width: 100%;"
			class="one-file-upload text-left"
			:action="$config.uploadUrl"
			:data="{key:imgKey}"
			:headers="headers"
			:show-file-list="true"
			:on-preview="onPreview"
			:on-remove="onRemove"
			:on-success="onSuccess"
			:before-remove="beforeRemove"
			:before-upload="beforeUpload"
			:accept="accept"
			:multiple="multiple"
			:limit="limit"
			:file-list="fileList">
			<el-button type="primary" style="border-radius: 0 !important;" :disabled="disabled">上传</el-button>
		</el-upload>
		<el-dialog :visible.sync="show" append-to-body>
			<img width="100%" :src="bigImg" alt="">
		</el-dialog>
	</div>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter';
export default {
	name: "OneFileUpload",
	mixins: [emitter],
	data() {
		return {
			fileList: [],
			values: '',
			imgKey: 'file',
			ids: [],
			headers: {},
			name:'',
			bigImg: '',
			show: false,
		};
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		files: {
			type: [Array,String],
			default() {
				return []
			}
		},
		bigType:{
			type:String,
			default:'file'
		},
		accept:{
			type:String,
			default:'.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx'
		},
		value: {
			type: [Array,String],
			default() {
				return null
			}
		},
		limit:{
			type:Number,
			default:1
		},
		multiple:{
			type:Boolean,
			default:false
		},
		disabled:{
			type:Boolean,
			default:false
		},
		size:{
			type:Number,
			default:300 * 1024 * 1024
		}
	},
	created() {
		// this.values = this.files;
		let _files = [];
		let _fileList = [];
		// console.log(this.files);
		if(this.files){
			let _callType = Object.prototype.toString.call(this.files);

			if(_callType === '[object Array]'){
				_files = this.files;
			}else{
				_files = [this.files];
			}
		}

		for (let i=0;i < _files.length;i++){
			let _tmpFile = {
				name:_files[i].replace(/\/.+\//,''),
				uid:this.$utils.randomWords(),
				url:_files[i]
			};
			_fileList.push(_tmpFile);
		}
		this.fileList = _fileList;

		if(this.limit > 1){
			this.values = this.value ? this.value : [];
		}else {
			this.values = this.value;
		}
	},
	watch: {
		files:{
			handler(n,o){
				// console.log(n);
				// this.fileList = n;
			},
			deep:true,
			immediate:true
		},
		// files(n, o) {
		// 	console.log(n);
		// 	// console.log(Object.prototype.toString.call(n));
		// 	// this.values = n;
		// 	// this.fileList = n;
		//
		// 	// if(!n){
		// 	//     this.fileList = [];
		// 	// }
		// },
		value(n, o) {
			this.values = n;
		}
	},
	methods: {
		isPic(url){
			let reg = /\.(png|jpg|gif|jpeg|webp|PNG|JPG|GIF|JPEG)$/;
			return reg.test(url);
		},
		onRemove(file, fileList) {
			// console.log(file, fileList);
			// this.values = fileList;
			this.fileList = fileList;

			if(this.limit > 1){
				let _values = [];
				for (let i=0;i < fileList.length;i++){
					_values.push(fileList[i].url);
				}
				this.values = _values;
				// this.name = '';
			}else {
				this.values = '';
				this.name = '';
			}
			this.$emit('change', this.values);
			this.dispatch('ElFormItem', 'el.form.change', [])
			this.dispatch('ElFormItem', 'el.form.blur', [])
		},
		beforeUpload(file, fileList) {
			this.headers.Authorization = this.$user.getToken();

			let _time = this.$moment().unix();

			let _secret = this.$md5(this.$config.secret + _time.toString());

			this.headers.secret = _secret;
			this.headers.timestamp = _time;

			if(file.size > this.size){
				this.$message.warning('文件大小不能超过 ' + parseInt(this.size / 1024 / 1024) + 'MB！')
				return false
			}
		},
		onPreview(file) {
			console.log(file);
			let _url= '';
			if(file.response){
				_url = file.response.result.url.indexOf('http') >= 0 ? file.response.result.url : this.$config.assetsUrl + file.response.result.url;
			}else {
				_url = file.url.indexOf('http') >= 0 ? file.url : this.$config.assetsUrl + file.url;
			}

			if(_url) {
				if(this.isPic(_url)){
					this.bigImg = _url;
					this.show = true;
				}else{
					window.open(_url, '_blank');
				}
				// if(this.bigType === 'file') {
				// 	window.open(_url, '_blank');
				// }else if(this.bigType === 'image'){
				// 	this.bigImg = _url;
				// 	this.show = true;
				// }
			}
		},
		beforeRemove(file, fileList) {
			// return this.$confirm(`确定移除 ${file.name}？`);
		},
		onSuccess(response, file, fileList) {
			console.log(this.limit);
			if (response.code !== 0) {
				this.$utils.error('上传失败');
				this.fileList = [];
			} else {
				if(this.limit > 1){
					this.fileList = fileList;
					this.values.push(response.result.url);
				}else {
					this.name = file.name;
					this.fileList = fileList;
					this.values = response.result.url;
				}
				this.$emit('change', this.values);
				this.dispatch('ElFormItem', 'el.form.change', [this.values])
				this.dispatch('ElFormItem', 'el.form.blur', [this.values])
			}
		},
	}
}
</script>
<style>
.upload-demo .el-upload--text {
	text-align: left !important;
}
</style>

<template>
    <el-select :multiple="multiple" v-model="values" :placeholder="placeholder" :clearable="clearable" @change="change">
        <div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;" v-if="canAdd && data && data.akey">
            <el-input placeholder="请输入内容" v-model="name"></el-input>
	          <el-button @click.native="ajaxAdd" style="margin-left:5px;" type="primary">添加</el-button>
<!--            <add-button @click.native="ajaxAdd" label="添加" style="margin-left:5px;"></add-button>-->
        </div>
        <el-option
            v-for="item in list"
            :key="item[valKey]"
            :label="item[valName]"
            :value="item[valKey]"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "TypeSelect",
    data(){
        return{
            list:[],
            values:'',
            name:'',
	        default:'',
	        loaded:false
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number,Array],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
	    addUrl:{
		    type:String,
		    default:'/Type/add'
	    },
        url:{
            type:String,
            default:'/Type/listAll'
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        canAdd:{
            type:Boolean,
            default:true
        },
        clearable:{
            type:Boolean,
            default:false
        },
	    multiple:{
		    type:Boolean,
		    default:false
	    },
	    placeholder:{
					type:String,
		    default:'请选择'
	    },
	    valKey:{
		    type:String,
		    default:'id'
	    },
	    valName:{
		    type:String,
		    default:'name'
	    },
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        if(this.options) {
            this.list = this.options;
        }else{
            this.getList();
        }
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: this.url,
                datas: {state:1,orderBy:'sort',sortBy:'asc',...this.data},
                success(res) {
                    _this.list = res;
										
										for (let i=0;i< res.length;i++){
											if(res[i].is_default && res[i].is_default === 1){
												_this.default = res[i][_this.valKey];
											}
										}
										
										if(!_this.value && !_this.loaded){
											_this.$emit("change", _this.default);
											_this.loaded = true;
										}
                }
            });
        },
        change(e){
            this.$emit("change", e);
            this.$emit("changeCallback",e)
        },
        ajaxAdd(){
            let _this = this;

            this.$http.httpPost({
                url: this.addUrl,
                datas: {name:this.name,akey:this.data.akey},
                success(res) {
                    console.log(res);
                    _this.getList();
                    _this.name = '';
                }
            });
        }
    }
}
</script>

<template>
	<m-main-layout>
		<bread :list="[{name:'招标审核'}]"></bread>
		<div class="rel hide margin-top" style="width: 100%;height: 134px;">
			<img class="abs" src="/images/profile_bg.jpg" style="width: 100%;height: 134px;z-index: 1;"/>
			<div class="rel f-bth light f30 padding-left-40" style="line-height: 134px;z-index:2;">
				招标审核
			</div>
		</div>
		<div class="margin-top-20" v-if="info">
			<div class="bg-light border-radius-5 hide box-shadow-5 padding-15">
				<div class="f20 margin-bottom-15">{{bids.title}}</div>
				<div class="blue">
					<div class="inline-block">
						<i class="iconfont icon-product f20 vm"></i><div class="inline-block margin-left vm">{{bids.area_str}}</div>
					</div>
					<div class="inline-block margin-left-right-40">
						<i class="iconfont icon-integral-fill f20 vm"></i><div class="inline-block margin-left vm">{{bids.fuwu_txt}}</div>
					</div>
					<div class="inline-block">
						<i class="iconfont icon-all-fill f20 vm"></i><div class="inline-block margin-left vm">{{bids.industry.name}}</div>
					</div>
				</div>
			</div>
			<div class="margin-top-20 margin-bottom-20 bg-light border-radius-5 hide box-shadow-5 padding-15">
				<div class="f20 margin-bottom-15">报名资质审核</div>
				<el-row :gutter="20">
					<el-col :span="12" v-for="(item,index) in toubiao.tbzz" class="margin-bottom-20">
						<div style="background-color: #EFF2F8;" class="blue padding border-radius-5 hide">
							<i class="iconfont icon-zhengshuzizhi blue f20"></i><span class="inline-block margin-left f18">{{item.name}}</span>
							<template v-if="typeof item.file === 'string'">
								<div style="margin-top:5px;" v-if="item.file"><a :href="item.file && item.file.indexOf('http')>= 0 ? item.file : $config.assetsUrl + item.file" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;">{{item.name}}</a></div>
							</template>
							<template v-else>
								<template v-for="(st,si) in item.file">
									<div style="margin:0 5px 5px 5px;display: inline-block;" v-if="st"><a :href="st.indexOf('http')>= 0 ? st : $config.assetsUrl + st" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;"><!--{{st.replace(/\/.+\//,'')}}-->{{item.name+'_'+(si+1)}}</a></div>
								</template>
							</template>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="h50 hide">
				<img :src="$utils.formatImagePath(info.logo)" style="height: 50px;"/>
				<span class="vm f30 blue margin-left">{{info.company}}</span>
			</div>
			<div class="el-descriptions margin-top">
				<div class="el-descriptions__body">
					<table class="el-descriptions__table is-bordered">
						<tbody>
						<tr class="el-descriptions-row">
							<th colspan="4" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label" style="text-align: center;font-size: 38px !important;color:#15489D !important;">企业信息</th>
						</tr>
						</tbody>
						<tbody>
						<tr class="el-descriptions-row">
							<table-th>注册资金</table-th>
							<table-td>{{info.zczj ? info.zczj : '-'}}万</table-td>
							<table-th>自有运力</table-th>
							<table-td>{{info.zyyl ? info.zyyl : '-'}}辆</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>冷库面积</table-th>
							<table-td>{{info.lkmj ? info.lkmj : '-'}}m³</table-td>
							<table-th>普通仓库面积</table-th>
							<table-td>{{info.cwkmj ? info.cwkmj : '-'}}m³</table-td>
						</tr>
						<!--					<tr class="el-descriptions-row">-->
						<!--						<table-th>办公地址</table-th>-->
						<!--						<table-td :span="3">32</table-td>-->
						<!--					</tr>-->
						<tr class="el-descriptions-row">
							<table-th>客户类型</table-th>
							<table-td :span="3">{{info.industry_str ? info.industry_str : '-'}}</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>服务类型</table-th>
							<table-td :span="3">{{info.fuwu_str ? info.fuwu_str : '-'}}</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>服务区域</table-th>
							<table-td :span="3">{{info.province_str ? info.province_str : '-'}}</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>联系人</table-th>
							<table-td>{{info.contact}}</table-td>
							<table-th>职务</table-th>
							<table-td>{{info.job}}</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>电话</table-th>
							<table-td>{{info.phone}}</table-td>
							<table-th>官方网址</table-th>
							<table-td><a :href="info.homesite" target="_blank" rel="opener" v-if="info.homesite">{{info.homesite}}</a></table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>合作客户</table-th>
							<table-td :span="3">{{info.hzkh}}</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>企业介绍</table-th>
							<table-td :span="3"><a :href="$utils.formatImagePath(info.ppt)" target="_blank" v-if="info.ppt">企业介绍.ppt</a></table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>重要资质认证</table-th>
							<table-td :span="3">
								<div class="blue-2 padding-right-15 margin-top-5" v-for="(item,index) in info.zizhi_files" v-if="(item.file && item.file.length > 0) && item.name">
									{{item.name}}
									<template v-if="typeof item.file === 'string'">
										<div style="margin-top:5px;" v-if="item.file"><a :href="item.file.indexOf('http')>= 0 ? item.file : $config.assetsUrl + item.file" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;">{{item.name}}</a></div>
									</template>
									<template v-else>
										<template v-for="(st,si) in item.file">
											<div v-if="st" style="margin-left:10px;display: inline-block;"><a :href="st.indexOf('http')>= 0 ? st : $config.assetsUrl + st" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;"><!--{{st.replace(/\/.+\//,'')}}-->{{item.name+'_'+(si+1)}}</a></div>
										</template>
									</template>
								</div>
							</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>企业荣誉</table-th>
							<table-td :span="3">
								<div class="blue-2 padding-right-15 margin-top-5" v-for="(item,index) in info.rongyu_files" v-if="(item.file && item.file.length > 0) && item.name">
									{{item.name}}
									<template v-if="typeof item.file === 'string'">
										<div style="margin-top:5px;" v-if="item.file"><a :href="item.file.indexOf('http')>= 0 ? item.file : $config.assetsUrl + item.file" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;">{{item.name}}</a></div>
									</template>
									<template v-else>
										<template v-for="(st,si) in item.file">
											<div style="margin-left:10px;display: inline-block;" v-if="st"><a :href="st.indexOf('http')>= 0 ? st : $config.assetsUrl + st" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;"><!--{{st.replace(/\/.+\//,'')}}-->{{item.name+'_'+(si+1)}}</a></div>
										</template>
									</template>
								</div>
							</table-td>
						</tr>
						<tr class="el-descriptions-row">
							<table-th>重要评估认证</table-th>
							<table-td :span="3">
								<div class="blue-2 padding-right-15 margin-top-5" v-for="(item,index) in info.pinggu_files" v-if="(item.file && item.file.length > 0) && item.name">
									{{item.name}}
									<template v-if="typeof item.file === 'string'">
										<div style="margin-top:5px;" v-if="item.file"><a :href="item.file.indexOf('http')>= 0 ? item.file : $config.assetsUrl + item.file" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;">{{item.name}}</a></div>
									</template>
									<template v-else>
										<template v-for="(st,si) in item.file">
											<div v-if="st" style="margin-left:10px;display: inline-block;"><a :href="st.indexOf('http')>= 0 ? st : $config.assetsUrl + st" target="_blank" style="border-radius: 9999px;background-color: #d1e7ff;padding:3px 10px;display: inline-block;"><!--{{st.replace(/\/.+\//,'')}}-->{{item.name+'_'+(si+1)}}</a></div>
										</template>
									</template>
								</div>
							</table-td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<el-row :gutter="40" class="margin-top-20">
				<el-col :span="12">
					<el-button :disabled="toubiao.state !== 1" class="block" type="primary" @click="toChangeState(2)">通过</el-button>
				</el-col>
				<el-col :span="12">
					<el-button :disabled="toubiao.state !== 1" class="block" type="danger" @click="toChangeState(3)">拒绝</el-button>
				</el-col>
			</el-row>
		</div>
		<el-dialog :visible.sync="showBigImg" width="30%">
			<img :src="$utils.formatImagePath(bigImgUrl)" style="width: 100%;"/>
			<span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="showBigImg = false">关闭</el-button>
		  </span>
		</el-dialog>
	</m-main-layout>
</template>

<script>
export default {
	name: "ToubiaoDetail",
	data() {
		return {
			showBigImg:false,
			bigImgUrl:'',
			formData:{
				state:'',
				reason:'',
			},
			info:null,
			toubiao:null,
			bids:null,
			show:false,
			id:'',
			bidsid:''
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
		this.bidsid = this.$route.query.bidsid ? this.$route.query.bidsid : '';
	},
	mounted() {
		if(this.id && this.bidsid) {
			this.getCompanyDetail();
			this.getToubiaoDetail();
		}
	},
	methods: {
		toChangeState(state){
			let _this = this;
			if(state === 2){
				this.$confirm('是否通过此供应商的投标？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.$http.httpPost({
						url:'/AuthUserToubiaoLog/changeState',
						datas:{id:this.toubiao.id,state:2},
						success(res){
							_this.$message.success('操作成功');
							_this.getToubiaoDetail();
						}
					})
				}).catch(() => {});
			}else if(state === 3){
				this.$prompt('请输入拒绝原因', '提示', {
					confirmButtonText: '确认拒绝',
					cancelButtonText: '我在想想',
					inputPattern: /^\S{1,20}$/,
					inputErrorMessage: '拒绝原因必填且最大长度20位'
				}).then(({ value }) => {
					_this.$http.httpPost({
						url:'/AuthUserToubiaoLog/changeState',
						datas:{id:this.toubiao.id,state:3,reason:value},
						success(res){
							_this.$message.success('操作成功');
							_this.getToubiaoDetail();
						}
					})
				}).catch(() => {});
			}
		},
		toShowImg(item){
			if(item.file){
				this.bigImgUrl = item.file;
				this.showBigImg = true;
			}
		},
		getCompanyDetail(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserCompany/detail',
				datas:{id:this.id},
				success(res){
					_this.info = res;
				}
			})
		},
		getToubiaoDetail(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserToubiaoLog/detail',
				datas:{uid:this.id,bidsid:this.bidsid},
				success(res){
					_this.toubiao = res.toubiao;
					_this.bids = res.bids
				}
			})
		},
	}
}
</script>
<style scoped>
.el-descriptions-item__label.is-bordered-label{background: #EFF2F8 !important;width: 200px !important;color:#333 !important;font-size: 16px !important;text-align: center !important;}
.el-descriptions .is-bordered .el-descriptions-item__cell{border:1px solid #E5E9F1 !important;}
</style>

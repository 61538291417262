<template>
    <el-button round :type="is_on ? 'info' : 'warning'" size="small" :icon="icon" @click="tolog" v-if="!onlyIcon">{{is_on ? '取消收藏' : '收藏'}}</el-button>
    <i class="hand" :class="is_on ? onClass : offClass" v-else @click="tolog"></i>
</template>

<script>
export default {
    name: "LogButton",
    data(){
        return{
            is_on:0
        }
    },
    props:{
        title:{
            type:String,
            default:''
        },
        tid:{
            type:String,
            default:''
        },
        icon:{
            type:String,
            default:'el-icon-star-off'
        },
        offClass:{
            type:String,
            default:'el-icon-star-off'
        },
        onClass:{
            type:String,
            default:'el-icon-star-on'
        },
        type:{
            type:String,
            default:''
        },
        akey:{
            type:String,
            default:''
        },
        isOne:{
            type:Number,
            default:0
        },
        onlyIcon:{
            type:Boolean,
            default:false
        }
    },
    created() {
        this.is_on = this.isOne;
    },
    methods:{
        tolog(){
            let _this = this;

            let _token = this.$user.getToken();

            if(_token) {
                if (this.type && this.tid && this.akey) {
                    this.$http.httpPost({
                        url: '/UserLog/add',
                        datas: {type:this.type,akey:this.akey,tid:this.tid},
                        success: (res) => {
                            if(res === 'add'){
                                _this.is_on = 1;
                            }else{
                                _this.is_on = 0;
                            }
                        }
                    });
                }
            }else{
                this.$message.error('请先登录');
            }
        }
    },
    watch:{
        isOne(n,o){
            this.is_on = n;
        }
    }

}
</script>

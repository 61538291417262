<template>
    <el-select :multiple="multiple" v-model="values" placeholder="请选择" @change="change">
        <el-option
            v-for="item in list"
            :key="item.code"
            :label="item.name"
            :value="item.code"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "AreaSelect",
    data(){
        return{
            list:[],
            values:'',
            name:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number,Array],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        url:{
            type:String,
            default:''
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
	    multiple:{
		    type:Boolean,
		    default:false
	    },
        canAdd:{
            type:Boolean,
            default:true
        }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        if(this.options) {
            this.list = this.options;
        }else{
            this.getList();
        }
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: '/Area/listAll',
                datas: this.data ? this.data : {pid:[1,'<=']},
                success(res) {
                    _this.list = res;
                }
            });
        },
        change(e){
					console.log(e);
            this.$emit("change", e);
        }
    }
}
</script>

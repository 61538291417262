<template>
	<div :class="fontClass">{{values}}<b class="f16" v-if="appendValues">.{{appendValues}}</b><b class="f12 margin-left-5 dark-2">{{unit}}{{last}}</b></div>
</template>

<script>
export default {
	name: "NumberUnit",
	data() {
		return {
			values:0,
			appendValues:'',
			unit:''
		}
	},
	props:{
		value:{
			type:[Number,String],
			default:0
		},
		max:{
			type:Number,
			default:6
		},
		last:{
			type:String,
			default:''
		},
		fontClass:{
			type:String,
			default:''
		}
	},
	created() {
		this.formatNumber(this.value);
	},
	mounted() {
	},
	methods: {
		formatNumber(v){
			let _this = this;
			let _values = v ? Number(v) : 0;
			let _unit = '';
			let _len = 0;
			
			let _list = [];
			
			
			
			// if(_values > 10000000000){
			//   _values = parseInt(_values / 10000000000)+'+'
			//   _unit = '百亿'
			// }else if(_values > 1000000000){
			//   _values = parseInt(_values / 1000000000)+'+'
			//   _unit = '十亿'
			// }else if(_values > 100000000){
			//   console.log(_values / 100000000)
			//   _values = parseInt(_values / 100000000)+'+'
			//   _unit = '亿'
			// }else
			// if(_values > 10000000){
			//   _values = parseInt(_values / 10000000)+'+'
			//   _unit = '千万'
			// }else
			if(_values.toString().length <= this.max){
				_list.push({
					values:_values,
					len:_values.toString().length,
					unit:''
				})
			}
			if(_values > 10000){
				if(parseInt(_values / 10000).toString().length <= this.max){
					_list.push({
						values:parseFloat(_values / 10000).toFixed(2),
						len:parseFloat(_values / 10000).toFixed(2).toString().length,
						unit:'万'
					})
				}
			}
			
			if(_values > 1000000){
				if(parseInt(_values / 1000000).toString().length <= this.max){
					_list.push({
						values:parseFloat(_values / 1000000).toFixed(2),
						len:parseFloat(_values / 1000000).toFixed(2).toString().length,
						unit:'百万'
					})
				}
			}
			
			if(_list && _list.length > 0){
				let min = _list.reduce(function(prev, curr) {
					return Math.abs(prev.len - _this.max) <= Math.abs(curr.len - _this.max) ? prev : curr;
				});
				
				if(min){
					let _arr = parseFloat(min.values).toString().split('.');
					
					this.values = _arr[0];
					this.appendValues = _arr[1] ? _arr[1] : '';
					this.unit = min.unit;
				}
			}
		}
	},
	watch:{
		value(n,o){
			this.formatNumber(n);
		},
	}
}
</script>

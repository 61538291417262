<template>
  <router-view :key="$route.fullPath"/>
</template>
<script>
export default {
	name: "App",
	data(){
		return{}
	},
	mounted() {
		this.incTotal();
	},
	methods:{
		incTotal(){
			let _this = this;
			this.$http.httpPost({
				url:'/SystemTotal/incTotal',
				datas:{type:'page_visit'},
				success(res){},
				fail(err){}
			})
		},
	},
	watch: {
		$route: {
			handler: function(val, oldVal){
				this.incTotal();
			},
			deep: true
		}
	},
}
</script>

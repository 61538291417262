<template>
    <div>
        <div class="cl">
            <img src="/images/icon_2.png" class="z" width="20" style="margin-top:3px;"/>
            <div class="z f18 margin-left dark-2 f-lq">招标推荐</div>
        </div>
        <div class="cl"></div>
        <div class="margin-l-r-30 margin-top">
            <div v-for="(item,index) in list" class="margin-top-30">
                <a @click="toDetail(item.id)" class="f16 text-line-1">{{item.title}}</a>
                <div class="f12 dark-4-2 margin-top-5">截止时间 {{item.end_time}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "zbtj",
    data(){
        return{
            list:[]
        }
    },
    props:{
        count:{
            type:Number,
            default:10
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listAll',
                datas:{is_tuijian:1,state:1,orderBy:'sort,create_time',sortBy:'desc',page:1,limit:this.count},
                success(res){
                    _this.list = res
                }
            })
        },
        toDetail(id){
            window.open('/bids/detail/'+id,'_blank')
        },
    }
}
</script>

<template>
	<m-main-layout ref="main">
		<bread></bread>
		<div class="rel hide margin-top" style="width: 100%;height: 134px;">
			<img class="abs" src="/images/profile_bg.jpg" style="width: 100%;height: 134px;z-index: 1;"/>
			<div class="rel f-bth light f30 padding-left-40" style="line-height: 134px;z-index:2;">
				投标报名
			</div>
		</div>
		<div class="wrap margin-top-20" style="width: 800px !important;" v-if="info">
			<div class="box-shadow-5 bg-light padding-30 border-w-1 border-dark-5 border-radius-5">
				<el-form :model="formData" label-width="100px" :rules="rules" ref="form" label-position="left">
					<el-row :gutter="10">
						<el-col :span="12">
							<el-form-item label="联系人" prop="contact">
								<el-input v-model="formData.contact" maxlength="10" show-word-limit></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="联系电话" prop="phone">
								<el-input v-model="formData.phone" maxlength="15" show-word-limit></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="职务" prop="job">
								<el-input v-model="formData.job" maxlength="15" show-word-limit></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<div v-for="(item,index) in formData.tbzz">
								<el-form-item :label="item.name" label-width="150px" :prop="'tbzz[' + index + '].file'" :rules="[{required: true, message: item.name+'不能为空'}]">
<!--									<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP" v-model="item.file" :limit="1" tips="" :multiple="false" big-type="image" @change="onChange"></one-file-upload>-->
									<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :key="'pinggu_file_'+item.name+'_'+index" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image"></one-file-upload>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="24">
							<el-button type="primary" style="width: 100%;" @click="toSubmit">保存</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
	</m-main-layout>
</template>

<script>
export default {
	name: "join",
	data() {
		return {
			formData:{
				contact:'',
				job:'',
				phone:'',
				tbzz:[]
			},
			info:null,
			id:'',
			rules:{
				contact:[{required:true,message:'联系人不能为空'}],
				phone:[{required:true,message:'联系电话不能为空'},{pattern:this.$regexp.mobile,message:'请填写正确的手机号码'}],
				job:[{required:true,message:'职位不能为空'}],
			}
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {

		if(this.id){
			this.getDetail();
		}
	},
	methods: {
		onChange(e){
			console.log(e);
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _datas = {
						contact: this.formData.contact,
						job: this.formData.job,
						phone: this.formData.phone,
						tid: this.id,
						tbzz: this.formData.tbzz ? JSON.stringify(this.formData.tbzz) : ''
					}

					_this.$http.httpPost({
						url: '/AuthUserToubiaoLog/add',
						datas: _datas,
						success(res) {
							_this.$message.success('保存成功');
							_this.$router.back()
						}
					})
				} else {
					return false;
				}
			});
		},
		getDetail() {
			let _this = this;
			this.$http.httpPost({
				url: '/Bids/detailNew',
				datas: {id: this.id},
				success(res) {
					if(res.is_baoming === 1){
						_this.$alert('你已经报名', '提示', {
							showClose:false,
							confirmButtonText: '返回',
							callback: action => {
								_this.$router.back();
							}
						});
						return;
					}
					_this.info = res;
					let _tbzz = JSON.parse(JSON.stringify(res.tbzz));

					if(_tbzz && _tbzz.length > 0){
						for(let i=0;i < _tbzz.length;i++){
							_tbzz[i].file = ''
							// _this.formData.tbzz.push(_tbzz[i]);
						}
					}else{
						_tbzz = []
					}
					_this.formData.tbzz = _tbzz;
				}
			})
		},
	}
}
</script>

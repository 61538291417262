import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import format from 'vue-text-format';
Vue.use(format);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/main.css';
import './assets/iconfont.css';
import './assets/iconfont2.css';
import './assets/diy.css';
import Moment from 'moment';
import md5 from 'js-md5';
import VueUeditorWrap from 'vue-ueditor-wrap'

import Editor from './components/Editor';

Vue.use(ElementUI);

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import Config from './script/config';
import HTTPs from './script/http';
import Utils from './script/utils'
import User from './script/user';
import RegExp from './script/RegExp';


Vue.prototype.$config = Config;
Vue.prototype.$http = HTTPs;
Vue.prototype.$utils = Utils;
Vue.prototype.$user = User;
Vue.prototype.$moment = Moment;
Vue.prototype.$md5 = md5;
Vue.prototype.$regexp = RegExp;

import UserNavMain from './components/common/UserNavMain';
import MainLayout from './components/common/MainLayout'
import SmallTag from "@/components/widget/SmallTag";
import Bread from "@/components/widget/Bread";
import zbtj from "@/components/zbtj";
import ccgq from "@/components/ccgq";
import zbscroll from "@/components/zbscroll";
import BidTag from "@/components/BidTag";
import Banner from "@/components/widget/Banner";
import UserLayout from "@/components/common/UserLayout";
import UserMenu from "@/components/common/UserMenu";
import MCard from "@/components/widget/Card";
import PushTime from "@/components/widget/PushTime";
import LogButton from "@/components/widget/LogButton";
import UEditor from "@/components/UEditor";
import FileUpload from "@/components/widget/FileUpload";
import TypeSelect from './components/widget/TypeSelect'
import AreaSelect from './components/widget/AreaSelect'
import * as util from 'util'
import TableTd from './components/widget/TableTd'
import TableTh from './components/widget/TableTh'
import HeadImgUpload from './components/widget/HeadImgUpload'
import ProvinceSelect from './components/widget/ProvinceSelect'
import OneFileUpload from './components/widget/OneFileUpload'
import NumberUnit from './components/widget/NumberUnit'
import ImagePreView from './components/widget/ImagePreView'
import VipDialog from './components/widget/VipDialog'
import ImageUpload from './components/widget/ImageUpload'
import TimePicker from './components/widget/TimePicker'

Vue.component('m-main-layout',MainLayout);
Vue.component('m-user-nav-main',UserNavMain);
Vue.component('editor',Editor);
Vue.component('small-tag',SmallTag);
Vue.component('bread',Bread);
Vue.component('zbtj',zbtj);
Vue.component('ccgq',ccgq);
Vue.component('zbscroll',zbscroll);
Vue.component('bid-tag',BidTag);
Vue.component('banner',Banner);
Vue.component('user-layout',UserLayout);
Vue.component('user-menu',UserMenu);
Vue.component('m-card',MCard);
Vue.component('push-time',PushTime);
Vue.component('log-button',LogButton);
Vue.component('vue-ueditor-wrap', VueUeditorWrap);
Vue.component('UEditor',UEditor);
Vue.component('file-upload',FileUpload);
Vue.component('type-select',TypeSelect);
Vue.component('area-select',AreaSelect);
Vue.component('table-td',TableTd);
Vue.component('table-th',TableTh);
Vue.component('head-img-upload',HeadImgUpload);
Vue.component('province-select',ProvinceSelect);
Vue.component('one-file-upload',OneFileUpload);
Vue.component('number-unit',NumberUnit);
Vue.component('image-pre-view',ImagePreView);
Vue.component('vip-dialog',VipDialog);
Vue.component('image-upload',ImageUpload);
Vue.component('time-picker',TimePicker);

HTTPs.httpPost({
  url:'/SystemSetting/getSystem',
  datas:{skey:['default_avatar','service_tel','qq_service_qrcode','vip_weixin_tel','weixin_mini_app_qrcode','weixin_service_qrcode']},
  success(res){
    console.log(res);
    Utils.localSet('weixin_mini_app_qrcode',res.weixin_mini_app_qrcode ? (res.weixin_mini_app_qrcode.indexOf('http') >= 0 ? res.weixin_mini_app_qrcode : Config.assetsUrl + res.weixin_mini_app_qrcode) : '');
    Utils.localSet('vip_weixin_tel',res.vip_weixin_tel);
    Utils.localSet('service_tel',res.service_tel);
    Utils.localSet('default_avatar',res.default_avatar);
  }
})

router.beforeEach((to, from, next) => {

  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }

  if(to.path === '/register'){
    if(!User.getToken()){
      next();
    }else{
      next('/');
    }
  }else if(to.path === '/findpass'){
    if(!User.getToken()){
      next();
    }else{
      next('/');
    }
  }else if(to.path === '/changepass'){
    if(!User.getToken()){
      Utils.error('请先登录');
      next('/');
    }else{
      next();
    }
  }else if(to.path.indexOf('/user') >= 0){
    if(!User.getToken()){
      Utils.error('请先登录');
      next('/');
    }else{
      next();
    }
  }else{
    next();
  }
});

router.afterEach((to,from) => {
  window.scrollTo(0,0);
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

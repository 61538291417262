<template>
	<div class="margin-top" v-if="info">
		<div class="h50 hide">
			<img :src="$utils.formatImagePath(info.logo)" style="height: 50px;"/>
			<span class="vm f30 blue margin-left">{{info.company}}</span>
			<i class="iconfont icon-vip3 f34 margin-left-5 vm" style="color: orange" v-if="info.user_detail && info.user_detail.vip_state === 1"></i><i class="iconfont icon-vip3 f34 margin-left-5 vm" style="color: #bbb" v-if="info.user_detail && info.user_detail.vip_state === 0"></i>
		</div>
		<div class="text-center" v-if="info.bid_state === 0">
			<el-button style="margin:50px auto;" type="primary" @click="showLogin">请先登录</el-button>
		</div>
		<template v-if="info.bid_state === 2">
			<div style="width:500px !important;" class="wrap margin-bottom-40 margin-top-40">
				<div class="hand border-radius-10 hide padding cl" :class="active === 'gys' ? 'bg-blue-2 border-w-1 border-blue' : 'bg-gray-3 border-gray-3 border-w-1'" @click="change('gys')">
					<img src="/images/icon_gold.png" class="z" style="margin-top:15px;width: 20px;height: 20px;"/>
					<div class="margin-left z" :class="active === 'gys' ? 'blue-1' : 'dark-1'">
						<div class="f14">用会员次数解锁</div>
						<div class="f12">需花费1次 剩余:{{info.credit.lessCount === 999999 ? '无限' : info.credit.gys}}次</div>
					</div>
				</div>
				<div class="hand border-radius-10 hide padding margin-top cl" :class="active === 'gold' ? 'bg-blue-2 border-w-1 border-blue' : 'bg-gray-3 border-gray-3 border-w-1'" @click="change('gold')">
					<img src="/images/icon_gold.png" class="z" style="margin-top:15px;width: 20px;height: 20px;"/>
					<div class="margin-left z" :class="active === 'gold' ? 'blue-1' : 'dark-1'">
						<div class="f14">使用积分解锁</div>
						<div class="f12">需花费100积分 剩余:{{info.credit.gold}}积分</div>
					</div>
				</div>
				<el-button type="primary" class="margin-top" style="width: 100%;" @click="toUnLock">确认解锁</el-button>
			</div>
		</template>
		<template v-if="info.bid_state === 1">
			<div class="el-descriptions margin-top">
				<div class="el-descriptions__body">
					<table class="el-descriptions__table is-bordered">
						<tbody>
						<tr class="el-descriptions-row">
							<th colspan="4" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label" style="text-align: center;font-size: 38px !important;color:#15489D !important;">企业信息</th>
						</tr>
						</tbody>
						<tbody>
							<tr class="el-descriptions-row">
								<table-th>注册资金</table-th>
								<table-td>{{info.zczj ? info.zczj : '-'}}万</table-td>
								<table-th>自有运力</table-th>
								<table-td>{{info.zyyl ? info.zyyl : '-'}}辆</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>冷库面积</table-th>
								<table-td>{{info.lkmj ? info.lkmj : '-'}}m³</table-td>
								<table-th>普通仓库面积</table-th>
								<table-td>{{info.cwkmj ? info.cwkmj : '-'}}m³</table-td>
							</tr>
		<!--					<tr class="el-descriptions-row">-->
		<!--						<table-th>办公地址</table-th>-->
		<!--						<table-td :span="3">32</table-td>-->
		<!--					</tr>-->
							<tr class="el-descriptions-row">
								<table-th>客户类型</table-th>
								<table-td :span="3">{{info.industry_str ? info.industry_str : '-'}}</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>服务类型</table-th>
								<table-td :span="3">{{info.fuwu_str ? info.fuwu_str : '-'}}</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>服务区域</table-th>
								<table-td :span="3">{{info.province_str ? info.province_str : '-'}}</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>联系人</table-th>
								<table-td>{{info.contact}}</table-td>
								<table-th>职务</table-th>
								<table-td>{{info.job}}</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>电话</table-th>
								<table-td>{{info.phone}}</table-td>
								<table-th>官方网址</table-th>
								<table-td><a :href="info.homesite" target="_blank" rel="opener" v-if="info.homesite">{{info.homesite}}</a></table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>合作客户</table-th>
								<table-td :span="3">{{info.hzkh}}</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>企业介绍</table-th>
								<table-td :span="3"><a :href="$utils.formatImagePath(info.ppt)" target="_blank" v-if="info.ppt">企业介绍.ppt</a></table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>重要资质认证</table-th>
								<table-td :span="3">
									<span class="hand blue-2 inline-block padding-right-15" v-for="(item,index) in info.zizhi_files" v-if="item.file && item.name" @click="showFilePop(item.file)">{{item.name}}</span>
								</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>企业荣誉</table-th>
								<table-td :span="3">
									<span class="hand blue-2 inline-block padding-right-15" v-for="(item,index) in info.rongyu_files" v-if="item.file && item.name" @click="showFilePop(item.file)">{{item.name}}</span>
								</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<table-th>重要评估认证</table-th>
								<table-td :span="3">
									<span class="hand blue-2 inline-block padding-right-15" v-for="(item,index) in info.pinggu_files" v-if="item.file && item.name" @click="showFilePop(item.file)">{{item.name}}</span>
								</table-td>
							</tr>
							<tr class="el-descriptions-row">
								<th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label">天眼查信息</th>
								<td colspan="3" class="el-descriptions-item__cell el-descriptions-item__content">
									<a :href="'https://www.tianyancha.com/search?key='+info.company" target="_blank" rel="opener" class="border-radius bg-blue inline-block light f16 padding-left-right hand padding-top-bottom-5">
										<i class="iconfont icon-tianyancha"></i>
										跳转到天眼查
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</template>
		<div class="f20 blue margin-bottom margin-top">相似企业</div>
		<list-wuliu-item class="box-shadow-5 bg-light border-radius-5 padding-20" v-for="(item,index) in companyList" :info="item"></list-wuliu-item>
		<el-dialog
			:visible.sync="showBigImg"
			width="40%">
				<el-row :gutter="20">
					<el-col :span="6" v-for="(item,index) in popList">
						<el-image
							v-if="item.isImage"
							style="width: 100%; height: 120px"
							:src="$utils.formatImagePath(item.url)"
							:preview-src-list="[$utils.formatImagePath(item.url)]">
						</el-image>
<!--						<img :src="$utils.formatImagePath(item.url)" style="width: 100%;" v-if="item.isImage"/>-->
						<div style="cursor:pointer;text-align:center;height: 120px;width: 100%;background-color: #eee;" v-else @click="toOpenFile($utils.formatImagePath(item.url))">
							<div style="height: 90px;line-height: 90px;" class="hide">
								<i class="el-icon-paperclip" style="font-size: 40px;vertical-align: middle;"></i>
							</div>
							<div class="text-line-1" style="margin:0 5px;height: 30px;line-height: 30px;">{{item.name}}</div>
						</div>
					</el-col>
				</el-row>
<!--				<img :src="$utils.formatImagePath(bigImgUrl)" style="width: 100%;"/>-->
				<span slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="showBigImg = false">关闭</el-button>
			  </span>
		</el-dialog>
	</div>
</template>

<script>
import ListWuliuItem from '../listTemplate/wuliu'
export default {
	name: "wuliu",
	components: {ListWuliuItem},
	data() {
		return {
			showBigImg:false,
			bigImgUrl:'',
			bidsSearch:{
				uid:'',
				orderBy: 'create_time',
				sortBy: 'desc',
				page: 1,
				pageSize: 10
			},
			companySearch:{
				user_type:'gys',
				gys_type:'wuliu',
				orderBy: 'RAND()',
				type_industry_ids: ''
			},
			totalCount:0,
			bidsList:[],
			companyList:[],
			companyTotal:null,
			
			active:'gys',
			count:1,
			
			popList:[]
		}
	},
	props:{
		info:{
			type:[Object,Array],
			default:null
		}
	},
	created() {
		console.log(this.info);
		if(this.info){
			this.bidsSearch.uid = this.info.uid;
			this.companySearch.type_industry_ids = this.info.type_industry_ids ? this.info.type_industry_ids.join(',') : '';
		}
	},
	mounted() {
		this.getCompanyList();
	},
	methods: {
		showLogin(){
			this.$parent.showLogin = true;
		},
		hideFilePop(){
			this.setData({showPop:false,popList:[]})
		},
		
		showFilePop(file){
			if(file){
				let _list = [];
				if(Object.prototype.toString.call(file) === '[object Array]'){
					for(let i=0;i < file.length;i++){
						_list.push({
							name:file[i].replace(/\/.+\//,''),
							url:file[i],
							isImage:this.isPic(file[i])
						})
					}
				}else{
					_list.push({
						name:file.replace(/\/.+\//,''),
						url:file,
						isImage:this.isPic(file)
					})
				}
				if(_list.length > 0){
					this.showBigImg = true;
					this.popList = _list;
				}
			}
		},
		
		isPic(url){
			let reg = /\.(png|jpg|gif|jpeg|webp|PNG|JPG|GIF|JPEG)$/;
			return reg.test(url);
		},
		
		toOpenFile(file){
			if(file){
				window.open(file);
			}
		},
		
		toShowBigImg(url){
			this.bigImgUrl = url;
			this.showBigImg = true;
		},
		getCompanyList(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserCompany/listAll',
				datas:{...this.companySearch,page:1,limit:5},
				success(res){
					_this.companyList = res;
				}
			})
		},
		toUnLock(){
			let _this = this;
			
			this.$confirm('是否确认解锁此供应商?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.httpPost({
					url:'/AuthOrder/addOrderByCredit',
					datas:{tid:_this.info.uid,todo:'unlock_gys',type:_this.active,count:_this.count},
					success(res){
						_this.$message.success('解锁成功');
						// _this.getDetail();
						_this.$parent.$options.parent.getDetail();
						_this.getCompanyList()
					}
				})
			}).catch(() => {
			
			});
		},
		change(e){
			let _active = e;
			
			let _count = 0;
			if(_active === 'gold'){
				_count = 100
			}else if(_active === 'bids'){
				_count = 1;
			}
			this.active = _active;
			this.count = _count;
		},
	}
}
</script>
<style scoped>
.el-descriptions-item__label.is-bordered-label{background: #EFF2F8 !important;width: 200px !important;color:#333 !important;font-size: 16px !important;text-align: center !important;}
.el-descriptions .is-bordered .el-descriptions-item__cell{border:1px solid #E5E9F1 !important;}
</style>

<template>
    <m-main-layout>
        <bread :list="bread"></bread>
        <div class="cl margin-top-30" v-if="info">
            <div class="z hide" style="width: 860px;">
                <div class="padding-50">
                    <div class="text-center f26">{{info.title}}</div>
                    <div class="padding-top-bottom-40 text-center f16 dark-2">
                        <span class="">时间：{{info.create_time}}</span>
                        <span class="margin-left-30">来源：{{info.source ? info.source : '-'}}</span>
                    </div>
                    <div class="line-2"></div>
                    <div class="cl"></div>
                    <div class="f16 dark-2 margin-top-30 padding-bottom-100" v-html="info.content"></div>
                </div>
            </div>
            <div class="y bg-gray-4 hide padding-15" style="width: 317px;">
                <zbtj></zbtj>
                <div class="line-1"></div>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "Detail",
    data(){
        return{
            id:'',
            info:null,
            bread:[]
        }
    },
    created() {
        this.id = this.$route.params.id ? this.$route.params.id : ''
    },
    mounted() {
        if(this.id){
            this.getDetail();
        }
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.httpPost({
                url:'/News/detail',
                datas:{id:this.id},
                success(res){
                    _this.info = res;

                    if(res.type === 'policy'){
                        _this.bread = [{name:'政策法规',path:'/news/policy'},{name:res.title,path:'/'}];
                    }else if(res.type === 'logistics'){
                        _this.bread = [{name:'物流资讯',path:'/news/logistics'},{name:res.title,path:'/'}];
                    }else if(res.type === 'odd'){
	                    _this.bread = [{name:'散单平台',path:'/news/odd'},{name:res.title,path:'/'}];
                    }

                    document.title = res.title+'-'+_this.bread+'-中国物流招标网';
                }
            })
        }
    }
}
</script>

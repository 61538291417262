<template>
	<th :colspan="span" class="el-descriptions-item__cell el-descriptions-item__content">
		<slot></slot>
	</th>
</template>

<script>
export default {
	name: "TableTd",
	data() {
		return {}
	},
	props:{
		span:{
			type:Number,
			default:1
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>

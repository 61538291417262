<template>
	<m-main-layout>
		<bread :list="bread"></bread>
		<div class="cl margin-top-15">
			<div class="z hide" style="width: 860px;">
				<el-form inline>
					<template v-if="type === 'shebei'">
						<el-form-item label="关键字">
							<el-input v-model="searchForm.company" style="width: 170px;" placeholder="请输入关键字"></el-input>
						</el-form-item>
						<el-form-item label="优势产品">
							<type-select v-model="searchForm.type_superiority_ids" url="/Type/listAll" :can-add="false" placeholder="请选择" :data="{akey:'superiority',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 155px;"></type-select>
						</el-form-item>
						<el-form-item label="办公区域">
							<province-select style="width: 155px;" v-model="bangong_area" @change-area="onChangeArea"></province-select>
						</el-form-item>
						<el-button type="primary" @click="toSearch">查询</el-button>
						<el-button @click="toReset">重置</el-button>
					</template>
					<template v-else>
						<el-form-item label="关键字" label-width="80px">
							<el-input v-model="searchForm.company" style="width: 260px;" placeholder="请输入关键字"></el-input>
						</el-form-item>
						<el-form-item label="行业" label-width="80px">
							<type-select v-model="searchForm.type_industry_id" url="/Type/listAll" :can-add="false" placeholder="请选择" :data="{akey:'industry',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 260px;"></type-select>
						</el-form-item>
						<el-button type="primary" @click="toSearch">查询</el-button>
						<el-button @click="toReset">重置</el-button>
						<el-form-item label="服务" label-width="80px">
							<type-select v-model="searchForm.type_fuwu_id" url="/Type/listAll" :can-add="false" placeholder="请选择" :data="{akey:'fuwu',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 260px;"></type-select>
						</el-form-item>
						<el-form-item label="区域" label-width="80px">
							<type-select v-model="searchForm.province_code" url="/Area/listAll" :can-add="false" placeholder="请选择" :data="{pid:['1','<='],orderBy:'code',sortBy:'asc',state: 1}" style="width: 260px;" val-key="code"></type-select>
						</el-form-item>
					</template>
				</el-form>
				<div>
					<div class="f14 dark-3 margin-bottom" v-if="type === 'hz'">当前分类下招标方 <b class="blue">{{totalCount}}</b> 条</div>
					<div class="f14 dark-3 margin-bottom" v-if="type === 'wuliu'">当前分类下物流供应商 <b class="blue">{{totalCount}}</b> 条</div>
					<div class="f14 dark-3 margin-bottom" v-if="type === 'shebei'">当前分类下设备供应商 <b class="blue">{{totalCount}}</b> 条</div>
					<template v-if="list.length > 0">
						<template v-for="(item,index) in list">
							<list-huozhu-item v-if="type === 'hz'" :info="item"></list-huozhu-item>
							<list-wuliu-item v-if="type === 'wuliu'" :info="item"></list-wuliu-item>
							<list-shebei-item v-if="type === 'shebei'" :info="item"></list-shebei-item>
						</template>
					</template>
					<el-empty description="暂无内容" v-else></el-empty>
				</div>
			</div>
			<div class="y bg-gray-4 hide padding-15" style="width: 317px;">
				<zbtj></zbtj>
			</div>
		</div>
		<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
			<el-pagination
				background
				@current-change="pageChange"
				:current-page="searchForm.page"
				:page-size="searchForm.pageSize"
				layout="prev, pager, next, jumper"
				:total="totalCount">
			</el-pagination>
		</div>
	</m-main-layout>
</template>

<script>
import ListHuozhuItem from './listTemplate/huozhu'
import ListWuliuItem from './listTemplate/wuliu'
import ListShebeiItem from './listTemplate/shebei'
export default {
	name: "list",
	components: {ListHuozhuItem,ListWuliuItem,ListShebeiItem},
	data() {
		return {
			type:'',
			searchForm:{
				company:'',
				page:1,
				pageSize: 10,
				state:1,
				user_type:'',
				gys_type:'',
				province_code:'',
				type_industry_id:'',
				type_fuwu_id:'',
				bangong_city_code:'',
				bangong_province_code:''
			},
			bangong_area:[],
			totalCount:0,
			bread:[],
			list:[]
		}
	},
	created() {
		this.list = []
		
		this.type = this.$route.params.type ? this.$route.params.type : '';
		
		let _query = this.$user.getQuery(this.$route.path);
		
		if(_query){
			this.searchForm = {..._query}
		}
		
		this.searchForm.company = this.$route.query.kw ? this.$route.query.kw : '';
		
		if(this.type === 'hz'){
			this.bread.push({name:'找货主'});
			this.searchForm.user_type = 'hz'
			this.searchForm.gys_type = '';
		}else if(this.type === 'wuliu'){
			this.bread.push({name:'找物流'});
			this.searchForm.user_type = 'gys'
			this.searchForm.gys_type = 'wuliu';
		}else if(this.type === 'shebei'){
			this.bread.push({name:'找设备'});
			this.searchForm.user_type = 'gys'
			this.searchForm.gys_type = 'shebei';
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		onChangeArea(e){
			this.formData.bangong_province_code = e.provinceCode;
			this.formData.bangong_city_code = e.cityCode;
		},
		toSearch(){
			this.searchForm.page = 1;
			this.getList();
		},
		toReset(){
			this.searchForm.page = 1;
			this.searchForm.company = '';
			this.searchForm.type_industry_id = '';
			this.searchForm.province_code = '';
			this.searchForm.type_fuwu_id = '';
			
			this.getList();
		},
		getList(){
			let _this = this;
			this.$http.httpPost({
				url:'/UserCompany/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
					window.scrollTo(0,0)
					_this.$user.setQuery(_this.$route.path,_this.searchForm);
				}
			})
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>

<template>
	<el-form :model="formData" label-width="100px" :rules="rules" ref="form" label-position="left">
		<el-row :gutter="20">
			<el-col :span="24" v-if="!hideType">
				<el-form-item label="状态" prop="type">
					<el-tag type="info" v-if="formData.state === 0">待审核</el-tag>
					<el-tag type="success" v-if="formData.state === 1">已通过</el-tag>
					<el-tag type="danger" v-if="formData.state === 2">已拒绝</el-tag>
					<el-tag v-if="formData.state === 3">待完善</el-tag>
					<span class="margin-left-5 dark-3" v-if="formData.state === 2">拒绝理由：{{formData.reason}}</span>
				</el-form-item>
			</el-col>
			<el-col :span="12" v-if="!hideType">
				<el-form-item label="身份" prop="type">投标方</el-form-item>
			</el-col>
			<el-col :span="12" v-if="!hideType">
				<el-form-item label="供应商类型" prop="type">物流供应商</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="公司名称" prop="company">
					<el-input v-model="formData.company" maxlength="20" show-word-limit :disabled="formData.state === 1"></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="企业logo" prop="logo">
					<head-img-upload v-model="formData.logo" :disabled="formData.state === 1"></head-img-upload>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="注册资金" prop="zczj">
					<el-input v-model="formData.zczj" maxlength="15" show-word-limit :disabled="formData.state === 1">
						<template slot="append">万元</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="客户类型" prop="type_industry_ids">
					<type-select :can-add="false" multiple v-model="formData.type_industry_ids" url="/Type/listAll" :data="{akey:'industry',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="服务类型" prop="type_fuwu_ids">
					<type-select :can-add="false" multiple v-model="formData.type_fuwu_ids" url="/Type/listAll" :data="{akey:'fuwu',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="服务区域" prop="province_codes">
					<type-select :can-add="false" multiple v-model="formData.province_codes" url="/Area/listAll" :data="{state:1,orderBy:'code',sortBy:'asc',pid:[1,'<=']}" val-key="code" style="width: 100%;"></type-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="办公区域" prop="bangong_city_code">
					<province-select style="width: 100%;" v-model="bangong_area" @change-area="onChangeArea"></province-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="联系人" prop="contact">
					<el-input v-model="formData.contact" maxlength="10" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="formData.phone" maxlength="15" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="职务" prop="job">
					<el-input v-model="formData.job" maxlength="15" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="冷库容量" prop="lkmj">
					<el-input v-model="formData.lkmj" maxlength="7" show-word-limit>
						<template slot="append">m³</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="仓库容量" prop="cwkmj">
					<el-input v-model="formData.cwkmj" maxlength="7" show-word-limit>
						<template slot="append">m³</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="自有运力" prop="zyyl">
					<el-input v-model="formData.zyyl" maxlength="6" show-word-limit>
						<template slot="append">辆</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="合作客户" prop="hzkh">
					<el-input v-model="formData.hzkh" maxlength="50" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="企业官网" prop="homesite">
					<el-input v-model="formData.homesite" maxlength="50" show-word-limit></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item label="企业介绍" prop="ppt">
					<one-file-upload v-model="formData.ppt" :files="formData.ppt" :limit="1" tips="" :multiple="false" :disabled="formData.state === 1"></one-file-upload>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<div class="bg-gray-5 border-radius-10 hide margin-bottom-20">
					<div class="cl padding border-bottom-1 border-dark-5 margin-bottom">
						<span class="blue f16 z" style="line-height: 28px;">重要资质认证</span>
						<el-button class="y" size="mini" type="primary" icon="el-icon-plus" @click="addZizhi">新增资质</el-button>
					</div>
					<div v-for="(item,index) in formData.zizhi_files" class="margin-left margin-right">
						<template v-if="item.id">
							<el-form-item :label="item.name" label-width="300px">
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
						<template v-else>
							<el-form-item label-width="300px" :prop="'zizhi_files.' + index + '.file'" :rules="[{required: true, message: '资质图片不能为空'}]">
								<template slot="label">
									<el-form-item class="inline-block" label="" :prop="'zizhi_files.' + index + '.name'" :rules="[{required: true, message: '资质名称不能为空'}]">
										<el-popconfirm title="是否删除此企业资质？" @confirm="toDelZizhi(index)">
											<el-button slot="reference" type="text" class="margin-right-5">删除</el-button>
										</el-popconfirm>
										<el-input v-model="item.name" style="width: 240px;" placeholder="请输入资质名称"></el-input>
									</el-form-item>
								</template>
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
					</div>
				</div>
			</el-col>
			<el-col :span="24">
				<div class="bg-gray-5 border-radius-10 hide margin-bottom-20">
					<div class="cl padding border-bottom-1 border-dark-5 margin-bottom">
						<span class="blue f16 z" style="line-height: 28px;">重要评估认证</span>
						<el-button class="y" size="mini" type="primary" icon="el-icon-plus" @click="addPinggu">新增评估认证</el-button>
					</div>
					<div v-for="(item,index) in formData.pinggu_files" class="margin-left margin-right" :key="'pinggu_'+index">
						<template v-if="item.id">
							<el-form-item :label="item.name" label-width="300px">
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :key="'pinggu_file_'+index" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
						<template v-else>
							<el-form-item label-width="300px" :prop="'pinggu_files.' + index + '.file'" :rules="[{required: true, message: '资质图片不能为空'}]">
								<template slot="label">
									<el-form-item class="inline-block" label="" :prop="'pinggu_files.' + index + '.name'" :rules="[{required: true, message: '资质名称不能为空'}]">
										<el-popconfirm title="是否删除此企业资质？" @confirm="toDelPinggu(index)">
											<el-button slot="reference" type="text" class="margin-right-5">删除</el-button>
										</el-popconfirm>
										<el-input v-model="item.name" style="width: 240px;" placeholder="请输入资质名称"></el-input>
									</el-form-item>
								</template>
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
					</div>
				</div>
			</el-col>
			<el-col :span="24">
				<div class="bg-gray-5 border-radius-10 hide margin-bottom-20">
					<div class="cl padding border-bottom-1 border-dark-5 margin-bottom">
						<span class="blue f16 z" style="line-height: 28px;">企业荣誉</span>
						<el-button class="y" size="mini" type="primary" icon="el-icon-plus" @click="addRongyu">新增企业荣誉</el-button>
					</div>
					<div v-for="(item,index) in formData.rongyu_files" class="margin-left margin-right">
						<template v-if="item.id">
							<el-form-item :label="item.name" label-width="300px">
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
						<template v-else>
							<el-form-item label-width="300px" :prop="'rongyu_files.' + index + '.file'" :rules="[{required: true, message: '荣誉图片不能为空'}]">
								<template slot="label">
									<el-form-item class="inline-block" label="" :prop="'rongyu_files.' + index + '.name'" :rules="[{required: true, message: '荣誉名称不能为空'}]">
										<el-popconfirm title="是否删除此企业资质？" @confirm="toDelRongyu(index)">
											<el-button slot="reference" type="text" class="margin-right-5">删除</el-button>
										</el-popconfirm>
										<el-input v-model="item.name" style="width: 240px;" placeholder="请输入荣誉名称"></el-input>
									</el-form-item>
								</template>
								<one-file-upload accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx" v-model="item.file" :files="item.file" :limit="5" tips="" :multiple="true" big-type="image" :disabled="formData.state === 1"></one-file-upload>
							</el-form-item>
						</template>
					</div>
				</div>
			</el-col>
			<el-col :span="24">
				<el-button type="primary" style="width: 100%;" @click="toSave">保存</el-button>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: "wuliu",
	data() {
		return {
			formData:null,
			bangong_area:[],
			rules:{
				company:[{required:true,message:'企业名称不能为空'}],
				logo:[{required:true,message:'企业logo不能为空'}],
				zczj:[{required:true,message:'注册资金不能为空'}],
				type_industry_ids:[{required:true,message:'客户类型不能为空'}],
				type_fuwu_ids:[{required:true,message:'服务类型不能为空'}],
				province_codes:[{required:true,message:'服务区域不能为空'}],
				bangong_city_code:[{required:true,message:'办公区域不能为空'}],
				contact:[{required:true,message:'联系人不能为空'}],
				phone:[{required:true,message:'联系电话不能为空'},{pattern:this.$regexp.mobile,message:'请填写正确的手机号码'}],
				job:[{required:true,message:'职位不能为空'}],
				lkmj:[{required:true,message:'冷库容量不能为空'},{pattern:this.$regexp.money,message:'请填写数字'}],
				cwkmj:[{required:true,message:'仓库容量不能为空'},{pattern:this.$regexp.money,message:'请填写数字'}],
				zyyl:[{required:true,message:'自有运力不能为空'},{pattern:this.$regexp.positiveInteger,message:'请填写数字'}],
			}
		}
	},
	props:{
		data:{
			type:[Object,Array],
			default:null
		},
		hideType:{
			type:Boolean,
			default:false
		}
	},
	created() {
		if(this.data) {
			this.formData = JSON.parse(JSON.stringify(this.data));
			this.formData.user_type = 'gys';
			this.bangong_area = [this.data.bangong_province_code, this.data.bangong_city_code]
		}
	},
	mounted() {
		this.getZiZhiFile();
		this.getPingGuFile();
	},
	methods: {
		getZiZhiFile(){
			let _this = this;
			_this.$http.httpPost({
				url:'/Type/listAll',
				datas:{state:1,akey:'tbzz',orderBy:'sort',sortBy:'asc',uid:0,type_key:'wuliu'},
				success(res){
					// _this.formData.zizhi_files = res;
				}
			})
		},
		getPingGuFile(){
			let _this = this;
			_this.$http.httpPost({
				url:'/Type/listAll',
				datas:{state:1,akey:'pinggu',orderBy:'sort',sortBy:'asc',uid:0,type_key:'wuliu'},
				success(res){
					// _this.formData.pinggu_files = res;
				}
			})
		},
		addZizhi(){
			if(this.formData.state === 1){
				this.$message.error('你已通过认证，不能添加')
				return;
			}
			let _data = {name:'',file:'',id:''};
			
			this.formData.zizhi_files.push(_data);
		},
		toDelZizhi(index){
			this.formData.zizhi_files.splice(index,1);
		},
		
		addPinggu(){
			if(this.formData.state === 1){
				this.$message.error('你已通过认证，不能添加')
				return;
			}
			let _data = {name:'',file:'',id:''};
			
			this.formData.pinggu_files.push(_data);
		},
		toDelPinggu(index){
			this.formData.pinggu_files.splice(index,1);
		},
		
		addRongyu(){
			if(this.formData.state === 1){
				this.$message.error('你已通过认证，不能添加')
				return;
			}
			let _data = {name:'',file:'',id:''};
			
			this.formData.rongyu_files.push(_data);
		},
		toDelRongyu(index){
			this.formData.rongyu_files.splice(index,1);
		},
		
		onChangeArea(e){
			this.formData.bangong_province_code = e.provinceCode;
			this.formData.bangong_province_name = e.provinceName;
			this.formData.bangong_city_code = e.cityCode;
			this.formData.bangong_city_name = e.cityName;
		},
		toSave(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _datas = {
						user_type:this.formData.user_type,
						gys_type:this.formData.gys_type,
						zczj:this.formData.zczj,
						type_fuwu_ids:this.formData.type_fuwu_ids,
						type_industry_ids:this.formData.type_industry_ids,
						type_superiority_ids:this.formData.type_superiority_ids,
						province_codes:this.formData.province_codes,
						bangong_province_code:this.formData.bangong_province_code,
						bangong_province_name:this.formData.bangong_province_name,
						bangong_city_code:this.formData.bangong_city_code,
						bangong_city_name:this.formData.bangong_city_name,
						lkmj:this.formData.lkmj,
						cwkmj:this.formData.cwkmj,
						zyyl:this.formData.zyyl,
						hzkh:this.formData.hzkh,
						homesite:this.formData.homesite,
						ppt:this.formData.ppt,
						contact:this.formData.contact,
						phone:this.formData.phone,
						job:this.formData.job,
						company:this.formData.company,
						logo:this.formData.logo,
						zizhi_files:JSON.stringify(this.formData.zizhi_files),
						rongyu_files:JSON.stringify(this.formData.rongyu_files),
						pinggu_files:JSON.stringify(this.formData.pinggu_files),
						sanfang_files:JSON.stringify(this.formData.sanfang_files),
					}
					_this.$http.httpPost({
						url:'/AuthUserCompany/edit',
						datas:_datas,
						success(res){
							_this.$message.success('保存成功');
							_this.$router.replace({path:'/user'})
						}
					})
				} else {
					return false;
				}
			});
		}
	},
	watch:{
		data:{
			handler(n,o){
				this.formData = n ? JSON.parse(JSON.stringify(n)) : null;
				this.bangong_area = [n.bangong_province_code, n.bangong_city_code]
			},
			deep:true,
			immediate:false
		}
	}
}
</script>

<template>
    <m-main-layout>
        <bread></bread>
        <el-row :gutter="10" class="margin-top-15">
            <el-col :span="3" v-for="(item,index) in list" class="margin-bottom rel inline-block">
                <img @click="toOutUrl(item.out_url)" :src="item.head_img.indexOf('http') >= 0 ? item.head_img : $config.assetsUrl + item.head_img" class="hand img-hover border-w-1 border-dark-3" style="width: 141px;height: 65px;" v-if="item.head_img"/>
            </el-col>
        </el-row>
    </m-main-layout>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            list:[]
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Company/listAll',
                datas:{state:1,orderBy:'sort',sortBy:'asc',type:'vip'},
                success(res){
                    _this.list = res;
                }
            })
        },
        toOutUrl(out_url){
            if(out_url){
                let _url = out_url.indexOf('http') >= 0 ? out_url : 'http://'+out_url;

                window.open(_url,'_blank');
            }
        }
    }
}
</script>
<style scoped>
.img-hover{transition: All 0.4s ease-in-out;
    -webkit-transition: All 0.4s ease-in-out;
    -moz-transition: All 0.4s ease-in-out;
    -o-transition: All 0.4s ease-in-out;}
.img-hover:hover{transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -o-transform: scale(1.4);
    -ms-transform: scale(1.4);position: relative;z-index: 999;}
</style>

<template>
    <user-layout>
        <user-menu path="/user">
            <div class="border-w-1 border-dark-7 bg-light padding-top-bottom-40 padding-left-right border-radius-10">
                <div class="padding-left-40 dark-2 f20">我的招标</div>
                <div class="padding-left-right-40 padding-top-30 border-bottom-2 border-dark-7 f18 dark-2">
                    <el-row>
                        <el-col :span="5">
                            <span class="inline-block padding-bottom-30" :class="searchForm.type === 'notice' ? 'border-bottom-2 border-blue' : ''">
                                <span class="hand inline-block" @click="changeTab('notice')">招标公告</span>
                            </span>
                        </el-col>
                        <el-col :span="5">
                              <span class="inline-block padding-bottom-30" :class="searchForm.type === 'coldChain' ? 'border-bottom-2 border-blue' : ''">
                                  <span class="hand inline-block" @click="changeTab('coldChain')">冷链专区</span>
                              </span>
                        </el-col>
                        <el-col :span="5">
                            <span class="inline-block padding-bottom-30" :class="searchForm.type === 'odd' ? 'border-bottom-2 border-blue' : ''">
                                <span class="hand inline-block" @click="changeTab('odd')">散单平台</span>
                            </span>
                        </el-col>
                        <el-col :span="5">
                            <span class="inline-block padding-bottom-30" :class="searchForm.type === 'storage' ? 'border-bottom-2 border-blue' : ''">
                                <span class="hand inline-block" @click="changeTab('storage')">仓储供给</span>
                            </span>
                        </el-col>
                        <el-col :span="4">
                            <span class="inline-block padding-bottom-30" :class="searchForm.type === 'device' ? 'border-bottom-2 border-blue' : ''">
                                <span class="hand inline-block" @click="changeTab('device')">设备采购</span>
                            </span>
                        </el-col>
                    </el-row>
                </div>
                <template v-if="list.length > 0">
                    <div class="cl padding-top-30 padding-bottom-30 dark-2" style="line-height: 22px;" v-for="(item,index) in list" @click="toBids(item)">
                        <a class="f16 dark-2 z padding-left-right-40 text-line-1" style="width: 80%;">{{item.title}}</a>
                        <div class="f16 dark-3 z text-center" style="width: 20%;">{{item.push_time}}</div>
                    </div>
                </template>
                <div class="text-center padding-top-bottom-100 f18 dark-3" v-else>
                    暂无招标<el-button type="primary" @click="$router.push({path:'/push2'})" class="padding-left-right-40 margin-left-20">立即发布</el-button>
                </div>
                <div class="bg-gray-4 margin-40 padding margin-auto text-center" style="height: 52px;border-radius: 26px;" v-if="list.length > 0">
                    <el-pagination
                        background
                        @current-change="pageChange"
                        :current-page="searchForm.page"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalCount">
                    </el-pagination>
                </div>
            </div>
        </user-menu>
    </user-layout>
</template>

<script>
export default {
    name: "mybid",
    data(){
        return{
            searchForm:{
                page:1,
                type:''
            },
            pageSize:8,
            totalCount:0,
            list:[],
        }
    },
    created() {
        this.searchForm.type = this.$route.query.type ? this.$route.query.type : 'notice';
        this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        changeTab(index){
            this.searchForm.type = index;
            this.searchForm.page = 1;

            // this.getList();
            this.$router.replace({query:{...this.searchForm}});
        },
        pageChange(e){
            this.searchForm.page = e;

            // this.getList();
            this.$router.replace({query:{...this.searchForm}});
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url: '/AuthBids/listPage',
                datas:{...this.searchForm,pageSize:this.pageSize},
                success: (res) => {
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        toBids(item){
            if(item.state === 0){
                this.$alert('该信息未审核', '提示', {
                    confirmButtonText: '知道啦'
                });

                return;
            }else{
                this.$router.push({path:'/bids/detail/'+item.id});
            }
        }
    }
}
</script>

<template>
	<m-main-layout v-if="info" ref="main">
		<bread :list="[{name:'标讯详情'}]"></bread>
		<div class="cl margin-top-30">
			<div class="z hide" style="width: 860px;">
				<div class="padding-50">
					<div class="text-center f26">{{ info.title }}</div>
					<div class="padding-top-bottom-40 text-center f16 dark-2">
						<span class="">报名截止时间：{{ info.is_join_end_long === 1 ? '长期有效' : (info.join_end_time ? info.join_end_time : '-') }}</span>
						<span class="margin-left-30">投标截止时间：{{ info.is_end_long === 1 ? '长期有效' : info.end_time }}</span>
					</div>
					<div class="line-2"></div>
					<div class="cl"></div>
				</div>
				<div class="padding-20">
					<el-row>
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">项目概要：</el-col>
						<el-col :span="20" class="f16">
							<el-button size="small" round type="primary" plain @click="showTuijian = true">一键推荐</el-button>
							<log-button type="favorite" akey="bids" :tid="id" :is-one="info.is_favorite" class="margin-left-20" style="padding:5px 15px !important;" icon="el-icon-star-off" title="收藏"></log-button>
							<el-button size="mini" icon="el-icon-circle-check" round type="danger" @click="toJoin" :disabled="info.is_baoming === 1" v-if="info.uid !== uid && info.can_baoming === 1">{{info.is_baoming === 1 ? '已报名' : '报名'}}</el-button>
						</el-col>
					</el-row>
					<el-row class="margin-top-40">
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">项目地区：</el-col>
						<el-col :span="20" class="f16 dark-2" style="line-height: 24px;">{{ info.area_str ? info.area_str : '-' }}
						</el-col>
					</el-row>
					<el-row class="margin-top-40" v-if="info.survey">
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">项目概况：</el-col>
						<el-col :span="20" class="f16 dark-2" style="line-height: 24px;" v-html="info.survey"></el-col>
<!--						<el-col :span="20" class="f16 red" style="line-height: 24px;" v-else>( 该项目只对VIP用户开放 )</el-col>-->
					</el-row>
					<el-row class="margin-top-40" v-if="info.need">
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">项目要求：</el-col>
						<el-col :span="20" class="f16 dark-2" style="line-height: 24px;" v-html="info.need"></el-col>
<!--						<el-col :span="20" class="f16 red" style="line-height: 24px;" v-else>( 该项目只对VIP用户开放 )</el-col>-->
					</el-row>
					<el-row class="margin-top-40">
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">详情内容：</el-col>
						<el-col :span="20" class="f16 red" style="line-height: 24px;" v-if="info.bid_state === 0">
							<el-button type="primary" @click="$refs.main.showLogin = true">请先登录</el-button>
						</el-col>
						<el-col :span="20" class="f16" style="line-height: 24px;" v-if="info.bid_state === 2">
							<el-row :gutter="20">
								<el-col :span="12">
									<div style="padding-right:50px;">
										<div class="hand border-radius-10 hide padding cl" :class="active === 'bids' ? 'bg-blue-2 border-w-1 border-blue' : 'bg-gray-3 border-gray-3 border-w-1'" @click="change('bids')">
											<img src="/images/icon_gold.png" class="z" style="margin-top:15px;width: 20px;height: 20px;"/>
											<div class="margin-left z" :class="active === 'bids' ? 'blue-1' : 'dark-1'">
												<div class="f14">用会员次数解锁</div>
												<div class="f12">需花费1次 剩余:{{info.credit.lessCount === 999999 ? '无限' : info.credit.bids}}次</div>
											</div>
										</div>
										<div class="hand border-radius-10 hide padding margin-top cl" :class="active === 'gold' ? 'bg-blue-2 border-w-1 border-blue' : 'bg-gray-3 border-gray-3 border-w-1'" @click="change('gold')">
											<img src="/images/icon_gold.png" class="z" style="margin-top:15px;width: 20px;height: 20px;"/>
											<div class="margin-left z" :class="active === 'gold' ? 'blue-1' : 'dark-1'">
												<div class="f14">使用积分解锁</div>
												<div class="f12">需花费100积分 剩余:{{info.credit.gold}}积分</div>
											</div>
										</div>
										<el-button type="primary" class="margin-top" style="width: 100%;" @click="toUnLock">确认解锁</el-button>
									</div>
								</el-col>
								<el-col :span="12" class="text-center" style="border-left:1px solid #eee;">
									<div class="f14 dark-2">打开微信扫一扫 ，即可查看详细内容</div>
									<div class="margin-top-30" v-if="info.qr_code">
										<img :src="info.qr_code" width="166" class="border-w-1 border-dark-1"/>
									</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="20" class="f16 red" style="line-height: 24px;" v-if="info.bid_state === 1">
							<div class="f16 dark-2" style="line-height: 50px;" v-html="info.content"></div>
						</el-col>
					</el-row>
					<el-row class="margin-top-40" v-if="info.bid_state === 1 && info.files.length > 0">
						<el-col :span="4" class="f18 text-center dark-2" style="line-height: 24px;">附件：</el-col>
						<el-col :span="20">
							<div class="cl padding-bottom-30" v-if="info.files.length > 0">
								<template v-for="(item,index) in info.files">
									<div class="file-css margin-bottom" :key="index">
										<a class=" blue" :href="item.url.indexOf('http') >= 0 ? item.url : $config.assetsUrl + item.url" target="_blank">
											<img src="/images/file-icon.png" class="no-v-mid file"/>
											【{{ item.name }}】
											<img src="/images/download-icon.png" class="no-v-mid dowmload"/>
										</a>
									</div>
								</template>
							</div>
						</el-col>
					</el-row>
					<div class="text-center margin-top-20">
						<el-button icon="el-icon-circle-check" style="width: 200px;" type="danger" @click="toJoin" :disabled="info.is_baoming === 1" v-if="info.uid !== uid && info.can_baoming === 1">{{info.is_baoming === 1 ? '已报名' : '我要报名'}}</el-button>
					</div>
				</div>
			</div>
			<div class="y hide" style="width: 317px;">
				<zbtj class="bg-gray-4 padding-15 padding-bottom-30"></zbtj>
				<div class="margin-top-50">
					<banner akey="side_banner2" width="317px" height="247px"></banner>
				</div>
			</div>
		</div>
		<el-dialog title="提示" :visible.sync="showTuijian" width="298px" v-if="info.qr_code">
			<img :src="info.qr_code" width="258"/>
			<div class="text-center f16 dark-2 margin-top">微信扫一扫，推荐给好友</div>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="showToMini" width="298px" v-if="weixin_mini_app_qrcode">
			<div class="text-center f16 dark-2">你的当前{{active === 'bids' ? '解锁次数' : '积分'}}不够，无法解锁<br/>请扫码获取更多解锁机会</div>
			<img class="margin-top" :src="weixin_mini_app_qrcode" width="258"/>
		</el-dialog>
	</m-main-layout>
</template>

<script>
export default {
	name: "Detail",
	data() {
		return {
			info: null,
			id: '',
			bread: [{name:'标讯详情'}],
			time: null,
			time2: null,
			showDingyueQrcode: false,
			dingyueTime: null,
			showTuijian: false,
			active:'bids',
			count:1,

			weixin_mini_app_qrcode:'',
			showToMini:false,
			uid:''
		}
	},
	created() {
		this.id = this.$route.params.id ? this.$route.params.id : '';
		this.uid = this.$user.getUid();
	},
	mounted() {
		if (this.id) {
			this.getDetail();
		}

		this.getMiniQrCode();
	},
	methods: {
		toJoin(){
			let _this = this;
			if(!this.$user.getToken()){
				this.$refs.main.showLogin = true;
				return;
			}

			if(this.info.bid_state !== 1){
				this.$message.error('请先解锁标讯')
				return;
			}

			_this.$http.httpPost({
				url:'/AuthUserCompany/clearDetail',
				datas:null,
				success(res){
					if(res){
						if(res.state !== 1){
							_this.$alert('您的资料还未审核通过', '提示', {
								confirmButtonText: '确定',
								showClose:false,
								callback: action => {}
							});
							return;
						}else{
							_this.$router.push({path:'/bids/join',query:{id:_this.id}})
						}
					}else{
						_this.$alert('请先完善信息', '提示', {
							confirmButtonText: '确定',
							showClose:false,
							callback: action => {}
						});
						return;
					}
				}
			})
		},
		getMiniQrCode(){
			this.weixin_mini_app_qrcode = this.$utils.localGet('weixin_mini_app_qrcode');
		},
		toUnLock(){
			let _this = this;

			this.$confirm('是否确认解锁此标讯?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.httpPost({
					url:'/AuthOrder/addOrderByCredit',
					datas:{tid:_this.id,todo:'unlock_bids',type:_this.active,count:_this.count},
					success(res){
						_this.$message.success('解锁成功');
						_this.getDetail();
					},
					fail(err){
						_this.showToMini = true;
					}
				})
			}).catch(() => {

			});
		},
		change(e){
			let _active = e;

			console.log(_active);

			let _count = 0;
			if(_active === 'gold'){
				_count = 100
			}else if(_active === 'bids'){
				_count = 1;
			}
			this.active = _active;
			this.count = _count;
		},
		getDetail() {
			let _this = this;
			this.$http.httpPost({
				url: '/Bids/detailNew2',
				datas: {id: this.id},
				success(res) {
					_this.info = res;
					if (res.type === 'odd') {
						_this.bread = [{name: '散单平台', path: '/bids/odd'}, {name: res.title, path: '/'}];
					} else if (res.type === 'device') {
						_this.bread = [{name: '设备采购', path: '/bids/device'}, {name: res.title, path: '/'}];
					} else if (res.type === 'notice') {
						_this.bread = [{name: '招标公告', path: '/notice'}, {name: res.title, path: '/'}];
					} else if (res.type === 'storage') {
						_this.bread = [{name: '仓储供给', path: '/bids/storage'}, {name: res.title, path: '/'}];
					} else if (res.type === 'coldChain') {
						let subTitle = res.second_type == 'coldBid' ? '冷链招标' : '冷链需求'
						let subUrl = res.second_type == 'coldBid' ? '/coldChain/coldBid' : '/coldChain/coldNeed'
						_this.bread = [{name: '冷链专区'}, {name: subTitle, path: subUrl}, {name: res.title, path: '/'}];
					} else if (res.type === 'medicine'){
						_this.bread = [{name: '医药专区', path: '/bids/medicine'}, {name: res.title, path: '/'}];
					}

					if(res.bid_state === 2) {
					  _this.timeDown('checkState');
					}
					// if (res.bid_state === 0) {
					// 	_this.timeDown('checkLogin');
					// }

					// document.title = (res.province_name ? res.province_name : '') + '-' + res.title + '-' + _this.bread[0].name + '-中国物流招标网';

					// if(res.bid_state >= 1 && res.is_subscribe_dingyue === 0){
					//     _this.showDingyueQrcode = true;
					//
					//     _this.dingyueTime = setInterval(_this.checkDingyue, 3000)
					// }
				}
			})
		},
		checkDingyue() {
			let _this = this;
			this.$http.httpPost({
				url: '/User/checkDingState',
				datas: null,
				success(res) {
					if (res.is_subscribe_dingyue === 1) {
						clearInterval(_this.dingyueTime);
						_this.dingyueTime = null;
						_this.showDingyueQrcode = false;
					}
				}
			})
		},
		toLogin() {
			this.$refs.main.toLogin();
		},
		timeDown(type) {
			if (type === 'checkState') {
				this.time2 = setInterval(this.checkState, 3000)
			} else if (type === 'checkLogin') {
				this.time = setInterval(this.checkLogin, 3000)
			}
		},
		checkState() {
			let _this = this;
			this.$http.httpPost({
				url: '/Bids/check',
				datas: {id: this.id},
				success(res) {
					if (res.state === 1) {
						clearInterval(_this.time2);
						_this.time2 = null;
						_this.info.bid_state = res.state;

						_this.getDetail();
					}
				}
			})
		},
		checkLogin() {
			let _this = this;
			this.$http.httpPost({
				url: '/User/checkLogin',
				datas: {key: this.info.fuwu_qrcode_data.key},
				success(res) {
					if (res !== 'no') {
						clearInterval(_this.time);
						_this.time = null;

						_this.$user.setToken(res.token);
						_this.$user.setMobile(res.mobile);
						_this.$user.setUid(res.uid);
						// _this.$user.setUserName(res.username);

						window.location.reload();
					}
				}
			})
		}
	},
	destroyed() {
		if (this.time) {
			clearInterval(this.time);
			this.time = null;
		}

		if (this.time2) {
			clearInterval(this.time2);
			this.time2 = null;
		}

		if (this.dingyueTime) {
			clearInterval(this.dingyueTime);
			this.dingyueTime = null;
		}
	}
}
</script>
<style scoped>
table {
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
}

table th {
	border-bottom: 2px solid #ccc;
	background-color: #f1f1f1;
	padding: 3px 5px;
	min-height: 30px;
}

table td, table th {
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 3px 5px;
	min-height: 30px;
}

.file-css {
	padding: 3px 5px;
	display: inline-block;
	margin-right: 10px;
	background: #E8EDF5;
	border-radius: 4px;
}

.file-css img.file {
	position: relative;
	top: 4px;
	left: 5px;
}

.file-css img.dowmload {
	position: relative;
	top: 2px;
	left: -2px;
}
</style>

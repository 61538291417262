<template>
    <m-main-layout v-if="info">
        <bread :list="bread"></bread>
        <div class="cl margin-top-30">
            <div class="z hide" style="width: 860px;">
                <div class="padding-50 padding-bottom-20">
                    <div class="text-center f26">{{info.title}}</div>
                    <div class="padding-top-bottom-40 text-center f16 dark-2">
                        <div class="cl"></div>
                        <span class="f14"><img src="/images/icon_4.png" class="no-v-mid margin-right-5"/>状态:进行中</span>
                        <span class="f14 margin-left-20"><img src="/images/icon_5.png" class="no-v-mid margin-right-5"/>截止时间:{{info.is_end_long === 1 ? '长期有效' : info.end_time}}</span>
                        <span class="f14 margin-left-20"><img src="/images/icon_6.png" class="no-v-mid margin-right-5"/>行业:{{info.industry.name}}</span>
                        <span class="f14 margin-left-20"><img src="/images/icon_7.png" class="no-v-mid margin-right-5"/>地区:{{info.province_name}}</span>
                    </div>
                    <div class="line-2"></div>
                    <div class="cl"></div>
                </div>
                <log-button type="favorite" akey="bids" :tid="id" :is-one="info.is_favorite" class="margin-left-20" style="padding:5px 15px !important;" icon="el-icon-star-off" title="收藏"></log-button>
                <div class="padding-20 f16 dark-2" style="line-height: 50px;" v-html="info.content" v-if="info.bid_state === 1"></div>
                <el-alert
                    title="无限查看该信息"
                    type="error"
                    :description="info.bid_state === 0 ? '请先注册 / 登录' : (info.bid_state === 2 ? '支付后可查看该信息' : '成为VIP后可查看该信息')"
                    show-icon
                    :closable="false"
                    v-else>
                </el-alert>
            </div>
            <div class="y hide" style="width: 317px;">
                <zbtj class="bg-gray-4 padding-15 padding-bottom-30"></zbtj>
                <div class="margin-top-50"><img src="/images/banner6.jpg"/></div>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "Detail",
    data(){
        return{
            info:null,
            id:'',
            bread:[]
        }
    },
    created() {
        this.id = this.$route.params.id ? this.$route.params.id : '';
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/detail',
                datas:{id:this.id},
                success(res){
                    if(res.bid_state !== 1){
                        _this.$utils.error('无权查看');
                        // _this.$router.back();
                    }

                    _this.info = res;

                    if(res.type === 'odd'){
                        _this.bread = [{name:'散单平台',path:'/bids/odd'},{name:res.title,path:'/'}];
                    }else if(res.type === 'device'){
                        _this.bread = [{name:'设备采购',path:'/bids/device'},{name:res.title,path:'/'}];
                    }else if(res.type === 'notice'){
                        _this.bread = [{name:'招标公告',path:'/notice'},{name:res.title,path:'/'}];
                    }else if(res.type === 'storage'){
                        _this.bread = [{name:'仓储供给',path:'/bids/storage'},{name:res.title,path:'/'}];
                    }

                    document.title = (res.province_name ? res.province_name : '') + '-'+res.title+'-'+_this.bread[0].name+'-中国物流招标网';
                    // [地区][内容标题]-二级页标题-中国物流招标网
                }
            })
        },
    }
}
</script>

<template>
    <m-main-layout>
        <bread></bread>
        <div class="cl margin-top-15">
            <div class="z border-w-1 hide border-dark-6" style="width: 198px;height: 344px;padding:10px 20px !important;">
                <div class="red f18 f-sy" style="margin-top: 3px;">招标推荐</div>
                <div class="cl"></div>
                <zbscroll></zbscroll>
            </div>
            <div class="y">
                <banner akey="index_banner" width="995px" height="344px"></banner>
            </div>
        </div>
        <div class="cl">
            <div class="z hide" style="width: 860px;">
                <div class="padding-20" v-if="list.length > 0">
                    <template v-for="(item,index) in list">
                        <div class="cl padding-top-bottom-20">
                            <div class="z" style="width: 167px;height: 93px;">
                                <img :src="item.head_img ? (item.head_img.indexOf('http')>=0 ? item.head_img : $config.assetsUrl + item.head_img) : '/images/logo.png'" class="margin-top-20" width="167" height="46"/>
                            </div>
                            <div class="z hand margin-left-20" style="width: 633px;">
                                <div class="f16">
                                    <a :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}" @click="$router.push({path:'/bids/detail/'+item.id})">{{item.title}}</a>
                                </div>
                                <div class="cl hide margin-top-50" style="height: 21px;">
                                    <bid-tag :type="item.type"></bid-tag>
                                    <span class="z f12 dark-4-1 margin-left-20 margin-top">
                                        {{$moment(item.create_time).format('YYYY-MM-DD')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="line-1"></div>
                    </template>
                </div>
                <div class="padding-20 text-center f18 dark-3" style="padding-top:100px !important;" v-else>
                    没有找到相应的内容
                </div>
            </div>
            <div class="y bg-gray-4 hide padding-15" style="width: 317px;">
                <zbtj></zbtj>
                <div class="line-1"></div>
                <ccgq></ccgq>
            </div>
        </div>
        <div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "BidsSearch",
    data(){
        return{
            totalCount:0,
            list:[],
            searchForm:{
                title:'',
                page:1,
            },
            pageSize: 8,
        }
    },
    created() {
        this.searchForm.page = this.$route.query.page ? this.$route.query.page : 1;
        this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
    },
    mounted() {
        this.getList();
    },
    methods:{
        pageChange(e){
            this.searchForm.page = e;

            console.log(this.searchForm);

            this.$router.replace({query:{...this.searchForm}});
            // this.getList();
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listPage',
                datas:{...this.searchForm,pageSize:this.pageSize,state:1},
                success(res){
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            })
        }
    }
}
</script>

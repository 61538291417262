import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home';

import NoticeIndex from '../views/notice/Index';
import BidsIndex from '../views/bids/Index';
import BidsDetail from "@/views/bids/Detail";
import BidsDetail2 from "@/views/bids/Detail2";

import BidsSearch from '@/views/bids/Search';

import NewsIndex from '../views/news/Index';
import NewsDetail from "@/views/news/Detail";
import VipIndex from "@/views/vip/Index";
import About from "@/views/About";
import register from "@/views/site/register";
import ImportantIndex from "@/views/important/Index";
import Tuijian from "@/views/bids/Tuijian";
import changePass from "@/views/site/changePass";
import findPass from "@/views/site/findPass";
import findPass2 from "@/views/site/findPass2";

import notFound from '@/views/notFound';

import UserIndex from '@/views/user/index';
import UserChangePass from '@/views/user/changePass';
import UserPush from '@/views/user/push';
import UserPush2 from '@/views/user/push2';
import UserProfile from '@/views/user/profile';
import UserFavourite from '@/views/user/favourite';
import UserOrder from '@/views/user/order';
import UserMyBid from '@/views/user/mybid';
import UserBidList from '@/views/user/bidlist';
import CompanyList from '@/views/company/list';
import CompanyDetail from '@/views/company/detail';
import CompanyProfile from '../views/user/companyProfile'
import UserVisit from '../views/user/visit'
import UserBlog from '../views/user/blog'
import BidsJoin from '../views/bids/join'
import BaomingLog from '../views/user/baomingLog'
import ToubiaoLog from '../views/user/toubiaoLog'
import CompanyToubiao from '../views/company/ToubiaoDetail'
import UserRss from '../views/user/rss'
import Bbs from '../views/bbs'


const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location){
  return routerPush.call(this, location).catch(error=> error)
};

Vue.use(VueRouter);

  const routes = [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta:{
        title:'中国物流招标网-专注物流服务招标15年',
        content:{
          keywords:'物流,物流网,物流公司,物流查询,货运信息网,物流配货网,配货信息网,空车配货,车源,货源网,物流专线,物流信息网',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/important',
      name: 'ImportantIndex',
      component: ImportantIndex,
      meta:{
        bread:[{name:'重要通知',path:'/important'}]
      }
    },
    {
      path: '/changepass',
      name: 'changePass',
      component: changePass,
      meta:{
        bread:[{name:'修改密码',path:'/changepass'}]
      }
    },
    {
      path: '/findpass',
      name: 'findPass',
      component: findPass,
      meta:{
        bread:[{name:'忘记密码',path:'/findpass'}]
      }
    },
    {
      path: '/findpass2',
      name: 'findPass2',
      component: findPass2,
      meta:{
        bread:[{name:'忘记密码',path:'/findpass'},{name:'设置新密码',path:'/'}]
      }
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta:{
        bread:[{name:'关于我们',path:'/about'}]
      }
    },
    {
      path: '/tjbids',
      name: 'Tuijian',
      component: Tuijian,
      meta:{
        bread:[{name:'招标推荐',path:'/tjbids'}]
      }
    },
    {
      path: '/bids/notice',
      name: 'notice',
      component: NoticeIndex,
      meta:{
        bread:[{name:'招标公告',path:'/notice'}],
        title:'物流招标公告-中国物流招标网-最新物流招标',
        content:{
          keywords:'物流,物流网,物流公司,物流查询,货运信息网,物流配货网,配货信息网,空车配货,车源,货源网,物流专线,物流信息网',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/bids/odd',
      name: 'odd',
      component: BidsIndex,
      meta:{
        bread:[{name:'散单平台',path:'/bids/odd'}],
        title:'物流散单平台-中国物流招标网-物流配货，找货源',
        content:{
          keywords:'物流,物流网,物流公司,物流查询,货运信息网,物流配货网,配货信息网,空车配货,车源,货源网,物流专线,物流信息网',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/bids/cold',
      name: 'cold',
      component: NoticeIndex,
      meta:{
        // bread:[{name:'冷链专区'},{name:'冷链招标',path:'/coldChain/coldBid'}],
        bread:[{name:'冷链专区',path:'/coldChain/coldBid'}],
        title:'冷链招标-中国物流招标网-物流配货，找货源',
        content:{
          keywords:'物流,物流网,物流公司,物流查询,货运信息网,物流配货网,配货信息网,空车配货,车源,货源网,物流专线,物流信息网',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/coldChain/coldNeed',
      name: 'coldNeed',
      component: NoticeIndex,
      meta:{
        bread:[{name:'冷链专区'},{name:'冷链需求',path:'/coldChain/coldNeed'}],
        title:'冷链需求-中国物流招标网-物流配货，找货源',
        content:{
          keywords:'物流,物流网,物流公司,物流查询,货运信息网,物流配货网,配货信息网,空车配货,车源,货源网,物流专线,物流信息网',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/bids/storage',
      name: 'storage',
      component: BidsIndex,
      meta:{
        bread:[{name:'仓储供给',path:'/bids/storage'}],
        title:'物流仓储供给-中国物流招标网-冷库-仓储服务-仓储管理',
        content:{
          keywords:'仓储联盟,仓储物流,物流仓储,仓储服务,物流服务,物流平台,物流网,物流信息,物流信息网,物流仓库,找仓库,仓储,仓库,第三方仓储',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/bids/medicine',
      name: 'medicine',
      component: BidsIndex,
      meta:{
        bread:[{name:'医药专区',path:'/bids/medicine'}],
        title:'物流仓储供给-中国物流招标网-冷库-仓储服务-仓储管理',
        content:{
          keywords:'仓储联盟,仓储物流,物流仓储,仓储服务,物流服务,物流平台,物流网,物流信息,物流信息网,物流仓库,找仓库,仓储,仓库,第三方仓储',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/bids/device',
      name: 'device',
      component: BidsIndex,
      meta:{
        bread:[{name:'设备采购',path:'/bids/device'}],
        title:'物流设备采购-中国物流招标网',
        content:{
          keywords:'物流设备,仓储设备,物流设备厂家,物流设备展会,物流软件',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      },
    },
    {
      path: '/bids/detail2/:id',
      name: 'odd',
      component: BidsDetail2,
      meta:{
        bread:[{name:'散单平台',path:'/bids/odd'}],
      }
    },
    {
      path: '/bids/detail/:id',
      name: 'odd',
      component: BidsDetail,
      meta:{
        bread:[{name:'标讯详情'}],
      }
    },
    {
      path: '/bidssearch',
      name: 'odd',
      component: BidsSearch,
      meta:{
        bread:[{name:'项目搜索',path:'/bidssearch'}],
      }
    },
    {
      path: '/news/policy',
      name: 'policy',
      component: NewsIndex,
      meta:{
        bread:[{name:'政策法规',path:'/news/policy'}],
        title:'物流政策法规-中国物流招标网',
        content:{
          keywords:'物流信息,物流,物流政策,物流法规',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/news/logistics',
      name: 'logistics',
      component: NewsIndex,
      meta:{
        bread:[{name:'物流资讯',path:'/news/logistics'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/news/odd',
      name: 'odd',
      component: NewsIndex,
      meta:{
        bread:[{name:'散单平台',path:'/news/odd'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/news/detail/:id',
      name: 'policydetail',
      component: NewsDetail,
      meta:{
        bread:['政策法规','正文详情'],
      }
    },
    {
      path: '/logistics',
      name: 'logistics',
      component: NewsIndex,
      meta:{
        bread:['物流资讯'],
      }
    },
    {
      path: '/logistics/detail',
      name: 'logisticsdetail',
      component: NewsDetail,
      meta:{
        bread:['物流资讯','正文详情'],
      }
    },
    {
      path: '/vip',
      name: 'vip',
      component: VipIndex,
      meta:{
        bread:[{name:'会员频道',path:'/vip'}],
      }
    },
    {
      path: '/user',
      name: 'user',
      component: UserIndex,
      meta:{
        bread:[{name:'用户中心',path:'/user'}],
      }
    },
    {
      path: '/user/changepass',
      name: 'changepass',
      component: UserChangePass,
      meta:{
        bread:[{name:'修改密码',path:'/user/changepass'}],
      }
    },
    // {
    //   path: '/user/push',
    //   name: 'userpush',
    //   component: UserPush,
    //   meta:{
    //     bread:[{name:'发布',path:'/user/push'}],
    //   }
    // },
    {
      path: '/push2',
      name: 'userpush2',
      component: UserPush2,
      meta:{
        bread:[{name:'发布',path:'/push2'}],
      }
    },
    {
      path: '/user/profile',
      name: 'userprofile',
      component: UserProfile,
      meta:{
        bread:[{name:'修改资料',path:'/user/profile'}],
      }
    },
    {
      path: '/user/favourite',
      name: 'userfavourite',
      component: UserFavourite,
      meta:{
        bread:[{name:'我的收藏',path:'/user/favourite'}],
      }
    },
    {
      path: '/user/order',
      name: 'userorder',
      component: UserOrder,
      meta:{
        bread:[{name:'历史订单',path:'/user/order'}],
      }
    },
    {
      path: '/user/mybid',
      name: 'usermybid',
      component: UserMyBid,
      meta:{
        bread:[{name:'我的招标',path:'/user/mybid'}],
      }
    },
    {
      path: '/user/bidlist',
      name: 'usermybid',
      component: UserBidList,
      meta:{
        bread:[{name:'内容管理',path:'/user/bidlist'}],
      }
    },
    {
      path: '/company/list/:type',
      name: 'list',
      component: CompanyList,
      meta:{
        bread:[{name:'找物流',path:'/company/list'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/company/detail/:uid',
      name: 'detail',
      component: CompanyDetail,
      meta:{
        bread:[{name:'找物流',path:'/company/detail'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/company-profile',
      name: 'detail',
      component: CompanyProfile,
      meta:{
        bread:[{name:'找物流',path:'/user'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/visit',
      name: 'detail',
      component: UserVisit,
      meta:{
        bread:[{name:'访客记录',path:'/user/visit'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/blog',
      name: 'detail',
      component: UserBlog,
      meta:{
        bread:[{name:'我的动态',path:'/user/blog'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/bids/join',
      name: 'detail',
      component: BidsJoin,
      meta:{
        bread:[{name:'标讯报名',path:'/user/blog'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/baoming',
      name: 'detail',
      component: BaomingLog,
      meta:{
        bread:[{name:'标讯报名',path:'/user/blog'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/toubiao',
      name: 'detail',
      component: ToubiaoLog,
      meta:{
        bread:[{name:'标讯报名',path:'/user/blog'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/company/toubiao',
      name: 'detail',
      component: CompanyToubiao,
      meta:{
        bread:[{name:'标讯报名',path:'/user/blog'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/user/rss',
      name: 'detail',
      component: UserRss,
      meta:{
        bread:[{name:'订阅设置',path:'/user/rss'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/bbs',
      name: 'detail',
      component: Bbs,
      meta:{
        bread:[{name:'散单需求',path:'/bbs'}],
        title:'物流行业资讯-中国物流招标网',
        content:{
          keywords:'物流资讯,行业资讯,国内物流新闻,国际物流新闻,理政资讯,交通资讯,信息化资讯,采购资讯,仓储与配送,经贸资讯,流通资讯,铁路资讯,内河资讯,供应链管理,物流行业分析,经济数据公布,经济形势分析,路况信息',
          description:'中国物流招标网始终坚持以企业需求为核心，不段提升信息数量和质量，为企业寻找上下游合作商机搭建合作共赢的商务平台。专注物流服务招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时、准确的招标信息查询和推送服务。',
        },
      }
    },
    {
      path: '/404',
      name: 'notFound',
      component: notFound,
      meta:{
        bread:[{name:'页面未找到',path:'/404'}],
      }
    },
    {
      path: '*',
      redirect:'/404'
    },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router

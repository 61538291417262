<template>
	<user-layout>
		<user-menu path="/user">
			<el-tabs v-model="searchForm.to" @tab-click="changeTo">
				<el-tab-pane label="谁看过我" name="target" class="none"></el-tab-pane>
				<el-tab-pane label="我看过谁" name="me" class="none"></el-tab-pane>
			</el-tabs>
			<div class="bg-light margin-bottom-20 box-shadow-5 border-radius-10 padding-20 hand" v-for="(item,index) in list" @click="toUserPage(item)">
				<el-avatar class="vm" :size="40" :src="searchForm.to === 'target' ? $utils.formatImagePath(item.company.logo) : $utils.formatImagePath(item.target_company.logo)"></el-avatar>
				<div class="inline-block margin-left f16" v-if="searchForm.to === 'me'">{{item.target_company.company ? item.target_company.company : '无昵称'}}</div>
				<div class="inline-block margin-left f16" v-if="searchForm.to === 'target'">{{item.company.company ? item.company.company : '-'}}</div>
				<div class="inline-block y" v-if="searchForm.to === 'me'">
					{{item.target_company && item.target_company.province && item.target_company.province.length > 0 ? item.target_company.province[0].name : ''}}
				</div>
				<div class="inline-block y" v-if="searchForm.to === 'target'">
					{{item.company && item.company.province && item.company.province.length > 0 ? item.company.province[0].name : ''}}
				</div>
				<div class="margin-top f14 dark-3">最近访问时间：{{item.update_time}}</div>
			</div>
			<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
				<el-pagination
					background
					@current-change="pageChange"
					:current-page="searchForm.page"
					:page-size="searchForm.pageSize"
					layout="prev, pager, next, jumper"
					:total="totalCount">
				</el-pagination>
			</div>
		</user-menu>
	</user-layout>
</template>

<script>
export default {
	name: "visit",
	data() {
		return {
			searchForm:{
				to: 'me',
				type: 'view',
				orderBy: 'update_time',
				sortBy: 'desc',
				page: 1,
				pageSize: 10
			},
			totalCount:0,
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		changeTo(tab, event){
			this.searchForm.page = 1;
			this.getList();
		},
		toUserPage(item){
			let _target = null;
			if(this.searchForm.to === 'me'){
				_target = item.target_company;
			}else{
				_target = item.company;
			}
			
			if(_target && _target.uid){
				this.$router.push({path:'/company/detail/'+_target.uid})
			}
		},
		getList(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUserLog/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>

<template>
    <user-layout>
        <user-menu path="/user/profile">
            <div class="bg-light border-w-1 border-dark-7 border-radius-10">
                <div class="margin-auto margin-top-50" style="width: 550px;">
                    <div class="hide cl" style="height: 50px;line-height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">手机号</div>
                        <div class="border-w-1 border-dark-4 bg-light border-radius-10 padding-left-right f16" style="margin-left: 110px;height:50px;line-height: 50px;">
                            {{mobile}}
                        </div>
                    </div>
                    <div class="margin-top-20 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">电子邮箱</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.email" class="block border-w-1 border-dark-4 border-radius-10 f16" style="height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入您的电子邮箱"/>
                        </div>
                    </div>
                    <div class="margin-top-20 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">公司名称</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.company" class="block border-w-1 border-dark-4 border-radius-10 f16" style="height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入您的公司名称"/>
                        </div>
                    </div>
                    <div class="margin-top-20 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">联系人</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.name" class="block border-w-1 border-dark-4 border-radius-10 f16" style="height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入联系人姓名"/>
                        </div>
                    </div>
                    <div class="margin-top-20 hide cl" style="height: 50px;width: 100%;">
                        <div class="z dark-2 text-justify" style="line-height: 50px;width: 90px;">职位</div>
                        <div class="" style="margin-left: 110px;">
                            <input v-model="formData.job" class="block border-w-1 border-dark-4 border-radius-10 f16" style="height: 50px;line-height: 50px;padding:0 15px;" placeholder="请输入所在部门及职位"/>
                        </div>
                    </div>
                    <div class="margin-top-50 padding-bottom-30 text-center">
                        <el-button type="primary" @click="submit" class="padding-left-right-40">提 交</el-button>
                    </div>
                </div>
            </div>
        </user-menu>
    </user-layout>
</template>

<script>
export default {
    name: "profile",
    data(){
        return{
            formData:{
                email:'',
                company:'',
                name:'',
                job:''
            },
            mobile:'',
        }
    },
    mounted() {
        this.getProfile();
    },
    methods:{
        submit(){
            let _this = this;

            this.$http.httpPost({
                url: '/AuthUser/editProfile',
                datas: this.formData,
                success: (res) => {
                    if(res === 'ok'){
                        _this.$message.success('修改成功');
                    }
                }
            });
        },
        getProfile(){
            let _this = this;
            this.$http.httpPost({
                url: '/AuthUser/getProfile',
                datas: null,
                success: (res) => {
                    _this.formData.name = res.name;
                    _this.mobile = res.mobile;
                    _this.formData.company = res.company;
                    _this.formData.job = res.job;
                    _this.formData.email = res.email;
                }
            });
        }
    }
}
</script>

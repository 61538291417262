<template>
	<user-layout>
		<user-menu path="/user">
			<div v-if="profile">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="bg-light border-radius-10 hide hand" style="padding:20px 15px;" @click="toMySite">
							<i class="iconfont icon-image-text-fill vm" style="color: #447CFB;font-size: 50px;"></i>
							<span class="f-bth f24 vm margin-left">企业主页</span>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="bg-light border-radius-10 hide hand" style="padding:20px 15px;" @click="toToubiaoBaoming">
							<i class="iconfont icon-integral-fill vm" style="color: #2BD26C;font-size: 50px;"></i>
							<span class="f-bth f24 vm margin-left">投标报名</span>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="bg-light border-radius-10 hide hand" style="padding:20px 15px;" @click="$router.push({path:'/user/visit'})">
							<i class="iconfont icon-template-fill vm" style="color: #F5AE11;font-size: 50px;"></i>
							<span class="f-bth f24 vm margin-left">访客记录</span>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="bg-light border-radius-10 hide hand" style="padding:20px 15px;" @click="$router.push({path:'/user/blog'})">
							<i class="iconfont icon-product vm" style="color: #FB4F60;font-size: 50px;"></i>
							<span class="f-bth f24 vm margin-left">我的动态</span>
						</div>
					</el-col>
				</el-row>
			</div>
			<img src="/images/vip_banner.jpg" class="margin-top-bottom hand" style="width: 100%" @click="showVipDialog = true"/>
			<div v-if="profile">
				<el-row :gutter="20">
					<el-col :span="12">
						<m-card icon="iconfont icon-all-fill blue-2" class="hide" title="最新标讯" title-class="f-bth vm" style="height: 520px;" :is-link="false">
							<template v-if="profile.user_type === 'hz'">
								<a :href="'/bids/detail/'+item.id" target="_blank" class="block margin-top-20 cl" v-for="(item,index) in bids_list">
									<div class="z text-line-1" style="width: 320px;">{{item.title}}</div>
									<div class="y dark-3">{{$moment(item.create_time).format('YYYY.MM.DD')}}</div>
								</a>
							</template>
							<template v-if="profile.user_type === 'gys'">
								<a :href="'/bids/detail/'+item.bids.id" target="_blank" class="block margin-top-20 cl" v-for="(item,index) in baoming_list">
									<div class="z text-line-1" style="width: 320px;">{{item.bids.title}}</div>
									<div class="y dark-3">{{$moment(item.create_time).format('YYYY.MM.DD')}}</div>
								</a>
							</template>
						</m-card>
					</el-col>
					<el-col :span="12">
						<m-card icon="iconfont icon-all-fill blue-2" class="hide" title="最新动态" title-class="f-bth vm" style="height: 520px;" :is-link="false">
							<div class="block margin-top-20 cl" v-for="(item,index) in blog_list">
								<div class="z text-line-1" style="width: 320px;">{{item.slug.content}}</div>
								<div class="y dark-3">{{$moment(item.create_time).format('YYYY.MM.DD')}}</div>
							</div>
						</m-card>
					</el-col>
				</el-row>
			</div>
		</user-menu>
		<vip-dialog :show.sync="showVipDialog"></vip-dialog>
	</user-layout>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			vip_state: 0,
			vip_end_time: 0,
			bids_list: [],
			baoming_list:[],
			blog_list:[],
			order_count: 0,
			bids_count: 0,
			favorite_count: 0,
			profile:null,
			showVipDialog:false
		}
	},
	mounted() {
		this.getProfile();
		this.getTotal();
		this.getBlogList();
	},
	methods: {
		toToubiaoBaoming(){
			if(this.profile){
				if(this.profile.user_type === 'gys'){
					this.$router.push({path:'/user/baoming'})
				}else if(this.profile.user_type === 'hz'){
					this.$router.push({path:'/user/toubiao'})
				}
			}
		},
		toMySite(){
			if(!this.profile || !this.profile.company){
				this.$confirm('你还没有完善信息！', '提示', {
					confirmButtonText: '立即完善',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({path:'/user/company-profile'})
				}).catch(() => {});
				return;
			}
			
			let _uid = this.$user.getUid();
			this.$router.push({path:'/company/detail/'+_uid});
		},
		getProfile() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUser/getProfile',
				datas: null,
				success: (res) => {
					_this.profile = res;
					_this.vip_state = res.vip_state;
					_this.vip_end_time = res.vip_end_time;
					
					if(res.user_type === 'hz'){
						_this.getBidsList();
					}else{
						_this.getBaomingList();
					}
				}
			});
		},
		getBaomingList() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUserToubiaoLog/listPage',
				datas: {page: 1, pageSize: 10},
				success: (res) => {
					_this.baoming_list = res.list;
				}
			});
		},
		getBlogList() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthBlogLog/listPage',
				datas: {page: 1, pageSize: 10},
				success: (res) => {
					_this.blog_list = res.list;
				}
			});
		},
		getBidsList() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthBids/listPage',
				datas: {page: 1, pageSize: 10, state: 1},
				success: (res) => {
					_this.bids_list = res.list;
				}
			});
		},
		getTotal() {
			let _this = this;
			//
			this.$http.httpPost({
				url: '/AuthUserTotal/detail',
				datas: null,
				success: (res) => {
					_this.order_count = res.order_count;
					_this.bids_count = res.bids_count;
					_this.favorite_count = res.favorite_count;
				}
			});
		}
	}
}
</script>

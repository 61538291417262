<template>
	<m-main-layout>
		<bread :list="[{name:'注册'}]"></bread>
		<div class="rel hide margin-top" style="width: 100%;height: 134px;">
			<img class="abs" src="/images/profile_bg.jpg" style="width: 100%;height: 134px;z-index: 1;"/>
			<div class="rel f-bth light f30 padding-left-40" style="line-height: 134px;z-index:2;">
				注册
			</div>
		</div>
		<div class="wrap margin-top-40 margin-bottom-40" style="width: 700px !important;">
			<div class="box-shadow border-radius-5 hide padding-15">
				<el-form :model="formData" :rules="rules" label-width="100px" ref="form">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="身份" prop="user_type">
								<el-radio-group v-model="formData.user_type">
									<el-radio label="hz">招标方</el-radio>
									<el-radio label="gys">投标方</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="24" v-if="formData.user_type === 'gys'">
							<el-form-item label="供应商类型" prop="gys_type" :rules="[{required:true,message:'请选择供应商类型'}]">
								<el-radio-group v-model="formData.gys_type">
									<el-radio label="wuliu">物流</el-radio>
									<el-radio label="shebei">设备</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="姓名" prop="name">
								<el-input v-model="formData.name" maxlength="10" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职务" prop="job">
								<el-input v-model="formData.job" maxlength="10" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="公司" prop="company">
								<el-input v-model="formData.company" maxlength="30" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="邮箱" prop="email">
								<el-input v-model="formData.email" maxlength="30" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="mobile">
								<el-input v-model="formData.mobile" maxlength="13" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="formData.password" type="password" maxlength="20" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="确认密码" prop="rePassword">
								<el-input v-model="formData.rePassword" type="password" maxlength="20" show-word-limit/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="图形验证码" prop="verifyCode">
								<el-input v-model="formData.verifyCode" style="width: 115px;"/>
								<img :src="formData.verifyCodeImg" class="y hand" alt="点击刷新验证码" style="vertical-align: middle; margin-left:10px;width: 100px;" @click="getCode" v-if="formData.verifyCodeImg"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机验证码" prop="code">
								<el-input v-model="formData.code" maxlength="6" show-word-limit style="width: 115px;"/>
								<el-button type="warning" @click="sendCode" :disabled="disSms" style="width: 100px;padding:12px 0 !important;text-align: center !important;margin-left: 10px;">
									{{ codeText }}
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-checkbox v-model="checked">点击注册表示您已阅读并同意</el-checkbox>
				<span class="blue b f14 hand" @click="showService = true">《中国物流招标网服务协议》</span><span class="blue b f14 hand" @click="showSecret = true">《中国物流招标网隐私政策》</span>
			</div>
			<div class="text-center margin-top-20">
				<el-button type="primary" class="block" @click="reg">提交注册</el-button>
			</div>
		</div>
		<el-dialog title="用户协议" :visible.sync="showService">
			<div class="f16" style="max-height: 600px;overflow-y: auto;" v-html="serviceTxt"></div>
		</el-dialog>
		<el-dialog title="隐私政策" :visible.sync="showSecret">
			<div class="f16" style="max-height: 600px;overflow-y: auto;" v-html="secretTxt"></div>
		</el-dialog>
	</m-main-layout>
</template>

<script>
export default {
	name: "register",
	data() {
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.formData.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			formData: {
				user_type: '',
				gys_type: '',
				mobile: '',
				email: '',
				password: '',
				rePassword: '',
				code: '',
				company: '',
				job: '',
				name: '',
				verifyCode:'',
				verifyCodeImg: '',
				verifyCodeTime: '',
				verifyCodeHash: ''
			},
			codeText: '发送验证码',
			disSms: false,
			checked: false,
			time: 120,
			timeId: null,
			serviceTxt: '',
			showService: false,
			showSecret: false,
			secretTxt: '',

			rules: {
				name:[{required: true,message:'请输入姓名'}],
				job:[{required: true,message:'请输入职务'}],
				email:[{required: true,message:'请输入邮箱'},{pattern:this.$regexp.email,message: '请输入正确的邮箱'}],
				user_type:[{required: true,message:'请选择身份'}],
				mobile:[{required: true,message:'请输入手机号'},{pattern:this.$regexp.mobile,message: '请输入正确的手机号'}],
				code:[{required: true,message:'请输入手机验证码'}],
				verifyCode:[{required: true,message:'请输入验证码'}],
				password:[{required: true,message:'请输入密码'}],
				rePassword:[{required: true,message:'请输入验证码'},{validator: validatePass2}],
				company:[{required: true,message:'请输入公司名称'}],
			}
		}
	},
	mounted() {
		// this.sceenWidth = window.screen.width
		// console.log(this.sceenWidth)
		this.getCode();
		this.getService();
		this.getSecret();
		// this.getMiniQrCode();
	},
	methods: {
		getMiniQrCode() {
			this.weixin_mini_app_qrcode = this.$utils.localGet('weixin_mini_app_qrcode');
		},
		reg() {
			let _this = this;

			// if (!this.formData.mobile.trim()) {
			// 	_this.$utils.error('请填写手机号');
			// 	return;
			// }
			//
			// if (!this.formData.email.trim()) {
			// 	_this.$utils.error('请填写电子邮箱');
			// 	return;
			// }
			//
			// if (!this.formData.password.trim()) {
			// 	_this.$utils.error('请填写密码');
			// 	return;
			// }
			//
			// if (!this.formData.rePassword.trim()) {
			// 	_this.$utils.error('请填写确认密码');
			// 	return;
			// }
			//
			// if (!this.formData.company.trim()) {
			// 	_this.$utils.error('请输入您的公司名称');
			// 	return;
			// }
			//
			// if (!this.formData.job.trim()) {
			// 	_this.$utils.error('请输入所在部门及职位');
			// 	return;
			// }
			//
			// if (!this.formData.code.trim()) {
			// 	_this.$utils.error('请填写验证码');
			// 	return;
			// }

			if(!this.checked){
				this.$utils.error('请先勾选并同意《服务协议》和《隐私政策》');
				return;
			}

			this.$refs['form'].validate((valid) => {
				if (valid) {
					this.$http.httpPost({
						url: '/User/register',
						datas: this.formData,
						success(res) {
							_this.$user.setToken(res.token);
							_this.$user.setMobile(res.mobile);
							_this.$user.setUid(res.uid);
							_this.$user.setUserName(res.username);

							_this.$user.setAvatar(res.avatar);
							_this.$user.setUserType(res.user_type);
							_this.$user.setGysType(res.gys_type);
							_this.$user.setNickname(res.nickname);

							_this.$utils.success('注册成功');

							_this.$router.replace({path: '/'});
						}
					})
				} else {
					return false;
				}
			});
		},
		getCode() {
			let _this = this;
			this.$http.httpPost({
				url: '/VerifyCode',
				datas: null,
				success: (res) => {
					_this.formData.verifyCodeImg = res.verifyCodeImg;
					_this.formData.verifyCodeTime = res.verifyCodeTime;
					_this.formData.verifyCodeHash = res.verifyCodeHash;
					_this.formData.verifyCode = '';
				}
			});
		},
		checkCode(cb) {
			let _this = this;
			// console.log(document.cookie);
			this.$http.httpPost({
				url: '/VerifyCode/checkVerifyCode',
				datas: this.formData,
				success: (res) => {
					cb && cb()
				},
				fail(res){
					_this.$message.error(res.msg);
					_this.getCode();
				}
			});
		},
		getService() {
			let _this = this;
			this.$http.httpPost({
				url: '/SystemSetting/getSystem',
				datas: {skey: 'service'},
				success: (res) => {
					_this.serviceTxt = res.service;
				}
			});
		},
		getSecret() {
			let _this = this;
			this.$http.httpPost({
				url: '/SystemSetting/getSystem',
				datas: {skey: 'secret'},
				success: (res) => {
					_this.secretTxt = res.secret;
				}
			});
		},
		sendCode() {
			let _this = this;

			if(!this.formData.mobile.trim()){
				this.$message.error('请输入手机号');
				return;
			}

			if(!this.formData.verifyCode.trim()){
				this.$message.error('请输入图形验证码');
				return;
			}

			_this.$http.httpPost({
				url: '/User/sendSms',
				datas: this.formData,
				success: (res) => {
					if (res === 'ok') {
						_this.disSms = true;
						_this.startTime();
						_this.$utils.success('验证码发送成功');
					} else {
						_this.disSms = true;
						_this.startTime();
						_this.$utils.warning('请稍后重试');
					}
				},
				fail: (res) => {
					if(res.code === -2){
						_this.getCode();
					}
					_this.$message.error(res.msg);
				}
			});
		},
		timeDown() {
			if (this.time > 0) {
				this.time -= 1;
				this.codeText = this.time + 's 重新获取'
			} else {
				this.disSms = false;
				clearInterval(this.timeId);
				this.timeId = null;
				this.codeText = '发送验证码'
			}
		},
		startTime() {
			this.time = 120;
			this.timeId = setInterval(this.timeDown, 1000);
		}
	},
	destroyed() {
		if(this.timeId){
			clearInterval(this.timeId);
			this.timeId = null;
		}
	}
}
</script>
<style>

.page-main {
	position: relative;
	height: 100%
}

.padding-default {
	padding: 35px 40px 0 40px;
}

.padding-1920 {
	padding: 90px !important;
}

.padding-1680 {
	padding: 100px 40px 0 40px !important;
}

.padding-1440 {
	padding: 20px 40px 0 40px !important;
}

.padding-1366 {
	padding: 0px 40px 0 40px !important;
}

.el-row {
	height: 100%
}

.el-col {
	height: 100%;
}

img.bg {
	position: absolute;
	right: 20px;
	width: auto !important;
	height: 100%;
}

.h70 {
	height: 72px;
}

.btn-small {
	padding: 8px 12px !important;
}

</style>

<template>
	<m-main-layout>
		<bread :list="bread"></bread>
		<div v-if="info">
			<detail-huozhu :info="info" v-if="info.user_type === 'hz'"></detail-huozhu>
			<detail-wuliu :info="info" v-if="info.user_type === 'gys' && info.gys_type === 'wuliu'"></detail-wuliu>
			<detail-shebei :info="info" v-if="info.user_type === 'gys' && info.gys_type === 'shebei'"></detail-shebei>
		</div>
	</m-main-layout>
</template>

<script>
import DetailHuozhu from './detailTemplate/huozhu'
import DetailWuliu from './detailTemplate/wuliu'
import DetailShebei from './detailTemplate/shebei'
export default {
	name: "detail",
	components: {DetailHuozhu, DetailWuliu, DetailShebei},
	data() {
		return {
			uid:'',
			info:null,
			bread:[]
		}
	},
	created() {
		this.uid = this.$route.params.uid ? this.$route.params.uid : '';
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail(){
			let _this = this;
			if(!this.uid){
				this.$alert('信息不存在或参数错误', '提示', {
					showClose:false,
					confirmButtonText: '确定',
					callback: action => {
						window.close();
					}
				});
				return;
			}
			
			this.$http.httpPost({
				url:'/UserCompany/detail',
				datas:{id:this.uid},
				success(res){
					_this.bread = [];
					_this.info = res;
					
					if(res.user_type === 'hz'){
						_this.bread.push({name:'找货主',path:'/company/list/hz'})
					}else if(res.user_type === 'gys'){
						if(res.gys_type === 'wuliu'){
							_this.bread.push({name:'找物流',path:'/company/list/wuliu'})
						}else if(res.gys_type === 'shebei'){
							_this.bread.push({name:'找设备',path:'/company/list/shebei'})
						}
					}
					
					_this.bread.push({name:'企业信息'})
					
					// console.log(_this.bread);
				}
			})
		}
	}
}
</script>

<template>
    <div>
        <div class="cl">
            <img src="/images/icon_1.png" class="z" width="20" style="margin-top:3px;"/>
            <div class="z f18 margin-left dark-2 f-lq">仓库供求</div>
        </div>
        <div class="cl"></div>
        <div class="margin-l-r-30 margin-top-13">
            <div v-for="(item,index) in list" style="line-height: 50px;"><a @click="toDetail(item.id)" class="f16 text-line-1">{{item.title}}</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ccgq",
    props:{
        count:{
            type:Number,
            default:8
        }
    },
    data(){
        return{
            list:[]
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listAll',
                datas:{type:'storage',state:1,orderBy:'sort,create_time',sortBy:'desc',page:1,limit:this.count},
                success(res){
                    _this.list = res
                }
            })
        },
        toDetail(id){
            window.open('/bids/detail/'+id,'_blank')
        },
    }
}
</script>

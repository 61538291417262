<template>
	<user-layout>
		<user-menu path="/user">
			<div class="bg-light border-radius-10 margin-bottom-15 padding-15" v-for="(item,index) in list">
				<div class="flex flex-row flex-ai">
					<el-avatar class="vm" :size="40" :src="$utils.formatImagePath(item.company.logo)"></el-avatar>
					<div class="inline-block margin-left">
						<div class="f16">{{item.company && item.company.company ? item.company.company : (item.user.company ? item.user.company : '')}}</div>
						<div class="f12 dark-3">{{item.create_time}}</div>
					</div>
				</div>
				<template v-if="item.type === 'blog'">
					<div class="f16 dark-0 margin-top" v-html="item.slug.content" style="white-space: pre-wrap">{{item.slug.content}}</div>
					<image-pre-view :list="item.files" class="margin-top-bottom" v-if="item.files.length > 0"></image-pre-view>
<!--					<goodView outClass="flex flex-row flex-jc-between mg-t-20" hasapi="{{ false }}" total="{{item.total}}" tid="{{item.tid}}" akey="blog" target_uid="{{item.uid}}" canShare="{{false}}" delUrl="/AuthBlogLog/del" bind:onDel="onDel" bind:onShare="onShare" wx:if="{{item.type === 'blog'}}"></goodView>-->
				</template>
			</div>
		</user-menu>
	</user-layout>
</template>

<script>
export default {
	name: "blog",
	data() {
		return {
			searchForm:{
				page: 1,
				pageSize: 10
			},
			totalCount:0,
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList(){
			let _this = this;
			this.$http.httpPost({
				url:'/AuthBlogLog/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>

<template>
    <div class="list-wrap hide" style="margin-top: 3px;height: 275px;">
        <vue-seamless-scroll :class-option="optionHover" :data="list" class="seamless-warp">
            <div class="cl hide margin-top-11" style="height: 44px;" v-for="(item,index) in list">
                <img src="/images/clock.png" class="z" style="margin-top: 3px;"/>
                <div class="y" style="width: 135px;">
                    <div class="f12 dark-4-1" style="line-height: 20px;">{{$moment(item.create_time).format('YYYY-M-D')}}</div>
                    <a class="f14 text-line-1 dark-2 hand" style="line-height: 24px;" @click="toDetail(item.id)">{{item.title}}</a>
                </div>
            </div>
        </vue-seamless-scroll>
    </div>
</template>

<script>
export default {
    name: "zbscroll",
    data(){
        return{
            list:[]
        }
    },
    props:{
        count:{
            type:Number,
            default:5
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listAll',
                datas:{is_tuijian:1,orderBy:'sort desc,create_time',sortBy:'desc',page:1,limit:this.count,state:1},
                success(res){
                    _this.list = res;
                }
            })
        },
        toDetail(id){
            window.open('/bids/detail/'+id,'_blank')
        },
    },
    computed: {
        optionHover () {
            return {
                hoverStop: true,
                step:0.5
            }
        }
    },
}
</script>

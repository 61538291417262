<template>
	<div>
		<img src="/images/banner11.jpg" style="width: 100%;"/>
		<div style="width: 800px;margin:50px auto 100px auto;">
			<el-form :model="formData" label-width="100px" :rules="rules" ref="form" v-if="token && companyInfo && companyInfo.user_type === 'hz' && companyInfo.state === 1">
				<el-row :gutter="20">
<!--					<el-col :span="12">-->
<!--						<el-form-item label="发布板块" prop="type">-->
<!--							<type-select v-model="formData.type" :options="[{name:'招标公告',id:'notice'},{name:'冷链招标',id:'coldChain'},{name:'设备采购',id:'device'},{name:'医药专区',id:'medicine'}]" @change="onChangeType"></type-select>-->
<!--						</el-form-item>-->
<!--					</el-col>-->
					<el-col :span="24">
						<el-form-item label="标讯类型" prop="gys_type">
							<el-radio-group v-model="formData.gys_type">
								<el-radio label="wuliu">物流</el-radio>
								<el-radio label="shebei">设备采购</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="标讯名称" prop="title">
							<el-input v-model="formData.title" maxlength="40" show-word-limit></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="发布时间" prop="push_time">
							<time-picker v-model="formData.push_time" disabled></time-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="截止时间" prop="end_time">
							<time-picker v-model="formData.end_time"></time-picker>
<!--							<push-time v-model="formData.end_time"></push-time>-->
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="行业类型" prop="type_industry_id">
							<type-select :can-add="false" v-model="formData.type_industry_id" url="/Type/listAll" :data="{akey:'industry',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="服务类型" prop="type_fuwu_ids">
							<type-select multiple :can-add="false" v-model="formData.type_fuwu_ids" url="/Type/listAll" :data="{akey:'fuwu',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="区域" prop="province_codes">
							<area-select :data="{pid:1}" multiple v-model="formData.province_codes" style="width: 100%;"></area-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="标的金额" prop="bids_price">
							<el-input v-model="formData.bids_price" maxlength="50" show-word-limit>
								<template slot="append">万元</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="货运总量" prop="hyzl">
							<el-input v-model="formData.hyzl" maxlength="50" show-word-limit style="width: 550px;"></el-input>
							<el-form-item prop="type_dw_id" class="inline-block">
								<type-select :can-add="false" v-model="formData.type_dw_id" url="/Type/listAll" :data="{akey:'dw',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" class="margin-left" style="width: 140px;"></type-select>
							</el-form-item>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系人" prop="name">
							<el-input v-model="formData.name" maxlength="10" show-word-limit></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系方式" prop="phone">
							<el-input v-model="formData.phone" maxlength="15" show-word-limit></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="所需资质" prop="type_tbzz_ids">
							<type-select multiple :can-add="true" v-model="formData.type_tbzz_ids" add-url="/AuthType/add" url="/AuthType/listAll" :data="{akey:'tbzz',delete_time:0,state:1,orderBy:'sort',sortBy:'asc'}" style="width: 100%;"></type-select>
<!--							<el-select v-model="formData.type_tbzz_ids" multiple placeholder="请选择" style="width: 100%;">-->
<!--								<el-option-->
<!--									v-for="item in zzList"-->
<!--									:key="item.id"-->
<!--									:label="item.name"-->
<!--									:value="item.id">-->
<!--								</el-option>-->
<!--							</el-select>-->
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="项目内容" prop="phone" class="no-line-height">
							<vue-ueditor-wrap v-model="formData.content" :config="config" editor-id="editor-demo-01"></vue-ueditor-wrap>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="项目附件" prop="files_ids">
							<file-upload v-model="formData.files_ids" :files="formData['files']"></file-upload>
						</el-form-item>
					</el-col>
					<div class="margin-top-50 padding-bottom-30 text-center">
						<el-button type="primary" @click="submit" class="padding-left-right-40">提 交</el-button>
					</div>
				</el-row>
			</el-form>
		</div>
		<el-dialog
			title=""
			custom-class="border-radius-20"
			:visible.sync="showLogin"
			width="530px"
			center>
			<div class="text-center">
				<img src="/images/logo.png" width="200"/>
			</div>
			<div class="margin-l-r-50 margin-top-50">
				<div class="box-shadow bg-light cl border-radius-5 padding">
					<img src="/images/icon_username.png" class="z"/>
					<input type="text" class="margin-left" v-model="loginform.mobile" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入手机号"/>
				</div>
				<div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">
					<img src="/images/icon_password.png" class="z"/>
					<input type="password" class="margin-left" v-model="loginform.password" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入密码"/>
				</div>
				<div class="margin-top-40 dialog-footer">
					<el-button type="primary" class="block" @click="login">登 录</el-button>
				</div>
				<div class="cl margin-top padding-bottom-30">
					<a href="/findpass" class="y">忘记密码？立即找回</a>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
	name: "push2",
	data() {
		return {
			token:'',
			showLogin:false,
			loginform:{
				username:'',
				password:''
			},
			companyInfo:null,
			formData:{
				gys_type:'',
				title:'',
				type:'notice',
				second_type:"",
				type_industry_id:'',
				type_fuwu_ids:'',
				type_dw_id:'',
				type_demand_id:"",
				push_time:'',
				end_time:'',
				content:'',
				name:'',
				// province_code:'',
				// privince_name:'',
				province_codes:[],
				files_ids:[],
				bids_price:'',
				hyzl:'',
				phone:'',
				type_tbzz_ids:[]
			},
			zzList:[],
			rules:{
				// type:[{required:true,message:'发布板块必填'}],
				gys_type:[{required:true,message:'请选择标讯类型'}],
				title:[{required:true,message:'项目名称必填'}],
				push_time:[{required:true,message:'发布时间必填'}],
				end_time:[{required:true,message:'截止时间必填'}],
				bids_price:[{pattern: this.$regexp.money, message: '请填写正确的金额'},{required:true,message:'标的金额必填'}],
				hyzl:[{pattern: this.$regexp.float, message: '请填写正确的货运总量'},{required:true,message:'货运总量必填'}],
				type_dw_id:[{required:true,message:'货运单位必选'}],
				phone:[{required:true,message:'联系方式必填'},{pattern:this.$regexp.mobile,message: '请填写正确的手机号'}],
				name:[{required:true,message:'发布人必填'}],
				type_industry_id:[{required:true,message:'行业必选'}],
				type_fuwu_ids:[{required:true,message:'服务类型必选'}],
				province_codes:[{required:true,message:'区域必选'}],
			},
			config: {
				// 编辑器不自动被内容撑高
				autoHeightEnabled: false,
				autoFloatEnabled:false,
				// 初始容器高度
				initialFrameHeight: 240,
				// 初始容器宽度
				initialFrameWidth: '100%',
				// 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				serverUrl: 'http://www.clb.org.cn/UEditor/php/controller.php',
				// UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				UEDITOR_HOME_URL: '/ueditor/',
				toolbars:[[
					'fullscreen', '|', 'undo', 'redo', '|',
					'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
					'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
					'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
					'directionalityltr', 'directionalityrtl', 'indent', '|',
					'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
					'simpleupload','attachment', 'template', '|',
					'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
					'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
					'print', 'preview', 'searchreplace', 'drafts', 'help'
				]]
			}
		}
	},
	created() {
		let _token = this.$route.query.token ? this.$route.query.token : '';
		let _mobile = this.$route.query.mobile ? this.$route.query.mobile : '';
		this.id = this.$route.query.id ? this.$route.query.id : '';
		
		if(_token){
			this.token = _token;
			this.$user.setToken(_token);
			if(_mobile){
				this.$user.setMobile(mobile);
			}
			
			if(this.id){
				this.getDetail();
			}
		}else{
			_token = this.$user.getToken();
			
			if(!_token){
				this.$alert('请先登录', '提示', {
					confirmButtonText: '确定',
					showClose:false,
					callback: action => {
						this.toLogin()
					}
				});
			}else{
				this.token = _token;
				if(this.id){
					this.getDetail();
				}
			}
		}
	},
	mounted() {
		this.formData.push_time = this.$moment().format('YYYY-MM-DD HH:mm:ss');
		
		this.getZzList();
		
		if(this.token) {
			this.getUserType();
		}
	},
	methods: {
		getDetail(){
			let _this = this;
			
			this.$http.httpPost({
				url: '/AuthBids/detail',
				datas:{id:this.id},
				success: (res) => {
					_this.formData = res;
				}
			});
		},
		getDwList(){
			let _this = this;
			
			this.$http.httpPost({
				url: '/Type/listAll',
				datas: {type:'dw',state:1,},
				success: (res) => {
					console.log(res);
				}
			});
		},
		getSystem(){
			let _this = this;
			
			this.$http.httpPost({
				url: '/SystemSetting/getSystem',
				datas: {skey: ['weixin_mini_app_qrcode']},
				success: (res) => {
					console.log(res);
				}
			});
		},
		getUserType() {
			let _this = this;
			this.$http.httpPost({
				url:'/AuthUserCompany/clearDetail',
				datas:null,
				success(res){
					_this.companyInfo = res;
					let _qrcode = _this.$utils.localGet('weixin_mini_app_qrcode');
					if(!res){
						let _content = '';
						if(_qrcode){
							_content = '<div class="text-center">' +
								'<div class="f16">请先使用微信扫描二维码完善信息</div>'+
								'<img class="margin-top" src="'+_qrcode+'" height="200"/>' +
								'</div>';
						}else{
							_content = '请先完善信息'
						}
						
						_this.$alert(_content, '提示', {
							confirmButtonText: '知道了',
							dangerouslyUseHTMLString: true,
							callback: action => {
								_this.$router.replace({path:'/'})
							}
						});
					}else{
						if(res.state === 1){
							if(res.user_type !== 'hz'){
								_this.$alert('你的身份不是货主，不能发布标讯', '提示', {
									confirmButtonText: '知道了',
									dangerouslyUseHTMLString: true,
									callback: action => {
										_this.$router.replace({path:'/'})
									}
								});
							}
						}else{
							let _content = '';
							if(_qrcode){
								_content = '<div class="text-center">' +
									'<div class="f16">您的信息还在审核中，扫描二维码了解详情</div>'+
									'<img class="margin-top" src="'+_qrcode+'" height="200"/>' +
									'</div>';
							}else{
								_content = '您的信息还在审核中'
							}
							
							_this.$alert(_content, '提示', {
								confirmButtonText: '知道了',
								dangerouslyUseHTMLString: true,
								callback: action => {
									_this.$router.replace({path:'/'})
								}
							});
						}
					}
				}
			})
		},
		onChangeType(e){
			console.log(e);
			if(e === 'coldChain') {
				this.formData.second_type = 'coldBid';
			}else{
				this.formData.second_type = '';
			}
		},
		login(){
			let _this = this;
			this.$http.httpPost({
				url: '/User/login',
				datas: this.loginform,
				success: (res) => {
					_this.$user.setToken(res.token);
					_this.$user.setMobile(res.mobile);
					_this.$user.setUid(res.uid);
					_this.$user.setUserName(res.username);
					
					_this.$utils.success('登录成功');
					
					_this.token = res.token;
					
					_this.loginform = {
						username:'',
						password:''
					};
					
					_this.showLogin = false;
					
					window.location.reload();
				}
			});
		},
		toLogin(){
			this.showLogin = true;
		},
		changeArea(e){
			console.log(e);
			this.formData.province_name = e === '1' ? '全国' : CodeToText[e];
		},
		getZzList(){
			let _this = this;
			
			_this.$http.httpPost({
				url: '/Type/listAll',
				datas:{akey:'tbzz',state:1,orderBy:'sort',sortBy:'asc'},
				success: (res) => {
					_this.zzList = res;
				}
			});
		},
		submit(){
			let _this = this;
			if(!this.$user.getToken()){
				this.toLogin();
				return;
			}
			
			this.$refs['form'].validate((valid,obj) => {
				if (valid) {
					_this.$http.httpPost({
						url: '/AuthBids/add3',
						datas:this.formData,
						success: (res) => {
							if(res === 'ok'){
								// _this.$message.success('发布成功，等待审核');
								_this.$alert('发布成功，等待审核', '提示', {
									confirmButtonText: '知道了',
									showClose:false,
									callback: action => {
										window.location.reload();
									}
								});
							}
						}
					});
				}else{
					_this.$message.error('请填写正确的表单');
				}
			})
		},
	}
}
</script>

<template>
    <div v-if="show">
        <el-carousel :interval="5000" arrow="always" v-if="list.length > 1" :height="height" :style="{width:width}" trigger="click">
            <el-carousel-item v-for="(item,index) in list">
                <img @click="toOutUrl(item.out_url)" class="hand" :src="item.res_url.indexOf('http') >= 0 ? item.res_url : $config.assetsUrl + item.res_url" :style="{height:height,width:width}"/>
            </el-carousel-item>
        </el-carousel>
        <div v-else :style="{width:width,height:height}">
            <img @click="toOutUrl(list[0].out_url)" class="hand" :src="list[0].res_url.indexOf('http') >= 0 ? list[0].res_url : $config.assetsUrl + list[0].res_url" :style="{height:height,width:width}" v-if="list[0]"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Banner",
    data(){
        return{
            list:[],
            show:false
        }
    },
    props:{
        height:{
            type:String,
            default:'0px'
        },
        width:{
            type:String,
            default:'0px'
        },
        akey:{
            type:String,
            default:''
        }
    },
    mounted() {
        this.getList();
        this.getSystem();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Banner/listAll',
                datas:{key:this.akey,},
                success(res){
                    _this.list = res;
                }
            })
        },
        toOutUrl(out_url){
            if(out_url){
                let _url = out_url.indexOf('http') >= 0 ? out_url : 'http://'+out_url;

                window.open(_url,'_blank');
            }
        },
        getSystem(){
            let _this = this;

            this.$http.httpPost({
                url: '/SystemSetting/getSystem',
                datas: {skey: 'open_'+this.akey},
                success: (res) => {
                    if(res['open_'+_this.akey]){
                        let _isopen = parseInt(res['open_'+_this.akey]);
                        if(_isopen === 0){
                            _this.show = false;
                        }else{
                            _this.show = true;
                        }
                    }else{
                        _this.show = true;
                    }
                }
            });
        },
    }
}
</script>
